import PropTypes from 'prop-types';
import {
  Paper,
  Typography,
} from '@mui/material';

function ErrorPage({ error }) {
  return (
    <Paper sx={{ p: 3, bgcolor: 'error.light' }}>
      <Typography sx={{ mb: 3 }}>
        Não foi possível processar a operação.
      </Typography>
      <Typography sx={{ mb: 3 }}>
        {error}
      </Typography>
    </Paper>
  );
}

ErrorPage.propTypes = {
  error: PropTypes.object.isRequired,
};

export default ErrorPage;
