/* eslint-disable react/jsx-no-duplicate-props */
import PropTypes from 'prop-types';
import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from 'react';

import {
  TextField,
  CircularProgress,
} from '@mui/material';

import {
  Diamond,
} from '@mui/icons-material';

import { useSnack } from '../../../hooks';

import { PrimaryButton } from '../../../components/styles/buttons';

import { UserPlanService } from '../../../services';

function UserDiamonds({
  open,
  handleClose,
  userPlan,
}) {
  const [diamondFoms, setDiamondsForm] = useState({
    quantity: 1,
    userPlanId: userPlan.userPlanId,
    loading: false,
  });
  console.log(diamondFoms);
  const [errors, setErrors] = useState({
    quantity: '',
  });
  const clearErrors = () => {
    const { quantity } = errors;

    // Avoid setState cycle
    if (quantity) {
      setErrors({
        quantity: '',
      });
    }
  };
  const { setSnack } = useSnack();
  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log('VAI ENVIAR');
    if (diamondFoms.quantity < 0) {
      setErrors({
        quantity: 'O número deve ser acima de 0',
      });
    }
    setDiamondsForm({
      ...diamondFoms,
      loading: true,
    });
    try {
      const createdDiamonds = await UserPlanService.addDiamonds({
        userPlanId: diamondFoms.userPlanId,
        quantity: diamondFoms.quantity,
      });

      console.log(createdDiamonds);

      setDiamondsForm({
        ...diamondFoms,
        loading: false,
      });

      return setSnack({
        type: 'success',
        message: 'Diamantes criados com sucesso',
      });
    } catch (e) {
      setDiamondsForm({
        ...diamondFoms,
        loading: false,
      });
      return setSnack({ message: e.message });
    }
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        component="form"
        noValidate
        onSubmit={handleSubmit}
        padding={5}
      >
        <DialogTitle color="primary">Adicionar diamantes</DialogTitle>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          spacing={2}
        >
          <Grid
            item
            xs={8}
          >
            <TextField
              required
              fullWidth
              autoFocus
              error={!!errors.quantity}
              name="quantity"
              type="number"
              label="Quantidade"
              id="quantity"
              InputProps={{
                inputProps: { min: 1 },
              }}
              autoComplete="quantity"
              helperText={errors.quantity}
              value={diamondFoms.quantity}
              onChange={(event) => {
                if (parseInt(event.target.value, 2) < 1) {
                  setDiamondsForm({ ...diamondFoms, quantity: 1 });
                } else {
                  setDiamondsForm({ ...diamondFoms, quantity: event.target.value });
                }
                clearErrors();
              }}
            />
          </Grid>
          <Grid
            item
            xs={4}
          >
            {diamondFoms.loading
              ? <CircularProgress />
              : (
                <PrimaryButton
                  type="submit"
                  key="submitbutton"
                  variant="primaryButton"
                >
                  Adicionar
                </PrimaryButton>
              )}
          </Grid>
        </Grid>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Os diamantes serão criados e associados ao plano selecionado do cliente pelo
          botão de adicionar Diamante.
        </Typography>
        <Typography variant="caption" sx={{ mt: 2 }}>
          Passe o mouse no botão de +<Diamond sx={{ fontSize: 15 }} /> na
          listagem de usuários para ver a
          qual plano receberá os diamantes.
        </Typography>

      </Box>
    </Dialog>
  );
}

UserDiamonds.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  userPlan: PropTypes.object.isRequired,
};

export default UserDiamonds;
