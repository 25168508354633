import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

const PrimaryButton = styled(Button)`
  ${({ theme }) => `
    transition: 0.2s ease-in-out all;
    &:hover {
      opacity: 0.7;
      background-color: ${theme.palette.buttons.primary};
      transition: 0.3s ease-in-out all;
    }
  `}
`;

export default PrimaryButton;
