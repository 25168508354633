import { ENDPOINTS } from '../constants';
import { Supporter } from '../models';
import { parseResponse, defaultHeaders } from '../utils';

class SupporterService {
  async getAll() {
    const response = await fetch(ENDPOINTS.SUPPORTER, {
      method: 'GET',
      headers: defaultHeaders(),
    });
    return parseResponse(response, Supporter);
  }

  async get(id) {
    if (!id) return null;

    const response = await fetch(ENDPOINTS.SUPPORTER_ID(id), {
      method: 'GET',
      headers: defaultHeaders(),
    });

    return parseResponse(response, Supporter);
  }

  async create(data) {
    const supporter = new Supporter(data);
    if (!supporter.isValid()) throw new Error('Invalid supporter');

    const response = await fetch(ENDPOINTS.SUPPORTER, {
      method: 'POST',
      headers: defaultHeaders(),
      body: supporter.json(),
    });

    return parseResponse(response);
  }

  async update(data) {
    const supporter = new Supporter(data);
    if (!supporter.isValid(true)) throw new Error('Invalid supporter');

    const response = await fetch(ENDPOINTS.SUPPORTER_ID(supporter.id), {
      method: 'PUT',
      headers: defaultHeaders(),
      body: supporter.json(),
    });

    return parseResponse(response);
  }

  async delete(id) {
    if (!id) return null;

    const response = await fetch(ENDPOINTS.SUPPORTER_ID(id), {
      method: 'DELETE',
      headers: defaultHeaders(),
    });

    return parseResponse(response);
  }
}

export default new SupporterService();
