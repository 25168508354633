import { ENDPOINTS } from '../constants';
import { VoucherApplication } from '../models';
import { parseResponse, defaultHeaders } from '../utils';

class VoucherApplicationService {
  async getAll(query) {
    const url = ENDPOINTS.parse(ENDPOINTS.VOUCHER_APPLICATION, query);
    const response = await fetch(url, {
      method: 'GET',
      headers: defaultHeaders(),
    });

    return parseResponse(response, VoucherApplication);
  }

  async get(id) {
    if (!id) return null;

    const response = await fetch(ENDPOINTS.VOUCHER_APPLICATION_ID(id), {
      method: 'GET',
      headers: defaultHeaders(),
    });

    return parseResponse(response, VoucherApplication);
  }

  async accept(id) {
    if (!id) return null;

    const response = await fetch(ENDPOINTS.VOUCHER_APPLICATION_ACCEPT(id), {
      method: 'PUT',
      headers: defaultHeaders(),
    });

    return parseResponse(response);
  }

  async reject(id) {
    if (!id) return null;

    const response = await fetch(ENDPOINTS.VOUCHER_APPLICATION_REJECT(id), {
      method: 'PUT',
      headers: defaultHeaders(),
    });

    return parseResponse(response);
  }
}

export default new VoucherApplicationService();
