class Logs {
  constructor({ id, action }) {
    this.id = id;
    this.action = action;
  }

  /// Check if this is valid. It is valid if all required data has been informed.
  isValid(checkId) {
    if (checkId && !this.id) return false;
    return true;
  }

  json() {
    return JSON.stringify(this);
  }
}

export default Logs;
