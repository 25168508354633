class Faq {
  constructor(data) {
    this.id = data.id;
    this.question = data.question;
    this.answer = data.answer;
    this.upvotes = data.upvotes || 0;
    this.downvotes = data.downvotes || 0;
  }

  /// Check if this is valid. It is valid if all required data has been informed.
  isValid(checkId) {
    if (checkId && !this.id) return false;
    if (!this.question || !this.answer) return false;

    return true;
  }

  json() {
    return JSON.stringify(this);
  }
}

export default Faq;
