/* eslint-disable no-unused-vars */
const DAYS = [
  'Domingo',
  'Segunda',
  'Terca',
  'Quarta',
  'Quinta',
  'Sexta',
  'Sabado',
];

const PERIODS = [
  'Manhã',
  'Tarde',
  'Noite',
];

class Formatter {
  getStringForPeriod(period) {
    switch (period) {
      case 'breakfast':
        return PERIODS[0];
      case 'lunch':
        return PERIODS[1];
      case 'dinner':
        return PERIODS[2];
      default:
        return 'Desconhecido';
    }
  }

  getStringForDay(day, length) {
    switch (day) {
      case 'sunday':
        return DAYS[0].slice(0, length || undefined);
      case 'monday':
        return DAYS[1].slice(0, length || undefined);
      case 'tuesday':
        return DAYS[2].slice(0, length || undefined);
      case 'wednesday':
        return DAYS[3].slice(0, length || undefined);
      case 'thursday':
        return DAYS[4].slice(0, length || undefined);
      case 'friday':
        return DAYS[5].slice(0, length || undefined);
      case 'saturday':
        return DAYS[6].slice(0, length || undefined);
      default:
        return 'Desconhecido';
    }
  }

  formatNumberToPeriodObject(num) {
    if (num < 0 || num > 7) throw new Error('Número inválido');

    const day = [false, false, false];

    if (num > 3) {
      day[0] = true; // Café
      num -= 4;
    }
    if (num > 1) {
      day[1] = true; // Almoço
      num -= 2;
    }
    day[2] = !!num; // Janta

    return {
      breakfast: day[0],
      lunch: day[1],
      dinner: day[2],
    };
  }

  formatPeriodObjectToNumber(state) {
    const {
      breakfast, lunch, dinner,
    } = state;

    let num = 0;
    num += breakfast ? 4 : 0;
    num += lunch ? 2 : 0;
    num += dinner ? 1 : 0;

    return num;
  }

  formatWeekObjectToNumber(week) {
    const {
      sunday, monday, tuesday, wednesday, thursday, friday, saturday,
    } = week;

    let num = 0;
    num += saturday ? 64 : 0;
    num += friday ? 32 : 0;
    num += thursday ? 16 : 0;
    num += wednesday ? 8 : 0;
    num += tuesday ? 4 : 0;
    num += monday ? 2 : 0;
    num += sunday ? 1 : 0;

    return num;
  }

  formatNumberToWeekObject(num) {
    const day = [false, false, false, false, false, false, false];

    if (num > 63) {
      day[0] = true; // Sábado
      num -= 64;
    }
    if (num > 31) {
      day[1] = true; // Sexta
      num -= 32;
    }
    if (num > 15) {
      day[2] = true; // Quinta
      num -= 16;
    }
    if (num > 7) {
      day[3] = true; // Quarta
      num -= 8;
    }
    if (num > 3) {
      day[4] = true; // Terça
      num -= 4;
    }
    if (num > 1) {
      day[5] = true; // Segunda
      num -= 2;
    }
    day[6] = !!num; // Domingo

    return {
      sunday: day[6],
      monday: day[5],
      tuesday: day[4],
      wednesday: day[3],
      thursday: day[2],
      friday: day[1],
      saturday: day[0],
    };
  }

  formatPeriodToString(period) {
    if (parseInt(period, 10)) period = this.formatNumberToPeriodObject(period);

    const valids = [];
    Object.entries(period).forEach(([k, v]) => {
      if (v) valids.push(this.getStringForPeriod(k));
    });

    return valids.join(valids.length === 2 ? ' e ' : ' - ');
  }

  formatWeekToString(week) {
    if (parseInt(week, 10)) week = this.formatNumberToWeekObject(week);

    const valids = [];
    Object.entries(week).forEach(([k, v]) => {
      if (v) valids.push(this.getStringForDay(k));
    });

    if (valids.length === 7) return 'Todos os dias';

    return valids.join(' - ');
  }
}

export default new Formatter();
