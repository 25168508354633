import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  CircularProgress,
  Typography,
} from '@mui/material';

import { SupporterTile } from './components';
import { supporterService } from '../../services';
import SectionList from '../../components/styles/list/sectionList';

function SupportersPage() {
  const [error, setError] = useState();
  const [state, setState] = useState({
    loading: true,
    supporters: [],
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (error) return () => { };

    supporterService.getAll().then((supporters) => {
      setState((oldState) => ({
        ...oldState,
        loading: false,
        supporters,
      }));
    }).catch((err) => {
      setError(err);
    });

    return () => { };
  }, [error]);

  // Throwing errors which the AppBoundary can catch
  // Errors thrown inside useEffect are not catched by the boundary
  useEffect(() => {
    if (error) {
      const err = error;
      setError(null);
      throw err;
    }
  }, [error]);

  const handleClick = () => {
    navigate('/supporters/new');
  };

  return state.loading
    ? (
      <CircularProgress />
    )
    : (
      <>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5"><b>Apoiadores</b></Typography>
          <Button variant="contained" onClick={handleClick}>
            + Adicionar
          </Button>
        </Box>
        <Box
          sx={{
            mt: 2,
            maxHeight: '70vh',
            bgcolor: 'transparent',
          }}
        >
          <SectionList variant="sectionList">
            {state.supporters.map((supporter) => (
              <SupporterTile
                key={supporter.id.toString()}
                supporter={supporter}
              />
            ))}
          </SectionList>
        </Box>
      </>
    );
}

export default SupportersPage;
