import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Box,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Typography,
  TableFooter,
} from '@mui/material';

import { PeriodPicker, WeekDayPicker, EstablishmentRow } from './components';
import { establishmentService } from '../../services';
import { formatter } from '../../utils';

function EstablishmentListPage() {
  const [state, setState] = useState({
    loading: true,
    establishments: [],
  });

  const [filters, setFilters] = useState({ days: 0, periods: 0 });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const navigate = useNavigate();

  const loadData = useCallback(() => {
    establishmentService
      .getAll()
      .then((establishments) => {
        establishments.sort((a, b) => {
          if (a.highlight && b.highlight) return a.id - b.id;

          return a.highlight ? -1 : 1;
        });
        setState({
          establishments,
          loading: false,
        });
      })
      .catch(() => {
        setState((oldState) => ({
          ...oldState,
          loading: false,
        }));
      });
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleDayFilterChange = (newValue) => {
    if (newValue !== filters.days) {
      setFilters((oldFilters) => ({
        ...oldFilters,
        days: newValue,
      }));
    }
  };

  const handlePeriodFilterChange = (newValue) => {
    if (newValue !== filters.periods) {
      setFilters((oldFilters) => ({
        ...oldFilters,
        periods: newValue,
      }));
    }
  };
  function periodFilter(availablePeriods) {
    const filterObject = formatter.formatNumberToPeriodObject(filters.periods);
    const filterArray = Object.entries(filterObject)
      .filter(([, v]) => v)
      .map(([k]) => k);
    const periodObject = formatter.formatNumberToPeriodObject(availablePeriods);
    const periodArray = Object.entries(periodObject)
      .filter(([, v]) => v)
      .map(([k]) => k);

    let result = true;
    filterArray.forEach((period) => {
      if (!periodArray.includes(period)) result = false;
    });
    return result;
  }

  function dayFilter(availableDays) {
    const filterObject = formatter.formatNumberToWeekObject(filters.days);
    const filterArray = Object.entries(filterObject)
      .filter(([, v]) => v)
      .map(([k]) => k);
    const dayObject = formatter.formatNumberToWeekObject(availableDays);
    const dayArray = Object.entries(dayObject)
      .filter(([, v]) => v)
      .map(([k]) => k);

    let result = true;
    filterArray.forEach((day) => {
      if (!dayArray.includes(day)) result = false;
    });
    return result;
  }

  function getFiltererEstablishments() {
    const establishments = state.establishments.filter((establishment) => {
      if (!periodFilter(establishment.availablePeriods)) return false;
      if (!dayFilter(establishment.availableDays)) return false;
      return true;
    });
    return establishments;
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };
  const handleClick = () => {
    navigate('/establishments/add');
  };

  return state.loading ? (
    <CircularProgress />
  ) : (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h5">
          <b>Estabelecimentos</b>
        </Typography>
        <Button variant="contained" onClick={handleClick}>
          + Adicionar
        </Button>
      </Box>
      <Box sx={{ display: 'flex', mt: 1 }}>
        <Box sx={{ mr: 4 }}>
          <Typography>Dias da Semana</Typography>
          <WeekDayPicker
            value={filters.days}
            onChangeHandle={handleDayFilterChange}
          />
        </Box>
        <Box>
          <Typography>Período de Funcionamento</Typography>
          <PeriodPicker
            value={filters.periods}
            onChangeHandle={handlePeriodFilterChange}
          />
        </Box>
      </Box>
      <Box
        sx={{
          mt: 2,
          bgcolor: 'transparent',
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Foto</TableCell>
              <TableCell>Nome</TableCell>
              <TableCell>Endereço</TableCell>
              <TableCell>Destaque</TableCell>
              <TableCell>Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getFiltererEstablishments()
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((establishment) => (
                <EstablishmentRow establishment={establishment} />
              ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                count={getFiltererEstablishments().length}
                rowsPerPage={rowsPerPage}
                page={page}
                labelRowsPerPage="Resultados por página"
                labelDisplayedRows={({ from, to, count }) => `${from}–${to} de ${count !== -1 ? count : `mais de ${to}`}`}
                onPageChange={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                rowsPerPageOptions={[10, 20, 50]}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </Box>
    </Box>
  );
}

export default EstablishmentListPage;
