import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box, Button,
} from '@mui/material';
import { formatter } from '../../../utils';

function WeekDayPicker({
  inputKey,
  value,
  onChangeHandle,
  readOnly,
}) {
  const [state, setState] = useState(formatter.formatNumberToWeekObject(value));

  const handleClick = (day) => {
    if (readOnly) return;

    const newState = state;
    newState[day] = !state[day];

    onChangeHandle(formatter.formatWeekObjectToNumber(newState));
    setState(newState);
  };

  return (
    <Box>
      {
        Object.entries(state).map(([day, selected]) => (
          <Button
            key={`${inputKey} - ${day}`}
            variant={selected ? 'contained' : 'outlined'}
            onClick={() => handleClick(day)}
            sx={{ borderRadius: 0 }}
          >
            {formatter.getStringForDay(day, 3)}
          </Button>
        ))
      }
    </Box>
  );
}

WeekDayPicker.defaultProps = {
  inputKey: null,
  value: null,
  readOnly: false,
};

WeekDayPicker.propTypes = {
  inputKey: PropTypes.string,
  value: PropTypes.number,
  onChangeHandle: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};

export default WeekDayPicker;
