import PropTypes from 'prop-types';
import {
  Avatar,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import { ChevronRight as ChevronRightIcon } from '@mui/icons-material';

function UserTile({ user }) {
  return (
    <ListItemButton
      key={user.id.toString()}
      href={`/users/${user.id}`}
    >
      <ListItemAvatar>
        <Avatar alt={`${user.name} image`} src={user.avatar || 'none'} />
      </ListItemAvatar>
      <ListItemText>
        {user.id}
        {' '}
        -
        {' '}
        {user.name}
      </ListItemText>
      <ChevronRightIcon />
    </ListItemButton>
  );
}

UserTile.propTypes = {
  user: PropTypes.object.isRequired,
};

export default UserTile;
