/* eslint-disable react/no-children-prop */
import { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';

import termsPath from '../../assets/terms.md';

function TermsPage() {
  const [terms, setTerms] = useState(null);

  useEffect(() => {
    fetch(termsPath).then((response) => response.text()).then((text) => {
      setTerms(text);
    });

    return () => { };
  }, []);

  return (
    <div className="content">
      <ReactMarkdown children={terms} />
    </div>
  );
}

export default TermsPage;
