import PropTypes from 'prop-types';
import {
  ListItemButton,
  ListItemText,
} from '@mui/material';

function LogsTile({ log }) {
  return (
    <ListItemButton
      key={log.id.toString()}
    >
      <ListItemText>
        {log.id}
        {' '}
        -
        {' '}
        {log.action}
      </ListItemText>
    </ListItemButton>
  );
}

LogsTile.propTypes = {
  log: PropTypes.object.isRequired,
};

export default LogsTile;
