import moment from 'moment';
import xtype from 'xtypejs';
import isEmail from 'validator/lib/isEmail';

class Validator {
  required(input) {
    return !input ? 'Campo obrigatório' : '';
  }

  requiredPlan(input) {
    return !input || input <= 0 ? 'Campo obrigatório' : '';
  }

  email(input, isRequired) {
    if (!isRequired && !input) return '';

    if (!input) this.required(input);
    if (!input.includes('@') || !isEmail(input)) return 'E-mail inválido';

    return '';
  }

  data(input, isRequired) {
    const data = moment(input);

    if (!isRequired && !input) return '';
    if (!input) this.required(input);

    if (xtype.type(data.toDate()) !== 'date' || !data.isValid()) return 'Data inválida';

    return '';
  }

  reais(input, isRequired) {
    if (!isRequired && !input) return '';

    if (!input) this.required(input);
    if (xtype.type(input) !== 'number' || input <= 0) return 'Valor em reais inválido';

    return '';
  }

  name(input, isRequired) {
    if (!isRequired && !input) return '';

    if (!input) this.required(input);
    if (input.length < 4) return 'Nome deve ter entre 3 e 255 caracteres';

    return '';
  }

  password(input, isRequired) {
    if (!isRequired && !input) return '';

    if (!input) this.required(input);
    if (input.length < 6) return 'Senha deve ter no mínimo 6 caracteres';

    return '';
  }
}

export default new Validator();
