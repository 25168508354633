import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

const SecondaryButton = styled(Button)`
  ${({ theme }) => `
    transition: 0.2s ease-in-out all;
    &:hover {
      opacity: 0.8;
      background-color: ${theme.palette.buttons.secondary};
      transition: 0.3s ease-in-out all;
    }
  `}
`;

export default SecondaryButton;
