export const ADMIN = 1;
export const FRANCHISEE = 2;
export const OWNER = 3;

const roles = [
  'Desconhecido',
  'Admin',
  'Franqueado',
  'Restaurante',
  'App',
];

export const roleName = (role) => roles[role];
