import { ENDPOINTS } from '../constants';
import { parseResponse, defaultHeaders } from '../utils';

class StatsService {
  async getAll(query) {
    const url = ENDPOINTS.parse(ENDPOINTS.STATS, query);
    const response = await fetch(url, {
      method: 'GET',
      headers: defaultHeaders(),
    });

    return parseResponse(response);
  }
}

export default new StatsService();
