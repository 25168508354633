class Importador {
  constructor({
    email,
    buyDate,
    value,
    planId,
  }) {
    this.email = email;
    this.buyDate = buyDate;
    this.value = value;
    this.planId = planId;
  }

  /// Check if this is valid. It is valid if all required data has been informed.
  isValid() {
    if (!this.email) return false;
    if (!this.buyDate) return false;
    if (!this.value) return false;
    if (!this.planId) return false;

    return true;
  }

  json() {
    return JSON.stringify(this);
  }
}

export default Importador;
