import { ENDPOINTS } from '../constants';
import { ContactType } from '../models';
import { parseResponse, defaultHeaders } from '../utils';

class ContactTypeService {
  async getAll(query) {
    const url = ENDPOINTS.parse(ENDPOINTS.CONTACT_TYPE, query);
    const response = await fetch(url, {
      method: 'GET',
      headers: defaultHeaders(),
    });
    return parseResponse(response, ContactType);
  }

  async getOne(id) {
    if (!id) return null;

    const response = await fetch(ENDPOINTS.CONTACT_TYPE_ID(id), {
      method: 'GET',
      headers: defaultHeaders(),
    });

    return parseResponse(response, ContactType);
  }

  async create(data) {
    const contactType = new ContactType(data);
    if (!contactType.isValid()) throw new Error('Invalid contactType');

    const response = await fetch(ENDPOINTS.CONTACT_TYPE, {
      method: 'POST',
      headers: defaultHeaders(),
      body: contactType.json(),
    });

    return parseResponse(response);
  }

  async update(data) {
    const contactType = new ContactType(data);
    if (!contactType.isValid(true)) throw new Error('Invalid contactType');

    const response = await fetch(ENDPOINTS.CONTACT_TYPE_ID(contactType.id), {
      method: 'PUT',
      headers: defaultHeaders(),
      body: contactType.json(),
    });

    return parseResponse(response);
  }

  async delete(id) {
    if (!id) return null;

    const response = await fetch(ENDPOINTS.CONTACT_TYPE_ID(id), {
      method: 'DELETE',
      headers: defaultHeaders(),
    });

    return parseResponse(response);
  }
}

export default new ContactTypeService();
