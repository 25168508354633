import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  CircularProgress,
  MenuItem,
  TextField,
} from '@mui/material';
import { contactTypeService } from '../../../services';

function ContactTypePicker({
  value, onChangeHandle, readOnly,
}) {
  const [state, setState] = useState({
    loading: true,
    options: [],
  });

  useEffect(() => {
    contactTypeService.getAll().then((contactTypes) => {
      setState((oldState) => ({
        ...oldState,
        loading: false,
        options: contactTypes || [],
      }));
    }).catch(() => {
      setState((oldState) => ({
        ...oldState,
        loading: false,
      }));
    });

    return () => { };
  }, []);

  const handleChange = (event) => {
    onChangeHandle(
      state.options.find(
        (contactType) => contactType.id === event.target.value,
      ),
    );
  };

  return state.loading ? <CircularProgress /> : (
    <TextField
      fullWidth
      hiddenLabel
      variant="standard"
      name={`contactTypeSelect${value == null ? 'New' : ''}`}
      value={value}
      onChange={handleChange}
      InputProps={{
        disableUnderline: true,
        readOnly,
      }}
      select
    >
      {state.options.map(
        (contactType) => (
          <MenuItem key={`contactType${contactType.id}`} value={contactType.id}>
            {contactType.description}
          </MenuItem>
        ),
      )}
    </TextField>
  );
}

ContactTypePicker.defaultProps = {
  readOnly: false,
  value: '',
};

ContactTypePicker.propTypes = {
  readOnly: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onChangeHandle: PropTypes.func.isRequired,
};

export default ContactTypePicker;
