class State {
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.url = data.url;
    this.image = data.image;
  }

  /// Check if this is a valid user.
  isValid(checkId) {
    if (checkId && !this.id) return false;
    if (!this.name) return false;

    return true;
  }

  json() {
    return JSON.stringify(this);
  }
}

export default State;
