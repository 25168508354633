import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  CircularProgress,
  Typography,
} from '@mui/material';

import { CulinaryTile } from './components';
import { culinaryService } from '../../services';
import SectionList from '../../components/styles/list/sectionList';

function CulinariesPage() {
  const [error, setError] = useState();
  const [state, setState] = useState({
    loading: true,
    culinaries: [],
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (error) return () => { };

    culinaryService.getAll().then((list) => {
      list = list || [];
      const culinaries = list.map((culinary) => (
        <CulinaryTile
          key={culinary.id.toString()}
          culinary={culinary}
        />
      ));
      setState((oldState) => ({
        ...oldState,
        loading: false,
        culinaries,
      }));
    }).catch((err) => {
      setError(err);
    });

    return () => { };
  }, [error]);

  // Throwing errors which the AppBoundary can catch
  // Errors thrown inside useEffect are not catched by the boundary
  useEffect(() => {
    if (error) {
      const err = error;
      setError(null);
      throw err;
    }
  }, [error]);

  const handleClick = () => {
    navigate('/culinaries/new');
  };

  return state.loading
    ? (
      <CircularProgress />
    )
    : (
      <>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5"><b>Culinárias</b></Typography>
          <Button variant="contained" onClick={handleClick}>
            + Adicionar
          </Button>
        </Box>
        <Box sx={{
          mt: 2, maxHeight: '60vh', bgcolor: 'transparent',
        }}
        >
          <SectionList variant="sectionList">
            {state.culinaries}
          </SectionList>
        </Box>
      </>
    );
}

export default CulinariesPage;
