import PropTypes from 'prop-types';
import {
  Avatar,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Chip,
} from '@mui/material';
import { ChevronRight as ChevronRightIcon } from '@mui/icons-material';

function PlanTile({ plan }) {
  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });
  const formattedValue = formatter.format(plan.value / 100);
  const formattedDiscountValue = formatter.format(plan.discountValue / 100);
  const date = new Date(plan.expiry);
  date.setDate(date.getDate() + 1);
  date.setHours(0);
  const formattedDate = new Intl.DateTimeFormat('pt-BR').format(date);

  let preSaleDate;
  if (plan.discountPeriod !== null) {
    const dateDiscount = new Date(plan.discountPeriod);
    dateDiscount.setDate(dateDiscount.getDate() + 1);
    dateDiscount.setHours(0);
    preSaleDate = new Intl.DateTimeFormat('pt-BR').format(dateDiscount);
  }

  return (
    <ListItemButton
      key={plan.id.toString()}
      href={`/plans/${plan.id}`}
    >
      <ListItemAvatar>
        <Avatar alt={`${plan.name} image`} src={plan.image || 'none'}>
          {(plan.name?.split(' ')[1] || 'P')[0].toUpperCase()}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={<b>{`${plan.id} - ${plan.name}`}</b>}
        secondary={(
          <span style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start' }}>
            <span style={{ paddingRight: '80px' }}>
              {`Preço - ${formattedValue}`}
              <br />
              {plan.discountPeriod !== null && `Preço de Pré-Venda - ${formattedDiscountValue}`}
              <br />
              {`Número de Vouchers - ${plan.vouchers}`}
              <br />
              {`Diamantes - ${plan.diamonds}`}
              <br />
            </span>
            <span style={{ paddingRight: '20px' }}>
              {`Valido até - ${formattedDate}`}
              <br />
              {`Duração dos Vouchers - ${plan.voucherPeriod} dias`}
              <br />
              {plan.discountPeriod !== null && <Chip sx={{ marginTop: 1 }} color="primary" label={`Pré-venda até - ${preSaleDate}`} />}
            </span>
          </span>
        )}
        sx={{ paddingLeft: '20px' }}
      />
      <ChevronRightIcon />
    </ListItemButton>
  );
}

PlanTile.propTypes = {
  plan: PropTypes.object.isRequired,
};

export default PlanTile;
