import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DOMPurify from 'dompurify';
import {
  Box,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Typography,
  TableFooter,
} from '@mui/material';

import { PrimaryButton } from '../../components/styles/buttons';
import { newsService } from '../../services';

function NewsListPage() {
  const [newsState, setNewsState] = useState({
    loading: true,
    news: [],
  });

  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(10);

  const navigate = useNavigate();
  const createMarkup = (htmlContent) => ({ __html: DOMPurify.sanitize(htmlContent) });

  useEffect(() => {
    newsService.getAll().then((news) => {
      console.log('NEWS:', news);
      setNewsState({
        news,
        loading: false,
      });
    }).catch((e) => {
      console.log('ERROR:', e);
      setNewsState((oldState) => ({
        ...oldState,
        loading: false,
      }));
    });
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  const handleClick = () => {
    navigate('/news/add');
  };

  return newsState.loading ? (
    <CircularProgress />
  ) : (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h5">
          <b>Novidades</b>
        </Typography>
        <Button variant="contained" onClick={handleClick}>
          + Adicionar Notícia
        </Button>
      </Box>
      <Box
        sx={{
          mt: 2,
          bgcolor: 'transparent',
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Imagem</TableCell>
              <TableCell>Título</TableCell>
              <TableCell>Descrição</TableCell>
              <TableCell>Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {newsState.news
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((newsItem) => (
                <TableRow key={newsItem.id}>
                  <TableCell>
                    {newsItem.image
                      ? <img src={newsItem.image} alt={newsItem.title} style={{ width: '100px' }} />
                      : '-'}
                  </TableCell>
                  <TableCell>{newsItem.title}</TableCell>
                  <TableCell>
                    {/* eslint-disable-next-line react/no-danger */}
                    <div dangerouslySetInnerHTML={createMarkup(newsItem.description)} />
                  </TableCell>
                  <TableCell>
                    <PrimaryButton
                      key="editButton"
                      variant="contained"
                      sx={{ my: 2, mr: 2, color: 'white' }}
                      onClick={() => {
                        navigate(`/news/${newsItem.id}`);
                      }}
                    >
                      Editar
                    </PrimaryButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                count={newsState.news.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                // onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage="Notícias por página"
                labelDisplayedRows={({ from, to, count }) => `${from}–${to} de ${count !== -1 ? count : `mais de ${to}`}`}
                rowsPerPageOptions={[10, 20, 50]}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </Box>
    </Box>
  );
}

export default NewsListPage;
