class City {
  constructor({
    id, name, State, Users, Vendors,
  }) {
    this.id = id;
    this.name = name;
    this.State = State;
    this.Users = Users;
    this.Vendors = Vendors;
  }

  /// Check if this is a valid user.
  isValid(checkId) {
    if (checkId && !this.id) return false;
    if (!this.name || !this.State) return false;

    return true;
  }

  json() {
    return JSON.stringify(this);
  }
}

export default City;
