import { ENDPOINTS } from '../constants';
import { AddDiamonds } from '../models';
import { parseResponse, defaultHeaders } from '../utils';

class UserPlanService {
  async addDiamonds(data) {
    const diamonds = new AddDiamonds(data);
    if (!diamonds.isValid()) throw new Error('Invalid diamond');

    const response = await fetch(ENDPOINTS.USER_PLAN_ADDDIAMONDS, {
      method: 'POST',
      headers: defaultHeaders(),
      body: diamonds.json(),
    });

    return parseResponse(response);
  }
}

export default new UserPlanService();
