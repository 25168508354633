import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  TextField,
} from '@mui/material';

import { CameraAlt as CameraIcon } from '@mui/icons-material';

import { DeleteDialog } from '../../../components';

function FaqProfileForm({
  buttons,
  errors,
  clearErrors,
  profile,
  setProfile,
  isEditMode,
  handleSubmit,
  dialogOpen,
  onDialogClose,
  loading,
}) {
  const handleFileChange = (e) => {
    const file = Array.from(e.target.files)[0];
    if (file != null) {
      file.url = URL.createObjectURL(file);
      setProfile({ ...profile, file });
    }
  };

  return (
    <Box component="form" noValidate onSubmit={handleSubmit} sx={{}}>
      <Grid
        container
        spacing={2}
        component={Paper}
        elevation={6}
        sx={{
          m: 1,
          pl: 1,
          pr: 3,
          pt: 1,
          pb: 3,
        }}
      >
        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <Avatar
            alt="Avatar de apoiador"
            src={profile.file?.url || profile.image || 'none'}
            sx={{ width: 80, height: 80, mx: 'auto' }}
          >
            <CameraIcon />
          </Avatar>
          {isEditMode ? (
            <Button component="label" sx={{ maxWidth: 200 }}>
              Alterar imagem
              <input
                type="file"
                name="imageInput"
                accept="image/*"
                onChange={handleFileChange}
                hidden
              />
            </Button>
          ) : null}
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            fullWidth
            error={!!errors.name}
            name="name"
            label="Nome"
            id="name"
            autoComplete="name"
            helperText={errors.name}
            value={profile.name || ''}
            onChange={(event) => {
              setProfile({ ...profile, name: event.target.value });
              clearErrors();
            }}
            InputProps={{
              readOnly: !isEditMode,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            fullWidth
            error={!!errors.url}
            name="url"
            label="Website"
            id="url"
            autoComplete="url"
            helperText={errors.url}
            value={profile.url || ''}
            onChange={(event) => {
              setProfile({ ...profile, url: event.target.value });
              clearErrors();
            }}
            InputProps={{
              readOnly: !isEditMode,
            }}
          />
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
          {loading ? <CircularProgress /> : buttons}
        </Grid>
      </Grid>
      <DeleteDialog label="Apagar categoria?" open={dialogOpen} onClose={onDialogClose} />
    </Box>
  );
}

FaqProfileForm.propTypes = {
  buttons: PropTypes.array.isRequired,
  errors: PropTypes.object.isRequired,
  clearErrors: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  setProfile: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  dialogOpen: PropTypes.bool.isRequired,
  onDialogClose: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default FaqProfileForm;
