import { Route } from 'react-router-dom';
import {
  CategoryInfoPage,
  CategoryListPage,
  CulinaryInfoPage,
  CulinaryListPage,
  EstablishmentInfoPage,
  EstablishmentListPage,
  FaqListPage,
  FaqInfoPage,
  LogListPage,
  PlanInfoPage,
  PlanListPage,
  SelectCitiesPage,
  TermsPage,
  UserInfoPage,
  UserListPage,
  VoucherListPage,
  ImportPage,
  SupporterListPage,
  SupporterInfoPage,
  DashboardPage,
  StatsPage,
  ImportPageSingle,
  PaymentPage,
  NewsListPage,
  NewsInfoPage,
  /* PaymentInfoListPage,
  PaymentInfoPage, */
} from '../pages';

const routes = [];
/* Index */
routes.push(<Route
  index
  key="indexRoute"
  element={<DashboardPage />}
/>);

/* Dashboard */
routes.push(
  <Route path="/dashboard" key="dashboardRoute">
    <Route key="dashboard" path="" element={<DashboardPage />} />
  </Route>,
);

/* Stats */
routes.push(
  <Route path="/dashboard/stats" key="dashboardStatsRoute">
    <Route key="stats" path="" element={<StatsPage />} />
  </Route>,
);

/* Users */
routes.push(
  <Route path="/users" key="usersRoute">
    <Route key="userListRoute" path="" element={<UserListPage />} />
    <Route key="userDetailsRoute" path=":id" element={<UserInfoPage />} />
  </Route>,
);

/* Establishments */
routes.push(
  <Route key="establishmentRoutes" path="/establishments">
    <Route key="establishmentListRoute" path="" element={<EstablishmentListPage />} />
    <Route key="establishmentDetailsRoute" path=":id" element={<EstablishmentInfoPage />} />
  </Route>,
);

/* Vouchers */
routes.push(
  <Route key="voucherRoutes" path="vouchers">
    <Route key="voucherListRoute" path="" element={<VoucherListPage />} />
  </Route>,
);

/* Plans */
routes.push(
  <Route key="plansRoutes" path="plans">
    <Route key="plansListRoute" path="" element={<PlanListPage />} />
    <Route key="plansDetailRoute" path=":id" element={<PlanInfoPage />} />
  </Route>,
);

/* Categories */
routes.push(
  <Route key="categoryRoutes" path="/categories">
    <Route key="categoryListRoute" path="" element={<CategoryListPage />} />
    <Route key="categoryDetailsRoute" path=":id" element={<CategoryInfoPage />} />
  </Route>,
);

/* Culinary */
routes.push(
  <Route key="culinaryRoutes" path="/culinaries">
    <Route key="culinaryListRoute" path="" element={<CulinaryListPage />} />
    <Route key="culinaryDetailsRoute" path=":id" element={<CulinaryInfoPage />} />
  </Route>,
);

/* Cities */
routes.push(
  <Route key="cityRoutes" path="/cities">
    <Route key="cityListRoute" path="" element={<SelectCitiesPage />} />
  </Route>,
);

/* NEWS */
routes.push(
  <Route key="newsRoute" path="news">
    <Route key="newsListRoute" path="" element={<NewsListPage />} />
    <Route key="newsDetailsRoute" path=":id" element={<NewsInfoPage />} />
  </Route>,
);
/* FAQ */
routes.push(
  <Route key="faqRoute" path="faqs">
    <Route key="faqListRoute" path="" element={<FaqListPage />} />
    <Route key="faqDetailsRoute" path=":id" element={<FaqInfoPage />} />
  </Route>,
);

/* Supporters */
routes.push(
  <Route key="supporterRoute" path="supporters">
    <Route key="supporterListRoute" path="" element={<SupporterListPage />} />
    <Route key="supporterDetailsRoute" path=":id" element={<SupporterInfoPage />} />
  </Route>,
);

/* Import */
routes.push(
  <Route key="importRoute" path="import">
    <Route path="" element={<ImportPage />} />
  </Route>,
);

/* Import */
routes.push(
  <Route key="importRouteSingle" path="importador">
    <Route path="" element={<ImportPageSingle />} />
  </Route>,
);

/* Payments */
routes.push(
  <Route key="pagamentos" path="pagamentos">
    <Route path="" element={<PaymentPage />} />
  </Route>,
);

/* PaymentInfo */
/* routes.push(
  <Route key="paymentInfo" path="paymentInfo">
    <Route path="" element={<PaymentInfoListPage />} />
    <Route path=":id" element={<PaymentInfoPage />} />
  </Route>,
); */

/* Logs */
routes.push(
  <Route key="logsRoute" path="logs">
    <Route path="" element={<LogListPage />} />
  </Route>,
);

/* Terms */
routes.push(
  <Route key="termsRoute" path="/terms">
    <Route path="" element={<TermsPage />} />
  </Route>,
);

export default routes;
