import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  CircularProgress,
} from '@mui/material';

import {
  Edit as EditIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';

import { UserProfileForm } from './components';

import { uploadService, userService } from '../../services';
import { validator } from '../../utils';
import { useSnack, useAuth } from '../../hooks';
import { UnauthorizedError } from '../../errors';
import { ROLES } from '../../constants';
import { PrimaryButton, SecondaryButton, CancelButton } from '../../components/styles/buttons';
import { User } from '../../models';

const { OWNER } = ROLES;

function UserInfoPage() {
  const { setToken, user: currentUser } = useAuth();
  const { id } = useParams();
  const { setSnack } = useSnack();
  const navigate = useNavigate();

  const [state, setState] = useState({
    loading: true,
    edit: false,
    saving: false,
    dialogOpen: false,
  });

  const [profile, setProfile] = useState(new User({}));

  useEffect(() => {
    if (
      currentUser.id !== parseInt(id, 10) && currentUser.roleId === OWNER
    ) {
      setState(() => { throw new UnauthorizedError(); });
    }

    return () => { };
  }, [currentUser, id]);

  useEffect(() => {
    async function fetchProfile() {
      if (parseInt(id, 10)) {
        const loadedData = (await userService.get(id)) || new User({});
        setProfile(loadedData);
        setState((oldState) => ({
          ...oldState,
          loading: false,
          edit: !loadedData?.id,
          dialogOpen: false,
        }));
      } else {
        setState((oldState) => ({
          ...oldState,
          loading: false,
          edit: true,
          dialogOpen: false,
        }));
      }
    }

    fetchProfile();

    return () => { };
  }, [id]);

  const [errors, setErrors] = useState({
    name: '',
    email: '',
    password: '',
    role: '',
    state: '',
    city: '',
  });

  function isNewUser() {
    return profile?.id == null;
  }

  const cancel = () => {
    navigate(-1);
  };

  const toggleEdit = () => {
    if (parseInt(profile?.id, 10)) {
      setState((oldState) => ({
        ...oldState,
        edit: !state.edit,
      }));
    } else {
      cancel();
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setState((oldState) => ({
      ...oldState,
      saving: true,
    }));

    const avatar = (profile.file?.size || 0) === 0
      ? profile.avatar
      : await uploadService.image(profile.file);

    profile.avatar = avatar;

    profile.establishments = profile.Establishments.map((e) => e.id) || [];

    const nameError = validator.name(profile.name, true);
    const emailError = validator.email(profile.email, true);
    const passwordError = validator.password(profile.password, isNewUser());
    const roleError = isNewUser() ? validator.required(profile.roleId) : false;

    if (!emailError && !nameError && !passwordError && !roleError) {
      try {
        const newToken = isNewUser()
          ? await userService.create(profile)
          : await userService.update(profile);

        if (currentUser.id === profile?.id) setToken(newToken);

        setState((oldState) => ({
          ...oldState,
          saving: false,
          loading: false,
          edit: isNewUser(),
        }));

        if (isNewUser()) {
          setProfile(new User({}));
        }

        return setSnack({
          type: 'success',
          message: 'Cadastro salvo com sucesso',
        });
      } catch (e) {
        setState((oldState) => ({
          ...oldState,
          saving: false,
        }));
        return setSnack({ message: e.message });
      }
    } else {
      return setErrors({
        name: nameError,
        email: emailError,
        password: passwordError,
        role: roleError,
      });
    }
  };

  const clearErrors = () => {
    const { email, name, password } = errors;

    // Avoid setState cycle
    if (email || name || password) {
      setErrors({
        name: '',
        email: '',
        password: '',
      });
    }
  };

  function handleDelete() {
    userService.delete(profile.id).then((success) => {
      if (success) {
        cancel();
        setSnack({
          type: 'success',
          message: 'Usuário apagado com sucesso',
        });
      } else {
        setSnack();
      }
    }).catch((error) => {
      setSnack({ message: error.message });
    });
  }

  const onDialogClose = (confirm) => {
    setState((oldState) => ({ ...oldState, dialogOpen: false }));

    if (confirm) {
      handleDelete();
    }
  };

  const confirmDelete = () => {
    setState({ ...state, dialogOpen: true });
  };

  const buttonRow = () => {
    const buttons = [];

    if (state.edit) {
      if (!isNewUser()) {
        buttons.push(
          <CancelButton
            key="deleteButton"
            onClick={confirmDelete}
            sx={{ my: 2, mr: 2, color: 'error.main' }}
          >
            <DeleteIcon sx={{ mr: 2 }} />
            {' '}
            Deletar Conta
          </CancelButton>,
        );
      }
      buttons.push(
        <CancelButton
          key="cancelEditButton"
          onClick={toggleEdit}
          sx={{ my: 2, mr: 2 }}
        >
          Cancelar
        </CancelButton>,
      );
      buttons.push(
        <PrimaryButton
          type="submit"
          key="submitbutton"
          variant="primaryButton"
          sx={{ my: 2 }}
        >
          Salvar
        </PrimaryButton>,
      );
    } else {
      buttons.push(
        <SecondaryButton
          variant="secondaryButton"
          key="backButton"
          onClick={cancel}
          sx={{ my: 2, mx: 2 }}
        >
          Voltar
        </SecondaryButton>,
      );
      buttons.push(
        <PrimaryButton
          key="editbutton"
          variant="primaryButton"
          sx={{ my: 2 }}
          onClick={toggleEdit}
        >
          Editar
          <EditIcon sx={{ ml: 2 }} />
        </PrimaryButton>,
      );
    }

    return buttons;
  };

  return state.loading
    ? (
      <CircularProgress />
    )
    : (
      <Box sx={{ display: 'flex' }}>
        <UserProfileForm
          handleSubmit={handleSubmit}
          errors={errors}
          clearErrors={clearErrors}
          profile={profile}
          setProfile={setProfile}
          isNewUser={isNewUser()}
          isEditMode={state.edit}
          buttons={buttonRow()}
          dialogOpen={state.dialogOpen}
          onDialogClose={onDialogClose}
          loading={state.saving}
        />
      </Box>
    );
}

export default UserInfoPage;
