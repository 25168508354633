import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Avatar,
  Box,
  Button,
  Typography,
} from '@mui/material';

import {
  LockOutlined as LockOutlinedIcon,
} from '@mui/icons-material';

import { validator } from '../../utils';
import { authService } from '../../services';
import { PasswordField } from '../../components';
import { useSnack } from '../../hooks';

function CodeValidationPage() {
  const { setSnack } = useSnack();
  const navigate = useNavigate();
  const [errors, setErrors] = useState({
    password: '',
    confirmPassword: '',
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const password = data.get('password');
    const confirmPassword = data.get('confirmPassword');

    const passwordError = validator.password(password, true);
    const confirmPasswordError = confirmPassword !== password
      ? 'Senha e Confirmar Senha estão diferentes'
      : '';

    if (!passwordError && !confirmPasswordError) {
      try {
        const email = sessionStorage.getItem('email');
        const code = sessionStorage.getItem('code');
        const result = await authService.resetPassword({ email, code, password });
        if (result) {
          sessionStorage.clear();
          setSnack({ message: 'Senha alterada com sucesso!', type: 'sucess' });
          navigate('/');
        }
      } catch (e) {
        console.log(e);
      }
    }

    setErrors({
      password: passwordError,
      confirmPassword: confirmPasswordError,
    });
  };

  const back = () => {
    sessionStorage.removeItem('code');
    navigate(-1);
  };

  const clearErrors = () => {
    const { confirmPassword, password } = errors;
    if (confirmPassword || password) {
      setErrors({
        password: '',
        confirmPassword: '',
      });
    }
  };

  return (
    <Box
      sx={{
        my: 8,
        mx: 'auto',
        maxWidth: 400,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Esqueci a senha
      </Typography>
      <Typography sx={{ mt: 2 }}>
        Insira uma nova senha. Esta será sua nova senha de acesso à plataforma
      </Typography>
      <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
        <PasswordField error={errors.password} onChange={clearErrors} />
        <PasswordField
          id="confirmPassword"
          name="confirmPassword"
          label="Confirmar senha"
          error={errors.confirmPassword}
          onChange={clearErrors}
        />
        <Button
          fullWidth
          type="submit"
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Enviar
        </Button>
        <Button
          fullWidth
          onClick={back}
        >
          Voltar
        </Button>
      </Box>
    </Box>
  );
}

export default CodeValidationPage;
