import { useCallback, useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Fab,
  TextField,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
} from '@mui/material';

import { Refresh as RefreshIcon } from '@mui/icons-material';

import { VoucherTile } from './components';
import { establishmentService, voucherApplicationService } from '../../services';
// import SectionList from '../../components/styles/list/sectionList';
import { Establishment } from '../../models';

function VoucherListPage() {
  const [error, setError] = useState();
  const [state, setState] = useState({
    loading: true,
    refresh: true,
    vouchers: [],
  });

  const [filter, setFilter] = useState(0);
  const [establishment, setEstablishment] = useState(null);
  const [establishmentOptions, setEstablishmentOptions] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const filters = [
    [-1, 0, 1], // ALL
    [0], // PENDING
    [1], // APPROVED
    [-1], // REJECTED
  ];

  const filterNames = [
    'Todos',
    'Pendentes',
    'Aprovados',
    'Rejeitados',
  ];

  useEffect(() => {
    establishmentService.getAll().then((establishments) => {
      const options = establishments?.map((e) => new Establishment(e)) || [];
      setEstablishmentOptions(options);
    });
    return () => { };
  }, []);

  const loadLists = useCallback(() => {
    if (error) return;

    setState((oldState) => ({
      ...oldState,
      refresh: true,
    }));

    voucherApplicationService.getAll().then((vouchers) => {
      setState((oldState) => ({
        ...oldState,
        loading: false,
        refresh: false,
        vouchers: vouchers || [],
      }));
    }).catch((err) => {
      setState((oldState) => ({
        ...oldState,
        loading: false,
        refresh: false,
        vouchers: [],
      }));
      setError(err);
    });
  }, [error]);

  useEffect(() => {
    loadLists();
    const interval = setInterval(() => loadLists(), 3 * 60 * 1000);

    return () => clearInterval(interval);
  }, [error, loadLists]);

  // Throwing errors which the AppBoundary can catch
  // Errors thrown inside async functions in useEffect are not catched by the boundary
  useEffect(() => {
    if (error) {
      const err = error;
      setError(null);
      throw err;
    }
  }, [error]);

  const accept = useCallback((id) => {
    voucherApplicationService.accept(id);

    const i = state.vouchers.findIndex((v) => v.id === id);
    state.vouchers[i].status = 1;

    setState((oldState) => ({
      ...oldState,
      vouchers: state.vouchers,
    }));
  }, [state.vouchers]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const reject = useCallback((id) => {
    voucherApplicationService.reject(id);

    const i = state.vouchers.findIndex((v) => v.id === id);
    state.vouchers[i].status = 1;

    setState((oldState) => ({
      ...oldState,
      vouchers: state.vouchers,
    }));
  }, [state.vouchers]);

  return state.loading
    ? (
      <CircularProgress />
    )
    : (
      <>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h5" sx={{ mr: 4 }}><b>Vouchers</b></Typography>
            <Fab disabled={state.refresh} color="primary" size="small" onClick={loadLists}>
              <RefreshIcon sx={{ color: 'white' }} />
            </Fab>
            <Autocomplete
              popupIcon=""
              limitTags={2}
              options={establishmentOptions}
              getOptionLabel={(option) => `${option.id}-${option.name}`}
              getOptionDisabled={(option) => option.id === (establishment?.id || 0)}
              isOptionEqualToValue={(option, currentValue) => option.id === currentValue.id}
              value={establishment || null}
              onChange={(event, newValue) => { setEstablishment(newValue); }}
              sx={{ ml: 4, width: 300 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Estabelecimento"
                />
              )}
            />
          </Box>
          <Box>
            {filters.map((key, index) => (
              <Button
                key={`filter-voucher-${key}`}
                variant={filter === index ? 'contained' : 'outlined'}
                onClick={() => setFilter(index)}
                sx={{ borderRadius: 0 }}
              >
                {filterNames[index]}
              </Button>
            ))}
          </Box>
        </Box>
        <Box sx={{
          mt: 2, maxHeight: '60vh', bgcolor: 'transparent',
        }}
        >
          <Table>
            <TableHead>
              <TableRow>
                {/* Table headings */}
              </TableRow>
            </TableHead>
            <TableBody>
              {state.vouchers
                .filter((v) => filters[filter].includes(v.status))
                .filter((v) => v.establishmentId === (establishment?.id || v.establishmentId))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((voucherApplication) => (
                  <TableRow key={voucherApplication.id.toString()}>
                    <TableCell>
                      <VoucherTile
                        voucherApplication={voucherApplication}
                        accept={() => accept(voucherApplication.id)}
                        reject={() => reject(voucherApplication.id)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 50, 100]}
                  count={state.vouchers.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  labelDisplayedRows={({ from, to, count }) => `${from}–${to} de ${count !== -1 ? count : `mais de ${to}`}`}
                  labelRowsPerPage="Resultados por página"
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </Box>
      </>
    );
}

export default VoucherListPage;
