import PropTypes from 'prop-types';
import {
  Avatar,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import { ChevronRight as ChevronRightIcon } from '@mui/icons-material';

function CategoryTile({ category }) {
  return (
    <ListItemButton
      key={category.id.toString()}
      href={`/categories/${category.id}`}
    >
      <ListItemAvatar>
        <Avatar alt={`${category.name} image`} src={category.image || 'none'} />
      </ListItemAvatar>
      <ListItemText>
        {category.id}
        {' '}
        -
        {' '}
        {category.name}
      </ListItemText>
      <ChevronRightIcon />
    </ListItemButton>
  );
}

CategoryTile.propTypes = {
  category: PropTypes.object.isRequired,
};

export default CategoryTile;
