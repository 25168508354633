import { Route } from 'react-router-dom';
import {
  SupporterListPage,
  TermsPage,
  UserInfoPage,
  VoucherListPage,
  DashboardPage,
} from '../pages';

const routes = [];
/* Index */
routes.push(<Route
  index
  key="ownerIndexRoute"
  element={<VoucherListPage />}
/>);

/* Dashboard */
routes.push(
  <Route path="/dashboard" key="dashboardRoute">
    <Route key="dashboard" path="" element={<DashboardPage />} />
  </Route>,
);

/* Users */
routes.push(
  <Route path="users" key="usersRoute">
    <Route key="userDetailsRoute" path=":id" element={<UserInfoPage />} />
  </Route>,
);

/* Vouchers */
routes.push(
  <Route key="voucherRoutes" path="vouchers">
    <Route key="voucherListRoute" path="" element={<VoucherListPage />} />
  </Route>,
);

/* Supporters */
routes.push(
  <Route key="supporterRoute" path="supporters">
    <Route key="supporterListRoute" path="" element={<SupporterListPage />} />
  </Route>,
);

/* Terms */
routes.push(
  <Route key="termsRoute" path="terms">
    <Route path="" element={<TermsPage />} />
  </Route>,
);

export default routes;
