import { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import {
  Box,
  Button,
  TextField,
  CircularProgress,
  Card,
  CardMedia,
  CardActions,
  IconButton,
} from '@mui/material';

import {
  CameraAlt as CameraAltIcon,
  Delete as DeleteIcon,
  Close as CloseIcon,
} from '@mui/icons-material';

import { PrimaryButton, SecondaryButton, CancelButton } from '../../../components/styles/buttons';
import { DeleteDialog } from '../../../components';

function NewsForm({
  newsItem,
  updateNewsItem,
  isNewNews,
  handleSubmit,
  onDialogConfirm,
  loading,
}) {
  const navigate = useNavigate();
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleFileChange = (e) => {
    const newFiles = Array.from(e.target.files ?? []);
    if (newFiles.length > 0) {
      updateNewsItem('image', null);
    }
    const filesToUpload = newFiles;
    updateNewsItem('filesToUpload', filesToUpload);
  };

  const cancelUpload = (image, isExistingImage = false) => {
    if (isExistingImage) {
      updateNewsItem('image', null);
      updateNewsItem('filesToUpload', []);
    } else {
      const filesToUpload = newsItem.filesToUpload.filter((i) => !(i.name === image.name));
      updateNewsItem('filesToUpload', filesToUpload);
    }
  };

  const handleCancel = () => {
    navigate('/news/');
  };

  const openDeleteDialog = () => {
    setDialogOpen(true);
  };

  const onDialogClose = (confirm) => {
    setDialogOpen(false);
    if (confirm) {
      onDialogConfirm();
    }
  };

  const renderImages = () => {
    if (newsItem.filesToUpload && newsItem.filesToUpload.length > 0) {
      const lastImage = newsItem.filesToUpload[newsItem.filesToUpload.length - 1];
      return (
        <Card key={lastImage.name} sx={{ mr: 1, width: 120 }}>
          <CardMedia
            component="img"
            height={120}
            alt="Nova Imagem Carregada"
            src={URL.createObjectURL(lastImage)}
          />
          <CardActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <IconButton size="small" color="error" onClick={() => cancelUpload(lastImage)}>
              <CloseIcon />
            </IconButton>
          </CardActions>
        </Card>
      );
    }
    if (newsItem.image) {
      return (
        <Card sx={{ mr: 1, width: 120 }}>
          <CardMedia
            component="img"
            height={120}
            alt="Imagem Existente"
            src={newsItem.image}
          />
          <CardActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <IconButton size="small" color="error" onClick={() => cancelUpload(newsItem.image, true)}>
              <CloseIcon />
            </IconButton>
          </CardActions>
        </Card>
      );
    }
    return null;
  };

  return (
    <Box component="form" noValidate onSubmit={handleSubmit}>
      {isNewNews ? 'Nova Novidade' : 'Editar Novidade'}
      <TextField
        required
        fullWidth
        label="Título"
        value={newsItem.title}
        onChange={(e) => updateNewsItem('title', e.target.value)}
        sx={{ mb: 2 }}
      />
      <ReactQuill
        theme="snow"
        value={newsItem.description}
        onChange={(content, delta, source, editor) => updateNewsItem('description', editor.getHTML())}
        placeholder="Digite a descrição aqui..."
        style={{ height: '200px', marginBottom: '60px' }}
      />
      <Box sx={{
        mt: 2,
        pb: 1,
        display: 'flex',
        overflowX: 'auto',
      }}
      >
        <Button
          component="label"
          key="imageInput"
          sx={{
            maxWidth: 120,
            minWidth: 120,
            height: 120,
            border: '1px solid',
            borderColor: 'primary.main',
            mr: 1,
          }}
        >
          <CameraAltIcon />
          <input
            type="file"
            name="imageInput"
            accept="image/*"
            onChange={handleFileChange}
            hidden
          />
        </Button>
        {renderImages()}
      </Box>
      {
        loading
          ? <CircularProgress />
          : (
            <Box sx={{ my: 2 }}>
              <PrimaryButton
                type="submit"
                key="submitButton"
                sx={{ minWidth: 160 }}
                variant="primaryButton"
              >
                Salvar
              </PrimaryButton>
              <SecondaryButton
                variant="secondaryButton"
                key="cancelButton"
                onClick={handleCancel}
                sx={{ minWidth: 160, ml: 2 }}
              >
                Cancelar
              </SecondaryButton>
              {isNewNews ? null
                : (
                  <CancelButton
                    key="deleteButton"
                    onClick={openDeleteDialog}
                    sx={{ my: 2, mr: 2, color: 'error.main' }}
                  >
                    <DeleteIcon sx={{ mr: 2 }} />
                    {' '}
                    Deletar Novidade
                  </CancelButton>
                )}
            </Box>
          )
      }
      <DeleteDialog label="Deletar Novidade?" open={dialogOpen} onClose={onDialogClose} />
    </Box>
  );
}

NewsForm.propTypes = {
  newsItem: PropTypes.object.isRequired,
  updateNewsItem: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  isNewNews: PropTypes.bool.isRequired,
  onDialogConfirm: PropTypes.func.isRequired,
};

export default NewsForm;
