import { ENDPOINTS } from '../constants';
import { Establishment } from '../models';
import { parseResponse, defaultHeaders } from '../utils';

class EstablishmentService {
  async getAll(filterOptions) {
    const url = ENDPOINTS.parse(ENDPOINTS.ESTABLISHMENT_ALL, filterOptions);
    const response = await fetch(url, {
      method: 'GET',
      headers: defaultHeaders(),
    });

    return parseResponse(response, Establishment);
  }

  async get(id) {
    if (!id) return null;

    const response = await fetch(ENDPOINTS.ESTABLISHMENT_ID(id), {
      method: 'GET',
      headers: defaultHeaders(),
    });

    return parseResponse(response, Establishment);
  }

  async create(data) {
    const establishment = data instanceof Establishment ? data : new Establishment(data);
    if (!establishment.isValid()) throw new Error('Invalid Establishment');

    const response = await fetch(ENDPOINTS.ESTABLISHMENT, {
      method: 'POST',
      headers: defaultHeaders(),
      body: establishment.json(),
    });

    return parseResponse(response, Establishment);
  }

  async update(data) {
    const establishment = data instanceof Establishment ? data : new Establishment(data);
    if (!establishment.isValid()) throw new Error('Invalid Establishment');

    const response = await fetch(ENDPOINTS.ESTABLISHMENT_ID(establishment.id), {
      method: 'PUT',
      headers: defaultHeaders(),
      body: establishment.json(),
    });

    return parseResponse(response, Establishment);
  }

  async delete(id) {
    if (!id) return null;

    const response = await fetch(ENDPOINTS.ESTABLISHMENT_ID(id), {
      method: 'DELETE',
      headers: defaultHeaders(),
    });

    return parseResponse(response);
  }

  async addOwner(id, userId) {
    const response = await fetch(ENDPOINTS.ESTABLISHMENT_OWNER(id, userId), {
      method: 'PUT',
      headers: defaultHeaders(),
    });

    return parseResponse(response, Establishment);
  }

  async removeOwner(id, userId) {
    const response = await fetch(ENDPOINTS.ESTABLISHMENT_OWNER(id, userId), {
      method: 'DELETE',
      headers: defaultHeaders(),
    });

    return parseResponse(response, Establishment);
  }

  async addCulinary(id, culinaries) {
    const response = await fetch(ENDPOINTS.ESTABLISHMENT_CATEGORY(id), {
      method: 'PUT',
      headers: defaultHeaders(),
      body: {
        culinaries,
      },
    });

    return parseResponse(response, Establishment);
  }

  async addCategories(id, categories) {
    const response = await fetch(ENDPOINTS.ESTABLISHMENT_CULINARY(id), {
      method: 'PUT',
      headers: defaultHeaders(),
      body: {
        categories,
      },
    });
    return parseResponse(response, Establishment);
  }

  async updateHighlight(id, enable) {
    if (!(id && parseInt(id, 10))) throw new Error('Invalid Establishment');

    const response = await fetch(ENDPOINTS.ESTABLISHMENT_ID(id), {
      method: 'PUT',
      headers: defaultHeaders(),
      body: JSON.stringify({ highlight: !!enable }),
    });

    return parseResponse(response, Establishment);
  }
}

export default new EstablishmentService();
