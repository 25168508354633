import PropTypes from 'prop-types';
import {
  Avatar,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import { ChevronRight as ChevronRightIcon } from '@mui/icons-material';

function CulinaryTile({ culinary }) {
  return (
    <ListItemButton
      key={culinary.id.toString()}
      href={`/culinaries/${culinary.id}`}
    >
      <ListItemAvatar>
        <Avatar alt={`${culinary.name} image`} src={culinary.image || 'none'} />
      </ListItemAvatar>
      <ListItemText>
        {culinary.id}
        {' '}
        -
        {' '}
        {culinary.name}
      </ListItemText>
      <ChevronRightIcon />
    </ListItemButton>
  );
}

CulinaryTile.propTypes = {
  culinary: PropTypes.object.isRequired,
};

export default CulinaryTile;
