import { City, Establishment } from './index';

class User {
  constructor(data) {
    this.id = data?.id;
    this.name = data?.name;
    this.email = data?.email;
    this.password = data?.id ? null : data?.password;
    this.avatar = data?.avatar;
    this.City = data?.City == null ? null : new City(data.City);
    this.cityId = data?.cityId || data?.City?.id || null;
    this.State = data?.State;
    this.stateId = data?.stateId || data?.State?.id || null;
    this.Role = data?.Role;
    this.roleId = data?.roleId || data?.Role?.id || null;

    this.Establishments = data?.Establishments?.map((e) => new Establishment(e)) || [];
    this.establishments = data?.Establishments?.map((e) => e.id) || [];
    this.Cities = data?.Cities?.map((c) => new City(c)) || [];
    this.cities = data?.Cities?.map((c) => c.id) || [];
    this.tokens = data?.Tokens || null;
  }

  /// Check if this is a valid user.
  isValid() {
    if (!this.id && !this.password) return false;
    if (!this.name || !this.email) return false;
    if (!this.Role && !this.roleId) return false;

    return true;
  }

  json() {
    return JSON.stringify(this);
  }
}

export default User;
