import { ENDPOINTS } from '../constants';
import { Plan } from '../models';
import { parseResponse, defaultHeaders } from '../utils';

class PlanService {
  async getAll(query) {
    const url = ENDPOINTS.parse(ENDPOINTS.PLAN, query);
    const response = await fetch(url, {
      method: 'GET',
      headers: defaultHeaders(),
    });

    return parseResponse(response, Plan);
  }

  async get(id) {
    if (!id) return null;

    const response = await fetch(ENDPOINTS.PLAN_ID(id), {
      method: 'GET',
      headers: defaultHeaders(),
    });
    const plan = await parseResponse(response, Plan);

    if (plan.Categories) {
      plan.categories = plan.Categories;
      delete plan.Categories;
    }

    console.log(plan);
    return plan;
  }

  async create(data) {
    const plan = new Plan(data);
    if (!plan.isValid()) throw new Error('Invalid Plan');

    const response = await fetch(ENDPOINTS.PLAN, {
      method: 'POST',
      headers: defaultHeaders(),
      body: plan.json(),
    });

    return parseResponse(response);
  }

  async update(data) {
    const plan = new Plan(data);
    if (!plan.isValid(true)) throw new Error('Invalid plan');

    const response = await fetch(ENDPOINTS.PLAN_ID(plan.id), {
      method: 'PUT',
      headers: defaultHeaders(),
      body: plan.json(),
    });

    return parseResponse(response);
  }

  async delete(id) {
    if (!id) return null;

    const response = await fetch(ENDPOINTS.PLAN_ID(id), {
      method: 'DELETE',
      headers: defaultHeaders(),
    });

    return parseResponse(response);
  }
}

export default new PlanService();
