import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

const CancelButton = styled(Button)`
  ${() => `
    transition: 0.2s ease-in-out all;
    &:hover {
      opacity: 0.8;
      background-color: none;
      transition: 0.3s ease-in-out all;
    }
  `}
`;

export default CancelButton;
