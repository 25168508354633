class Category {
  constructor({
    id, name, image, plan,
  }) {
    this.id = id;
    this.name = name;
    this.plan = plan;
    this.image = image || 'none';
  }

  /// Check if this is valid. It is valid if all required data has been informed.
  isValid(checkId) {
    if (checkId && !this.id) return false;
    if (!this.name) return false;

    return true;
  }

  json() {
    return JSON.stringify(this);
  }
}

export default Category;
