class News {
  constructor({
    id = '', title = '', description = '', image = '',
  } = {}) {
    this.id = id;
    this.title = title;
    this.description = description;
    this.image = image;
  }

  isValid() {
    if (!this.title || !this.description) return false;
    return true;
  }

  json() {
    const obj = {
      title: this.title,
      description: this.description,
      image: this.image,
    };
    if (this.id) obj.id = this.id;
    return JSON.stringify(obj);
  }
}

export default News;
