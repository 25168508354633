import { useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from '@mui/material';

import {
  LockOutlined as LockOutlinedIcon,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

import { authService } from '../../services';
import { useSnack } from '../../hooks';

function CodeValidationPage() {
  const navigate = useNavigate();
  const { setSnack } = useSnack();

  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);

  const handleOnChange = (e) => {
    const regex = /^[0-9\b]+$/;
    const value = e.target.value.slice(0, 6);
    if (value === '' || regex.test(value)) {
      setCode(value);
      if (value.length === 6) {
        e.target.blur();
      }
    }
  };

  const handleSubmit = async (event) => {
    event?.preventDefault();
    setLoading(true);
    let error = (code?.length || 0) !== 6 ? 'Código deve ter 6 dígitos' : '';

    if (!error) {
      try {
        const email = sessionStorage.getItem('email');
        const result = await authService.validateCode({ email, code });
        setLoading(false);

        if (result) {
          sessionStorage.setItem('code', code);

          return navigate('/forgot/new');
        }
      } catch (e) {
        setLoading(false);
        error = 'Código inválido';
      }
    }

    setLoading(false);
    return setSnack({ message: error });
  };

  const back = () => {
    sessionStorage.removeItem('email');
    navigate(-1);
  };

  return (
    <Box
      sx={{
        my: 8,
        mx: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: 400,
      }}
    >
      <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Esqueci a senha
      </Typography>
      <Typography sx={{ my: 2 }}>
        Confira seu e-mail e insira o código abaixo informado abaixo para prosseguir
      </Typography>
      <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
        <TextField
          fullWidth
          type="tel"
          value={code}
          onChange={handleOnChange}
          inputProps={{
            style: {
              textAlign: 'center',
              letterSpacing: 12,
            },
            maxLength: 6,
          }}
        />
        {loading
          ? <CircularProgress />
          : (
            <Button
              fullWidth
              type="submit"
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Entrar
            </Button>
          )}
        <Button
          fullWidth
          sx={{ mx: 1 }}
          onClick={back}
        >
          Voltar
        </Button>
      </Box>
    </Box>
  );
}

export default CodeValidationPage;
