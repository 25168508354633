import { Route } from 'react-router-dom';
import {
  EstablishmentInfoPage,
  EstablishmentListPage,
  SupporterListPage,
  TermsPage,
  // EstablishmentInfoPage,
  // EstablishmentListPage,
  UserInfoPage,
  UserListPage,
  DashboardPage,
} from '../pages';

const routes = [];
/* Index */
routes.push(<Route
  index
  key="franchiseeIndexRoute"
  element={<DashboardPage />}
/>);

/* Dashboard */
routes.push(
  <Route path="/dashboard" key="dashboardRoute">
    <Route key="dashboard" path="" element={<DashboardPage />} />
  </Route>,
);

/* Users */
routes.push(
  <Route key="userRoutes" path="users">
    <Route key="userListRoute" path="" element={<UserListPage />} />
    <Route key="userDetailsRoute" path=":id" element={<UserInfoPage />} />
  </Route>,
);

/* Establishments */
routes.push(
  <Route key="establishmentsRoutes" path="establishments">
    <Route key="establishmentsListRoute" path="" element={<EstablishmentListPage />} />
    <Route key="establishmentsInfoRoute" path=":id" element={<EstablishmentInfoPage />} />
  </Route>,
);

/* Supporters */
routes.push(
  <Route key="supporterRoute" path="supporters">
    <Route key="supporterListRoute" path="" element={<SupporterListPage />} />
  </Route>,
);

/* Terms */
routes.push(
  <Route key="termsRoute" path="terms">
    <Route key="termsPageRoute" path="" element={<TermsPage />} />
  </Route>,
);

export default routes;
