class ContactType {
  constructor({
    id, description,
  }) {
    this.id = id;
    this.description = description;
  }

  /// Check if this is a valid contact Type.
  isValid(checkId) {
    if (checkId && !this.id) return false;
    if (!this.description) return false;

    return true;
  }

  json() {
    return JSON.stringify(this);
  }
}

export default ContactType;
