import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Link,
  Paper,
  TextField,
  Typography,
} from '@mui/material';

import {
  LockOutlined as LockOutlinedIcon,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

import { validator } from '../../utils';
import { authService } from '../../services';
import { PasswordField } from '../../components';
import { useSnack } from '../../hooks';

function AuthPage({ setToken }) {
  const [errors, setErrors] = useState({
    email: '',
    password: '',
  });
  const navigate = useNavigate();
  const { setSnack } = useSnack();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const email = data.get('email');
    const password = data.get('password');

    const remember = data.get('remember') === 'on';

    const emailError = validator.email(email, true);
    const passwordError = validator.password(password, true);

    if (!emailError && !passwordError) {
      try {
        const token = await authService.login({ email, password });
        navigate('/dashboard');
        console.log(token);
        return setToken(token, remember);
      } catch (e) {
        return setSnack({ message: e.message });
      }
    } else {
      return setErrors({
        email: emailError,
        password: passwordError,
      });
    }
  };

  const clearErrors = () => {
    const { email, password } = errors;
    if (email || password) {
      setErrors({
        email: '',
        password: '',
      });
    }
  };

  const getRandomObject = (array) => {
    const randomObject = array[Math.floor(Math.random() * array.length)];
    return randomObject;
  };

  const fundos = ['/auth/imagem-1.png', '/auth/imagem-2.png', '/auth/imagem-3.png'];

  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: `url(${window.location.origin + getRandomObject(fundos)})`,
          backgroundRepeat: 'no-repeat',
          backgroundColor: 'primary.dark',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Acesso
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              error={!!errors.email}
              margin="normal"
              required
              fullWidth
              id="email"
              label="Endereço de E-mail"
              name="email"
              helperText={errors.email}
              autoComplete="email"
              autoFocus
              onChange={clearErrors}
            />
            <PasswordField error={errors.password} onChange={clearErrors} />
            <FormControlLabel
              control={<Checkbox color="primary" name="remember" id="remember" />}
              label="Manter conectado"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Entrar
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="/forgot" variant="body2">
                  Esqueceu a senha?
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

AuthPage.propTypes = {
  setToken: PropTypes.func.isRequired,
};

export default AuthPage;
