import { ENDPOINTS } from '../constants';
import { Culinary } from '../models';
import { parseResponse, defaultHeaders } from '../utils';

class CulinaryService {
  async getAll(query) {
    const url = ENDPOINTS.parse(ENDPOINTS.CULINARY, query);
    const response = await fetch(url, {
      method: 'GET',
      headers: defaultHeaders(),
    });

    return parseResponse(response, Culinary);
  }

  async get(id) {
    if (!id) return null;

    const response = await fetch(ENDPOINTS.CULINARY_ID(id), {
      method: 'GET',
      headers: defaultHeaders(),
    });

    return parseResponse(response, Culinary);
  }

  async create(data) {
    const culinary = new Culinary(data);
    if (!culinary.isValid()) throw new Error('Invalid culinary');

    const response = await fetch(ENDPOINTS.CULINARY, {
      method: 'POST',
      headers: defaultHeaders(),
      body: culinary.json(),
    });

    return parseResponse(response);
  }

  async update(data) {
    const culinary = new Culinary(data);
    if (!culinary.isValid(true)) throw new Error('Invalid culinary');

    const response = await fetch(ENDPOINTS.CULINARY_ID(culinary.id), {
      method: 'PUT',
      headers: defaultHeaders(),
      body: culinary.json(),
    });

    return parseResponse(response);
  }

  async delete(id) {
    if (!id) return null;

    const response = await fetch(ENDPOINTS.CULINARY_ID(id), {
      method: 'DELETE',
      headers: defaultHeaders(),
    });

    return parseResponse(response);
  }
}

export default new CulinaryService();
