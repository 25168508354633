import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@mui/icons-material';

function PasswordField(valueProps) {
  const [isVisible, setIsVisible] = useState(false);
  const {
    error, name, label, ...props
  } = valueProps;

  const togglePassword = () => {
    setIsVisible(!isVisible);
  };

  return (
    <TextField
      required
      fullWidth
      error={!!error}
      margin="normal"
      name={name || 'password'}
      label={label || 'Senha'}
      type={isVisible ? 'text' : 'password'}
      id="password"
      autoComplete="current-password"
      helperText={error}
      InputProps={
        {
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={togglePassword}
              >
                {isVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }
      }
      {...props}
    />
  );
}

PasswordField.defaultProps = {
  label: undefined,
  name: undefined,
};

PasswordField.propTypes = {
  error: PropTypes.string.isRequired,
  label: PropTypes.string,
  name: PropTypes.string,
};

export default PasswordField;
