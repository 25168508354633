import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import randomColor from 'randomcolor';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

function ChartDisplay({ stats, establishments }) {
  console.log('Estabelecimentos:');
  console.log(establishments);
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Relação de Vouchers utilizados por período',
      },
      Tooltip: {
        enabled: true,
      },
    },
  };
  const labels = [];
  const porDia = stats.reduce((diasPorHora, stat) => {
    const diaSemTratar = new Date(stat.createdAt);
    const dia = moment(diaSemTratar, 'YYYY-MM-DD').subtract('3', 'hours').format('DD/MM/YYYY');
    if (!diasPorHora[dia]) {
      diasPorHora[dia] = [];
    }
    diasPorHora[dia].push(stat);
    return diasPorHora;
  }, {});

  const establishmentNames = stats.reduce((names, stat) => {
    if (!names[stat.establishmentId]) {
      names[stat.establishmentId] = stat.Establishment.name;
    }
    return names;
  }, {});

  Object.keys(porDia).forEach((key) => labels.push(key));

  const dataset = [];
  const porEstablishments = stats.reduce((establishmentIds, stat) => {
    if (!establishmentIds[stat.establishmentId]) {
      establishmentIds[stat.establishmentId] = [];
    }
    establishmentIds[stat.establishmentId].push(stat);
    return establishmentIds;
  }, {});

  const datasetPerEstablishments = [];
  const datasetEstablishmentsFinal = [];
  if (establishments.length > 0) {
    Object.keys(porEstablishments).forEach((estId) => {
      if (!datasetPerEstablishments[estId]) {
        datasetPerEstablishments[estId] = {
          label: establishmentNames[estId],
          data: [],
          backgroundColor: randomColor(),
        };
      }
      Object.keys(porEstablishments[estId]).forEach((estKey) => {
        labels.forEach((diaKey) => {
          console.log(`Dia key from labels: ${diaKey}`);
          if (!datasetPerEstablishments[estId].data[diaKey]) {
            datasetPerEstablishments[estId].data[diaKey] = 0;
          }
          if (porEstablishments[estId][estKey]) {
            const diaKeyMoment = moment(diaKey, 'DD/MM/YYYY').format('DD/MM/YYYY');
            const diaSemTratar = new Date(porEstablishments[estId][estKey].createdAt);
            const diaEstMoment = moment(diaSemTratar, 'YYYY-MM-DD').subtract('3', 'hours').format('DD/MM/YYYY');
            if (diaKeyMoment === diaEstMoment) {
              // console.log('Tem data');
              // console.log(`Dia key moment ${diaKeyMoment}`);
              // console.log(`Dia do estabelecimento ${diaEstMoment}`);
              datasetPerEstablishments[estId].data[diaKey] += 1;
            }
          }
        });
      });
    });
    Object.keys(datasetPerEstablishments).forEach(
      (key) => datasetEstablishmentsFinal.push({
        ...datasetPerEstablishments[key],
        data: Object.values(datasetPerEstablishments[key].data),
      }),
    );
  }

  Object.keys(porDia).forEach((key) => dataset.push(porDia[key].length));

  const data = {
    labels,
    datasets: establishments.length > 0 ? datasetEstablishmentsFinal : [
      {
        label: 'Vouchers Utilizados',
        data: dataset,
        backgroundColor: '#AF4700',
      },
    ],
  };

  return (
    <div>
      <Bar options={options} data={data} />
    </div>
  );
}

ChartDisplay.propTypes = {
  stats: PropTypes.array.isRequired,
  establishments: PropTypes.array.isRequired,
};

export default ChartDisplay;
