import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider, CssBaseline, Button } from '@mui/material';
import { SnackbarProvider } from 'notistack';

import theme from './theme';
import App from './App';

const notistackRef = React.createRef();
const onClickDismiss = (key) => () => {
  notistackRef.current.closeSnackbar(key);
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline enableColorScheme />
      <SnackbarProvider
        preventDuplicate
        maxSnack={3}
        autoHideDuration={3000}
        ref={notistackRef}
        action={(key) => (
          <Button sx={{ color: 'white' }} onClick={onClickDismiss(key)}>
            Dismiss
          </Button>
        )}
      >
        <App />
      </SnackbarProvider>
    </ThemeProvider>
  </React.StrictMode>,
);
