import { useState, useEffect } from 'react';
import moment from 'moment';
import {
  Box,
  TextField,
  Grid,
  Typography,
  Tooltip,
  Chip,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  CircularProgress,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import {
  Diamond,
} from '@mui/icons-material';

import { establishmentService, statsService, userService } from '../../services';

import StatsChartDisplay from './components/StatsChartDisplay';

function StatsPage() {
  const [filterData, setFilterData] = useState({
    startDate: moment().subtract('days', 1).format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
    status: 1,
    cityId: '',
    establishments: [],
    establishment: 0,
    franchiseeId: '',
    franchisee: 0,
  });
  const [stats, setStats] = useState({
    loading: true,
    stats: [],
  });
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const [totalVouchersUsed, setTotalVouchersUsed] = useState(0);
  const [totalUser, setTotalUser] = useState(0);
  const [establishments, setEstablishments] = useState([]);
  const [users, setUsers] = useState([]);
  const [errors, setErrors] = useState([]);
  const handleStartDateChange = async (startDate) => {
    setFilterData((prevState) => ({
      ...prevState,
      startDate: moment(startDate).format('YYYY-MM-DD'),
    }));
  };
  const handleEndDateChange = async (endDate) => {
    setFilterData((prevState) => ({
      ...prevState,
      endDate: moment(endDate).format('YYYY-MM-DD'),
    }));
  };

  useEffect(() => {
    establishmentService.getAll()
      .then((est) => setEstablishments(est)).then(() => userService.getAll({ roleId: 2 })
        .then((u) => setUsers(u.rows))).catch((err) => setErrors(err));
  }, [errors]);

  useEffect(() => {
    setStats((prevState) => ({ ...prevState, loading: true }));
    statsService.getAll({ ...filterData })
      .then((s) => {
        const tratados = [];
        s.forEach((voucherApp) => {
          if (
            voucherApp.Voucher.mealCost !== 0
            && voucherApp.Voucher.discount !== 0
            && voucherApp.Voucher.people !== 0
            && tratados.find((v) => v.Voucher.id === voucherApp.Voucher.id) === undefined
          ) {
            tratados.push(voucherApp);
          }
          if (voucherApp.Voucher.Diamond !== null) {
            if (tratados.find((v) => v.isDiamond === voucherApp.Voucher.Diamond.id) === undefined) {
              tratados.push({
                ...voucherApp,
                id: voucherApp.Voucher.Diamond.id,
                Voucher: {
                  ...voucherApp.Voucher,
                  mealCost: voucherApp.Voucher.Diamond.mealCost,
                  discount: voucherApp.Voucher.Diamond.discount,
                  people: voucherApp.Voucher.Diamond.people,
                },
                isDiamond: voucherApp.Voucher.Diamond.id,
              });
            }
          }
        });
        const totalDiscounts = tratados.reduce((sum, voucher) => sum + voucher.Voucher.discount, 0);
        setTotalDiscount(totalDiscounts);
        const totalCosts = tratados.reduce((sum, voucher) => sum + voucher.Voucher.mealCost, 0);
        setTotalCost(totalCosts);
        const totalUsers = tratados.reduce((sum, voucher) => sum + voucher.Voucher.people, 0);
        setTotalUser(totalUsers);
        const totalVouchers = tratados.length;
        setTotalVouchersUsed(totalVouchers);
        setStats({ stats: tratados, loading: false });
      })
      .catch((err) => setErrors(err));
  }, [filterData]);

  const handleEstablishmentChange = async (esta) => {
    setFilterData((prevState) => ({
      ...prevState,
      establishment: esta,
      establishments: esta !== 0 ? [esta] : [],
    }));
  };

  const handleFranchiseeChange = async (esta) => {
    setFilterData((prevState) => ({
      ...prevState,
      franchiseeId: esta !== 0 ? esta : '',
      franchisee: esta,
    }));
  };

  const pegaData = (data) => {
    const diaSemTratar = new Date(data);
    const diaEstMoment = moment(diaSemTratar, 'YYYY-MM-DD')/* .subtract('3', 'hours') */.format('DD/MM/YYYY [às]  HH:mm:ss');
    return diaEstMoment;
  };

  const columns = [
    {
      field: 'Voucher',
      headerName: 'Voucher/Diamante ID',
      width: 120,
      valueGetter: (params) => params.row.Voucher.id,
      renderCell: (params) => (
        <div>
          <Tooltip
            title={params.row.isDiamond !== undefined ? 'É Diamante' : 'É Voucher'}
          >
            <Chip
              label={
                params.row.isDiamond !== undefined
                  ? params.row.Voucher.Diamond.id
                  : params.value
              }
              color="warning"
              icon={params.row.isDiamond && <Diamond sx={{ '&:hover': { backgroundColor: 'transparent!important' } }} />}
            />
          </Tooltip>
        </div>
      ),
    },
    {
      field: 'data',
      headerName: 'Data',
      width: 230,
      type: 'date',
      valueGetter: (params) => pegaData(params.row.createdAt),
    },
    {
      field: 'establishment',
      headerName: 'Estabelecimento',
      width: 230,
      valueGetter: (params) => params.row.Establishment.name,
      renderCell: (params) => (
        <div>
          <Tooltip
            title={`Id: ${params.row.Establishment.id}`}
          >
            <Chip
              label={params.value}
              color="primary"
            />
          </Tooltip>
        </div>
      ),
    },
    {
      field: 'user',
      headerName: 'Cliente',
      width: 230,
      valueGetter: (params) => params.row.Voucher.UserPlan.User.name,
      renderCell: (params) => (
        <div>
          <Tooltip
            title={`Id: ${params.row.Voucher.UserPlan.User.id}`}
          >
            <Chip
              label={params.value}
              color="default"
            />
          </Tooltip>
        </div>
      ),
    },
    {
      field: 'mealCost',
      headerName: 'Custo',
      width: 150,
      valueGetter: (params) => params.row.Voucher.mealCost,
      renderCell: (params) => (
        <div>
          <Chip
            label={params.value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
            color="success"
          />
        </div>
      ),
    },
    {
      field: 'discount',
      headerName: 'Desconto',
      width: 150,
      valueGetter: (params) => params.row.Voucher.discount,
      renderCell: (params) => (
        <div>
          <Chip
            label={params.value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
            color="success"
          />
        </div>
      ),
    },
    {
      field: 'people',
      headerName: 'Pessoas',
      width: 100,
      valueGetter: (params) => params.row.Voucher.people,
    },
    /* {
      field: 'lastName',
      headerName: 'Last name',
      width: 150,
    },
    {
      field: 'age',
      headerName: 'Age',
      type: 'number',
      width: 110,
    },
    {
      field: 'fullName',
      headerName: 'Full name',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 160,
      valueGetter: (params) => `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    }, */
  ];

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <Box component="form" sx={{ display: 'block', width: '100%' }}>
        <Grid container spacing={1}>
          <Grid
            item
            xl={3}
          >
            <TextField
              name="startDate"
              type="date"
              label="Período de Início"
              fullWidth
              value={filterData.startDate}
              onChange={(event) => handleStartDateChange(event.target.value)}
              hiddenLabel
              variant="standard"
              InputProps={{
                disableUnderline: true,
                inputProps: {
                  // min: moment().subtract(30, 'days').format('YYYY-MM-DD'),
                  max: filterData.endDate,
                },
              }}
            />
          </Grid>
          <Grid
            item
            xl={3}
          >
            <TextField
              name="endDate"
              type="date"
              label="Período de Fim"
              fullWidth
              value={filterData.endDate}
              onChange={(event) => handleEndDateChange(event.target.value)}
              hiddenLabel
              variant="standard"
              InputProps={{
                disableUnderline: true,
                inputProps: {
                  min: filterData.startDate,
                  max: moment().format('YYYY-MM-DD'),
                },
              }}
            />
          </Grid>
          <Grid
            item
            xl={3}
          >
            <FormControl fullWidth sx={{ marginBottom: 2 }}>
              <InputLabel id="establishment">Estabelecimentos</InputLabel>
              <Select
                labelId="establishment"
                id="establishmentsField"
                name="establishments"
                value={filterData.establishment}
                label="Estabelecimento"
                InputProps={{ disableUnderline: true }}
                onChange={(event) => handleEstablishmentChange(event.target.value)}
              >
                <MenuItem value={0}>Nenhum estabelecimento</MenuItem>
                {establishments.map((est) => (<MenuItem value={est.id}>{est.name}</MenuItem>))}
              </Select>
              <FormHelperText sx={{ margin: '3px 0px 0px 0px' }}>Filtrar por Estabelecimento</FormHelperText>
            </FormControl>
          </Grid>
          <Grid
            item
            xl={3}
          >
            <FormControl fullWidth sx={{ marginBottom: 2 }}>
              <InputLabel id="franchisee">Franqueados</InputLabel>
              <Select
                labelId="franchisee"
                id="franchiseeField"
                name="franchisee"
                value={filterData.franchisee}
                label="Franqueado"
                InputProps={{ disableUnderline: true }}
                onChange={(event) => handleFranchiseeChange(event.target.value)}
              >
                <MenuItem value={0}>Nenhum franqueado</MenuItem>
                {users.map((est) => (<MenuItem value={est.id}>{est.name}</MenuItem>))}
              </Select>
              <FormHelperText sx={{ margin: '3px 0px 0px 0px' }}>Filtrar por Franqueado</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
        <Box sx={{ width: '100%' }}>
          <Typography variant="h5"><b>Totais por período</b></Typography>
          <Typography variant="body1">Total de Descontos: {totalDiscount.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</Typography>
          <Typography variant="body1">Total de Gastos: {totalCost.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</Typography>
          <Typography variant="body1">Total de Pessoas: {totalUser.toLocaleString()}</Typography>
          <Typography variant="body1">Total de Vouchers Utilizados: {totalVouchersUsed}</Typography>
        </Box>
        <Grid container paddingTop={10} spacing={4}>
          <Grid
            paddingTop={10}
            item
            xl={4}
          >
            {stats.loading
              ? (
                <Grid container justifyContent="center">
                  <CircularProgress />
                </Grid>
              ) : (
                <StatsChartDisplay
                  label="Custos do dia"
                  title="Custos por dia"
                  stats={stats.stats}
                  establishments={[]}
                  franchiseeId={0}
                  type="gastos"
                />
              )}
          </Grid>
          <Grid
            paddingTop={10}
            item
            xl={4}
          >
            {stats.loading
              ? (
                <Grid container justifyContent="center">
                  <CircularProgress />
                </Grid>
              ) : (
                <StatsChartDisplay
                  label="Descontos do dia"
                  title="Descontos por dia"
                  stats={stats.stats}
                  establishments={[]}
                  franchiseeId={0}
                  type="descontos"
                />
              )}
          </Grid>
          <Grid
            paddingTop={10}
            item
            xl={4}
          >
            {stats.loading
              ? (
                <Grid container justifyContent="center">
                  <CircularProgress />
                </Grid>
              ) : (
                <StatsChartDisplay
                  label="Quantas Pessoas"
                  title="Pessoas por dia"
                  stats={stats.stats}
                  establishments={[]}
                  franchiseeId={0}
                  type="pessoas"
                />
              )}
          </Grid>
        </Grid>
        <Box sx={{ width: '100%' }}>
          <Typography variant="h5"><b>Aplicação de Vouchers</b></Typography>
          {stats.loading
            ? (
              <Grid container justifyContent="center">
                <CircularProgress />
              </Grid>
            ) : (
              <DataGrid
                sx={{
                  marginTop: 2,
                }}
                rows={stats.stats}
                disableSelectionOnClick
                columns={columns}
                autoHeight
                getRowId={(row) => row.id}
                rowsPerPageOptions={[]}
                localeText={{
                  MuiTablePagination: {
                    labelDisplayedRows: ({ from, to, count }) => `${from} - ${to} de ${count}`,
                    labelRowsPerPage: 'Resultados por Página',
                    footerRowSelected: (count) => (count !== 1 ? `${count.toLocaleString()} linhas selecionadas` : `${count.toLocaleString()} linha selecionada`),
                  },
                }}
              />
            )}
        </Box>
      </Box>
    </Box>
  );
}

export default StatsPage;
