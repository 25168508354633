import { useState, useEffect } from 'react';
import moment from 'moment';

import {
  CircularProgress,
} from '@mui/material';

import ImportFormSingle from './components/ImportForm';
import { validator } from '../../utils';
import { useSnack } from '../../hooks';
import { importadorService, planService } from '../../services';

function ImportPage() {
  const { setSnack } = useSnack();
  const [state, setState] = useState({
    saving: false,
    loading: true,
    planos: [],
  });

  const [importado, setImportado] = useState({
    email: '',
    valor: 0,
    valorFinal: '',
    data: '',
    plano: 0,
  });

  const [error, setError] = useState();

  const [errors, setErrors] = useState({
    email: '',
    valor: '',
    plano: '',
    data: '',
  });

  const handleSubmit = async (event) => {
    const err = {};
    event.preventDefault();
    setState({
      ...state,
      saving: true,
    });
    const emailError = validator.email(importado.email, true);
    const dataError = validator.data(importado.data, true);
    const valorError = validator.reais(importado.valor, true);
    const planoError = validator.requiredPlan(importado.plano);
    if (emailError || dataError || valorError || planoError) {
      if (emailError) {
        err.email = emailError;
      }
      if (dataError) {
        err.data = dataError;
      }
      if (valorError) {
        err.valor = valorError;
      }
      if (planoError) {
        err.plano = planoError;
      }
      console.log(err);
      setErrors(err);
    } else {
      setState({
        ...state,
        saving: true,
      });

      try {
        const result = await importadorService.post({
          email: importado.email,
          value: importado.valorFinal,
          planId: importado.plano,
          buyDate: importado.data,
        });

        console.log(result);

        setState({
          ...state,
          saving: false,
        });
        setSnack({ type: 'success', message: 'Dados importados com sucesso' });
        setImportado({
          email: '',
          valor: 0,
          plano: '',
          data: '',
        });
      } catch (errr) {
        setSnack({ message: errr.message });
      }
    }

    setState({
      ...state,
      saving: false,
    });

    return true;
  };

  const clearErrors = () => {
    const {
      email,
      plano,
      data,
      valor,
    } = errors;

    // Avoid setState cycle
    if (email || plano || data || valor || plano) {
      setErrors({
        email: '',
        plano: '',
        data: '',
        valor: '',
      });
    }
  };

  useEffect(() => {
    if (error) return () => { };
    const actualDate = moment().format('YYYY-MM-DD');
    planService.getAll().then((list) => {
      list = list || [];
      list = list.filter((plan) => {
        const planExpiricy = moment(plan.expiry).format('YYYY-MM-DD');
        return moment(planExpiricy).isSameOrAfter(actualDate);
      });
      const plans = list.map((plan) => ({
        label: plan.name,
        id: plan.id,
      }));
      setState((oldState) => ({
        ...oldState,
        loading: false,
        planos: plans,
      }));
    }).catch((err) => {
      setError(err);
    });

    return () => { };
  }, [error]);

  // Throwing errors which the AppBoundary can catch
  // Errors thrown inside useEffect are not catched by the boundary
  useEffect(() => {
    if (error) {
      const err = error;
      setError(null);
      throw err;
    }
  }, [error]);

  return (
    state.loading ? <CircularProgress />
      : (
        <ImportFormSingle
          handleSubmit={handleSubmit}
          loading={state.saving}
          importador={importado}
          setImport={setImportado}
          errors={errors}
          planos={state.planos}
          clearErrors={clearErrors}
        />
      )
  );
}

export default ImportPage;
