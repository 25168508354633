/* eslint-disable react/no-array-index-key */
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Typography,
  TableContainer,
  Table,
  TableBody,
  CircularProgress,
  Card,
  CardMedia,
  CardActions,
  IconButton,
} from '@mui/material';

import {
  CameraAlt as CameraAltIcon,
  Close as CloseIcon,
  Delete as DeleteIcon,
  Star as StarIcon,
} from '@mui/icons-material';

import { DeleteDialog } from '../../../components';
import { EstablishmentContactRow, EstablishmentTableRow } from './index';
import { PrimaryButton, SecondaryButton, CancelButton } from '../../../components/styles/buttons';
import { Contact, Establishment } from '../../../models';
import { useSnack } from '../../../hooks';

function EstablishmentForm({
  errors,
  clearErrors,
  profile,
  updateProfile,
  isNewEstablishment,
  handleSubmit,
  onDialogConfirm,
  loading,
}) {
  const navigate = useNavigate();
  const { setSnack } = useSnack();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [newContact, setNewContact] = useState(new Contact({ establishmentId: profile.id }));

  const handleFileChange = (e) => {
    const newFiles = Array.from(e.target.files ?? []);
    const filesToUpload = profile.filesToUpload.concat(newFiles);
    updateProfile('filesToUpload', filesToUpload);
  };

  const cancelUpload = (image) => {
    const filesToUpload = profile.filesToUpload.filter((i) => !(i.name === image.name));
    updateProfile('filesToUpload', filesToUpload);
  };

  const openDeleteDialog = () => {
    setDialogOpen(true);
  };

  const onDialogClose = (confirm) => {
    setDialogOpen(false);
    if (confirm) {
      onDialogConfirm();
    }
  };

  function createOnChangeHandle(inputKey) {
    return (newValue) => {
      updateProfile(inputKey, newValue);
      clearErrors();
    };
  }

  function createData(inputKey, title, error, value, inputType = 'text') {
    const onChangeHandle = createOnChangeHandle(inputKey);
    value = value || '';

    return {
      inputKey, title, error, value, onChangeHandle, editMode: isNewEstablishment, inputType,
    };
  }

  const handleOnCreateNewContact = () => {
    if (!(newContact.typeId && newContact.information)) return;

    const contacts = [...profile.Contacts, newContact];
    updateProfile('Contacts', contacts);
    clearErrors();
    setNewContact(new Contact({ establishmentId: profile.id }));
  };

  const handleOnDeleteContact = (id) => {
    const contacts = profile.Contacts.filter((contact) => contact.id !== id);
    updateProfile('Contacts', contacts);
  };

  const handleOnTypeSelect = (newValue) => {
    const updateContact = new Contact({
      ...newContact,
      typeId: newValue.id,
      ContactType: newValue,
    });

    setNewContact(updateContact);
  };

  const handleOnInformationChange = (newValue) => {
    const updateContact = new Contact({
      ...newContact,
      information: newValue,
    });

    setNewContact(updateContact);
  };

  function createNewContactInput() {
    return (
      <EstablishmentContactRow
        key="contactNew"
        inputKey="contactNew"
        title="Novo Contato"
        contact={newContact}
        onChangeHandle={handleOnCreateNewContact}
        onDeleteHandle={() => { }}
        onTypeSelect={handleOnTypeSelect}
        onInformationChange={handleOnInformationChange}
        editMode={isNewEstablishment}
      />
    );
  }

  function createContactsData() {
    return profile.Contacts?.map((contact, index) => (
      <EstablishmentContactRow
        key={`contact${contact.id}${index}`}
        inputKey={`contact${contact.id}${index}`}
        title={contact.ContactType.description}
        contact={contact}
        onChangeHandle={() => { }}
        onDeleteHandle={() => handleOnDeleteContact(contact.id)}
        onTypeSelect={handleOnTypeSelect}
        onInformationChange={handleOnInformationChange}
      />
    ));
  }

  const selectAsAvatar = (image) => {
    setSnack({ key: 'imageHighlightChange', type: 'success', message: 'Imagem de destaque alterada' });
    updateProfile('avatar', image);
  };

  const deleteImage = (imageId) => {
    const Images = profile.Images.filter((i) => !(i.id === imageId));
    updateProfile('Images', Images);
    if (profile.avatar?.id === imageId) updateProfile('avatar', null);
  };

  const handleCancel = () => {
    navigate('/establishments/');
  };

  const rows = [
    createData('name', 'Nome', errors.name, profile.name),
    createData('address', 'Endereço', errors.address, profile.address),
    createData('City', 'City', errors.city, profile.City || {}),
    createData('morning', 'Manhã', '', profile.morning),
    createData('afternoon', 'Tarde', '', profile.afternoon),
    createData('night', 'Noite', '', profile.night),
    createData('benefits', 'Beneficios', '', profile.benefits),
    createData('rules', 'Regras de Uso', '', profile.rules, 'rich-text'),
    createData('Categories', 'Categorias', '', profile.Categories),
    createData('Culinaries', 'Culinárias', '', profile.Culinaries),
    createData('Types', 'Tipos de Atendimento', '', profile.Types),
  ];

  return (
    <Box component="form" noValidate onSubmit={handleSubmit}>
      <Box sx={{ mb: 2 }}>
        <Typography variant="h5" sx={{ pl: 0 }}>
          {isNewEstablishment ? 'Novo Estabelecimento' : 'Editar Estabelecimento'}
        </Typography>
        <Avatar
          sx={{ width: 90, height: 90, mt: 2 }}
          alt={`${profile.name} image`}
          src={profile.avatar?.url}
        />
      </Box>
      <TableContainer>
        <Table size="small">
          <TableBody>
            {rows.map((item) => <EstablishmentTableRow key={`row-${item.inputKey}`} {...item} />)}
            {createContactsData()}
            {createNewContactInput()}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{
        mt: 2,
        pb: 1,
        display: 'flex',
        overflowX: 'auto',
      }}
      >
        <Button
          component="label"
          key="imageInput"
          sx={{
            maxWidth: 120,
            minWidth: 120,
            height: 120,
            border: '1px solid',
            borderColor: 'primary.main',
            mr: 1,
          }}
        >
          <CameraAltIcon />
          <input
            type="file"
            name="imageInput"
            accept="image/*"
            onChange={handleFileChange}
            multiple
            hidden
          />
        </Button>
        {profile.filesToUpload.map((image, index) => (
          <Card key={image.name + index} sx={{ mr: 1, width: 120 }}>
            <CardMedia
              component="img"
              height={120}
              alt={`Imagem ${index + 1}`}
              src={URL.createObjectURL(image)}
            />
            <CardActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <div />
              <IconButton size="small" color="error" onClick={() => cancelUpload(image)}>
                <CloseIcon />
              </IconButton>
            </CardActions>
          </Card>
        ))}
        {profile.Images.map((image, index) => (
          <Card key={image.url + index} sx={{ mr: 1, width: 120 }}>
            <CardMedia
              component="img"
              height={120}
              alt={`Imagem ${index + 1}`}
              src={image.url}
            />
            <CardActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <IconButton
                size="small"
                sx={{ color: profile.avatar?.id === image.id ? 'primary.main' : 'disabled' }}
                onClick={
                  profile.avatar?.id === image.id
                    ? () => selectAsAvatar(null)
                    : () => selectAsAvatar(image)
                }
              >
                <StarIcon />
              </IconButton>
              <IconButton size="small" color="error" onClick={() => deleteImage(image.id)}>
                <DeleteIcon />
              </IconButton>
            </CardActions>
          </Card>
        ))}
      </Box>
      {
        loading
          ? <CircularProgress />
          : (
            <Box sx={{ my: 2 }}>
              <PrimaryButton
                type="submit"
                key="submitButton"
                sx={{ minWidth: 160 }}
                variant="primaryButton"
              >
                Salvar
              </PrimaryButton>
              <SecondaryButton
                variant="secondaryButton"
                key="cancelButton"
                onClick={handleCancel}
                sx={{ minWidth: 160, ml: 2 }}
              >
                Cancelar
              </SecondaryButton>
              {isNewEstablishment ? null
                : (
                  <CancelButton
                    key="deleteButton"
                    onClick={openDeleteDialog}
                    sx={{ my: 2, mr: 2, color: 'error.main' }}
                  >
                    <DeleteIcon sx={{ mr: 2 }} />
                    {' '}
                    Deletar Estabelecimento
                  </CancelButton>
                )}
            </Box>
          )
      }
      <DeleteDialog label="Deletar Estabelecimento?" open={dialogOpen} onClose={onDialogClose} />
    </Box>
  );
}

EstablishmentForm.propTypes = {
  errors: PropTypes.object.isRequired,
  clearErrors: PropTypes.func.isRequired,
  profile: PropTypes.instanceOf(Establishment).isRequired,
  updateProfile: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isNewEstablishment: PropTypes.bool.isRequired,
  onDialogConfirm: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default EstablishmentForm;
