import PropTypes from 'prop-types';
import {
  Avatar,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from '@mui/material';

import { useAuth } from '../../../hooks';

function SupporterTile({ supporter }) {
  const { user } = useAuth();

  const itemProps = user.roleId === 1
    ? { href: `/supporters/${supporter.id}` }
    : {
      href: supporter.url,
      target: '_blank',
      rel: 'noopener noreferrer',
    };

  return (
    <ListItemButton
      key={supporter.id.toString()}
      sx={{ maxHeight: 100 }}
      {...itemProps}
    >
      <ListItemAvatar>
        <Avatar alt={`${supporter.name} image`} src={supporter.image || 'none'} />
      </ListItemAvatar>
      <ListItemText
        primary={supporter.name}
        secondary={supporter.url}
      />
    </ListItemButton>
  );
}

SupporterTile.propTypes = {
  supporter: PropTypes.object.isRequired,
};

export default SupporterTile;
