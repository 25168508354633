class VoucherApplication {
  constructor(data) {
    this.id = data.id;
    this.status = data.status;
    this.voucherId = data.voucherId;
    this.establishmentId = data.establishmentId;
    this.Voucher = data.Voucher;
    this.Establishment = data.Establishment;
    this.createdAt = data.createdAt;
    this.user = data.Voucher?.UserPlan?.User?.name;
  }

  /// Check if this is valid. It is valid if all required data has been informed.
  isValid(checkId) {
    if (checkId && !this.id) return false;
    return true;
  }

  json() {
    return JSON.stringify(this);
  }
}

export default VoucherApplication;
