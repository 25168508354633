import { styled } from '@mui/material/styles';
import List from '@mui/material/List';

const SectionList = styled(List)`
  ${({ theme }) => `
    transition: 0.2s ease-in-out all;
    a:hover {
      background-color: ${theme.palette.secondary.main};
      color: ${theme.palette.primary.main};
      transition: 0.2 ease-in-out all;
    }
  `}
`;

export default SectionList;
