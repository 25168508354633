import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box, Button,
} from '@mui/material';
import { formatter } from '../../../utils';

function PeriodPicker({
  value, onChangeHandle, readOnly,
}) {
  const [state, setState] = useState(formatter.formatNumberToPeriodObject(value));

  const handleClick = (period) => {
    if (readOnly) return;

    const newState = state;
    newState[period] = !state[period];

    onChangeHandle(formatter.formatPeriodObjectToNumber(newState));
    setState(newState);
  };

  return (
    <Box>
      {
        Object.entries(state).map(([period, selected]) => (
          <Button
            key={period}
            variant={selected ? 'contained' : 'outlined'}
            onClick={() => handleClick(period)}
            sx={{ borderRadius: 0 }}
          >
            {formatter.getStringForPeriod(period)}
          </Button>
        ))
      }
    </Box>
  );
}

PeriodPicker.defaultProps = {
  value: null,
  readOnly: false,
};

PeriodPicker.propTypes = {
  readOnly: PropTypes.bool,
  value: PropTypes.number,
  onChangeHandle: PropTypes.func.isRequired,
};

export default PeriodPicker;
