import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import randomColor from 'randomcolor';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

function StatsChartDisplay({
  title,
  label,
  stats,
  establishments,
  franchiseeId,
  type,
}) {
  console.log(franchiseeId);
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: title,
      },
      Tooltip: {
        enabled: true,
      },
    },
  };
  const labels = [];
  const dataset = [];
  switch (type) {
    case 'gastos': {
      const porDia = stats.reduce((diasPorHora, stat) => {
        const diaSemTratar = new Date(stat.createdAt);
        const dia = moment(diaSemTratar, 'YYYY-MM-DD').subtract('3', 'hours').format('DD/MM/YYYY');
        if (!diasPorHora[dia]) {
          diasPorHora[dia] = [];
        }
        diasPorHora[dia].push(stat);
        return diasPorHora;
      }, {});
      Object.keys(porDia).forEach((key) => labels.push(key));
      const valores = stats.reduce((valoresTotal, stat) => {
        const diaSemTratar = new Date(stat.createdAt);
        const dia = moment(diaSemTratar, 'YYYY-MM-DD').subtract('3', 'hours').format('DD/MM/YYYY');
        const index = labels.findIndex((element) => element === dia);
        if (index > -1) {
          if (!dataset[index]) {
            dataset[index] = 0;
          }
          dataset[index] += stat.Voucher.mealCost;
        }
        valoresTotal += stat.Voucher.mealCost;
        return valoresTotal;
      }, 0);
      console.log(valores);
    }
      break;
    case 'descontos': {
      const porDia = stats.reduce((diasPorHora, stat) => {
        const diaSemTratar = new Date(stat.createdAt);
        const dia = moment(diaSemTratar, 'YYYY-MM-DD').subtract('3', 'hours').format('DD/MM/YYYY');
        if (!diasPorHora[dia]) {
          diasPorHora[dia] = [];
        }
        diasPorHora[dia].push(stat);
        return diasPorHora;
      }, {});
      Object.keys(porDia).forEach((key) => labels.push(key));
      const valores = stats.reduce((valoresTotal, stat) => {
        const diaSemTratar = new Date(stat.createdAt);
        const dia = moment(diaSemTratar, 'YYYY-MM-DD').subtract('3', 'hours').format('DD/MM/YYYY');
        const index = labels.findIndex((element) => element === dia);
        if (index > -1) {
          if (!dataset[index]) {
            dataset[index] = 0;
          }
          dataset[index] += stat.Voucher.discount;
        }
        valoresTotal += stat.Voucher.discount;
        return valoresTotal;
      }, 0);
      console.log(valores);
    }
      break;
    case 'pessoas': {
      const porDia = stats.reduce((diasPorHora, stat) => {
        const diaSemTratar = new Date(stat.createdAt);
        const dia = moment(diaSemTratar, 'YYYY-MM-DD').subtract('3', 'hours').format('DD/MM/YYYY');
        if (!diasPorHora[dia]) {
          diasPorHora[dia] = [];
        }
        diasPorHora[dia].push(stat);
        return diasPorHora;
      }, {});
      Object.keys(porDia).forEach((key) => labels.push(key));
      const valores = stats.reduce((valoresTotal, stat) => {
        const diaSemTratar = new Date(stat.createdAt);
        const dia = moment(diaSemTratar, 'YYYY-MM-DD').subtract('3', 'hours').format('DD/MM/YYYY');
        const index = labels.findIndex((element) => element === dia);
        if (index > -1) {
          if (!dataset[index]) {
            dataset[index] = 0;
          }
          dataset[index] += stat.Voucher.people;
        }
        valoresTotal += stat.Voucher.people;
        return valoresTotal;
      }, 0);
      console.log(valores);
    }
      break;
    default:
      break;
  }
  const datasetEstablishmentsFinal = [];
  const data = {
    labels,
    datasets: establishments.length > 0 ? datasetEstablishmentsFinal : [
      {
        label,
        data: dataset,
        backgroundColor: randomColor(),
      },
    ],
  };
  return (
    <div>
      <Bar options={options} data={data} />
    </div>
  );
}

StatsChartDisplay.propTypes = {
  title: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  stats: PropTypes.array.isRequired,
  establishments: PropTypes.array.isRequired,
  franchiseeId: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
};

export default StatsChartDisplay;
