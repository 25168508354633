import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  CircularProgress,
} from '@mui/material';

import { EstablishmentForm } from './components';

import { establishmentService, uploadService, userService } from '../../services';
import { validator } from '../../utils';
import { useSnack, useAuth } from '../../hooks';
import { Establishment } from '../../models';
import { UnauthorizedError } from '../../errors';
import { ROLES } from '../../constants';

const { ADMIN, OWNER } = ROLES;

function EstablishmentInfoPage() {
  const { id: pathId } = useParams();
  const { setSnack } = useSnack();
  const { user: currentUser, setUser } = useAuth();
  const navigate = useNavigate();

  const [state, setState] = useState({
    id: pathId,
    loading: true,
    saving: false,
    edit: true,
    dialogOpen: false,
    profile: new Establishment(),
  });

  if (state.id !== currentUser.Establishment?.id && currentUser.roleId === OWNER) {
    setState((oldState) => ({
      ...oldState,
      id: currentUser.Establishment?.id || 'new',
    }));
  }

  const loadProfile = useCallback((id) => {
    if (parseInt(id, 10)) {
      establishmentService.get(id).then((data) => {
        console.log(data);
        setState((oldState) => ({
          ...oldState,
          loading: false,
          profile: data,
        }));
      })
        .catch(() => {
          setState((oldState) => ({
            ...oldState,
            loading: false,
          }));
        });
    } else {
      setState((oldState) => ({
        ...oldState,
        loading: false,
        edit: true,
      }));
    }
  }, []);

  useEffect(() => {
    if (
      currentUser.roleId !== ADMIN // Não é admin
      && currentUser.Establishment != null // Já criou o próprio estabelecimento
      && currentUser.Establishment.id !== parseInt(state.id, 10) // Não é dono deste id
    ) {
      setState(() => { throw new UnauthorizedError(); });
    }

    return () => { };
  }, [currentUser, state.id]);

  useEffect(() => {
    loadProfile(state.id);

    return () => { };
  }, [loadProfile, state.id]);

  const [errors, setErrors] = useState({
    name: '',
    address: '',
    city: '',
  });

  function isNewEstablishment() {
    return state.profile.id == null;
  }

  const cancel = () => {
    navigate('/establishments/');
  };

  async function uploadImages(images) {
    try {
      const promises = [];
      images.forEach((image) => {
        promises.push(uploadService.image(image));
      });

      const imageArray = (await Promise.all(promises)).filter((e) => e != null);
      return imageArray;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setState((oldState) => ({
      ...oldState,
      edit: false,
      saving: true,
    }));

    const imageArray = (await uploadImages(state.profile.filesToUpload)).map((i) => ({
      url: i,
      isAvatar: false,
      establishmentId: state.profile.id,
    }));

    state.profile.cityId = state.profile.City?.id;
    state.profile.Images = state.profile.Images.concat(imageArray);
    state.profile.filesToUpload = [];

    state.profile.updateAvatar(state.profile.avatar?.id || null);

    const nameError = validator.name(state.profile.name, true);
    const addressError = validator.required(state.profile.address);
    const cityError = validator.required(state.profile.cityId);

    if (!addressError && !nameError && !cityError) {
      try {
        state.profile.setDaysFromPeriods();

        let newProfile;
        if (isNewEstablishment()) {
          newProfile = await establishmentService.create(state.profile);
        } else {
          newProfile = await establishmentService.update(state.profile);
        }

        if (currentUser.roleId === OWNER && currentUser.Establishment == null) {
          const reloadedUser = await userService.get(currentUser.id);
          setUser(reloadedUser);
        }

        setState((oldState) => ({
          ...oldState,
          saving: false,
          id: newProfile.id || 'new',
        }));

        loadProfile(newProfile?.id);
        return setSnack({
          type: 'success',
          message: 'Cadastro salvo com sucesso',
        });
      } catch (e) {
        setState((oldState) => ({
          ...oldState,
          saving: false,
        }));
        let { message } = e;
        if (e?.submessage === 'User is not assigned to this city') {
          message = 'Usuário não pode cadastrar um restaurante em uma cidade a qual não foi designado';
        }

        return setSnack({ message });
      }
    } else {
      return setErrors({
        name: nameError,
        address: addressError,
        city: cityError,
      });
    }
  };

  const clearErrors = () => {
    const { address, name, city } = errors;

    // Avoid setState cycle
    if (address || name || city) {
      setErrors({
        name: '',
        address: '',
        city: '',
      });
    }
  };

  const handleDelete = () => {
    establishmentService.delete(state.profile.id).then((success) => {
      if (success) {
        cancel();
        setSnack({
          type: 'success',
          message: 'Estabelecimento apagado com sucesso',
        });
      } else {
        setSnack();
      }
    }).catch((error) => {
      setSnack({ message: error.message });
    });
  };

  return state.loading
    ? (
      <CircularProgress />
    )
    : (
      <EstablishmentForm
        handleSubmit={handleSubmit}
        errors={errors}
        clearErrors={clearErrors}
        profile={state.profile}
        updateProfile={(key, value) => {
          const { profile } = state;
          profile[key] = value;
          setState((oldState) => ({ ...oldState, profile }));
        }}
        isNewEstablishment={isNewEstablishment()}
        isEditMode={state.edit}
        onDialogConfirm={handleDelete}
        loading={state.saving}
      />
    );
}

export default EstablishmentInfoPage;
