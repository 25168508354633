import PropTypes from 'prop-types';
import moment from 'moment';
import { NumericFormat } from 'react-number-format';
import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
  // InputAdornment,
} from '@mui/material';

import { PrimaryButton } from '../../../components/styles/buttons/index';

function ImportFormSingle({
  errors,
  clearErrors,
  importador,
  setImport,
  handleSubmit,
  loading,
  planos,
}) {
  console.log(planos);
  return (
    <Box component="form" noValidate onSubmit={handleSubmit} sx={{}}>
      <Grid
        container
        spacing={2}
        component={Paper}
        elevation={6}
        sx={{
          m: 1,
          pl: 1,
          pr: 3,
          pt: 1,
          pb: 3,
        }}
      >
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            autoFocus
            error={!!errors.email}
            name="email"
            label="E-mail"
            value={importador.email}
            id="email"
            autoComplete="email"
            helperText={errors.email}
            onChange={(event) => {
              setImport({ ...importador, email: event.target.value });
              clearErrors();
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            autoFocus
            error={!!errors.data}
            name="data"
            label="Data"
            value={importador.data}
            id="data"
            type="datetime-local"
            InputLabelProps={{
              shrink: true,
              required: true,
            }}
            InputProps={{
              inputMode: 'numeric',
            }}
            autoComplete="data"
            helperText={errors.data}
            onChange={(event) => {
              console.log(event.target.value);
              const data = moment(event.target.value);
              console.log(data.format('YYYY-MM-DD hh:mm'));
              setImport({ ...importador, data: data.format('YYYY-MM-DD HH:mm') });
              clearErrors();
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <NumericFormat
            value={importador.valor}
            name="valor"
            label="Valor"
            fullWidth
            autoFocus
            customInput={TextField}
            prefix="R$ "
            error={!!errors.valor}
            valueIsNumericString
            InputLabelProps={{
              shrink: true,
              required: true,
            }}
            decimalScale={2}
            thousandSeparator=""
            decimalSeparator=","
            helperText={errors.valor}
            type="text"
            onValueChange={(values, sourceInfo) => {
              console.log(values);
              console.log(sourceInfo);
              let valorFinal = 0;
              if (values.floatValue > 0) {
                valorFinal = Math.round(values.floatValue * 100);
              }
              setImport({ ...importador, valor: values.floatValue, valorFinal });
              clearErrors();
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel
              id="plano-label"
              error={!!errors.plano}
            >
              Plano
            </InputLabel>
            <Select
              name="plano"
              id="plano"
              labelId="plano-label"
              fullWidth
              value={importador.plano !== 0 ? importador.plano : 0}
              label="Plano"
              error={!!errors.plano}
              InputLabelProps={{
                shrink: true,
                required: true,
              }}
              onChange={(event) => {
                setImport({ ...importador, plano: event.target.value });
                clearErrors();
              }}
            >
              <MenuItem key={0} value={0}>Selecionar Plano</MenuItem>
              {
                planos.map((plano) => (
                  <MenuItem
                    key={plano.id}
                    value={plano.id}
                  >
                    {plano.label}
                  </MenuItem>
                ))
              }
            </Select>
            <FormHelperText error={!!errors.plano}>{errors.plano}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
          {loading ? <CircularProgress />
            : (
              <PrimaryButton
                type="submit"
                key="submitbutton"
                variant="primaryButton"
              >
                Importar
              </PrimaryButton>
            )}
        </Grid>
      </Grid>
    </Box>
  );
}

ImportFormSingle.propTypes = {
  errors: PropTypes.object.isRequired,
  clearErrors: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  importador: PropTypes.object.isRequired,
  setImport: PropTypes.func.isRequired,
  planos: PropTypes.array.isRequired,
};

export default ImportFormSingle;
