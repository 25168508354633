import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

export default function AlertDialog({ label, open, onClose }) {
  const handleCancel = () => {
    onClose();
  };

  const handleConfirm = () => {
    onClose(true);
  };

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      maxWidth="xs"
      aria-labelledby="delete-account-dialog-title"
      aria-describedby="delete-account-dialog-description"
    >
      <DialogTitle id="delete-account-dialog-title">
        {label}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="delete-account-dialog-description">
          Os dados serão permanentemente apagados e não poderão ser recuperados.
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ mb: 2, mr: 4 }}>
        <Button onClick={handleCancel} variant="contained">Cancelar</Button>
        <Button sx={{ color: 'error.main' }} onClick={handleConfirm} autoFocus>
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

AlertDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
};
