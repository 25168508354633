class Contact {
  constructor({
    id, typeId, information, establishmentId, ContactType,
  }) {
    this.id = id;
    this.typeId = typeId;
    this.information = information;
    this.establishmentId = establishmentId;
    this.ContactType = ContactType;
  }

  /// Check if this is a valid contact.
  isValid(checkId) {
    if (checkId && !this.id) return false;
    if (!this.typeId || !this.information || !this.establishmentId) return false;

    return true;
  }

  json() {
    return JSON.stringify(this);
  }
}

export default Contact;
