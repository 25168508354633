/* eslint-disable react/jsx-no-duplicate-props */
import PropTypes from 'prop-types';
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  Paper,
  TextField,
} from '@mui/material';

import {
  Person as PersonIcon,
} from '@mui/icons-material';

import { useEffect, useState } from 'react';
import { PasswordField, DeleteDialog } from '../../../components';
import { useAuth } from '../../../hooks';
import { ROLES } from '../../../constants';
import { establishmentService } from '../../../services';
import { Establishment } from '../../../models';

const {
  ADMIN, FRANCHISEE, OWNER, roleName,
} = ROLES;
function UserProfileForm({
  buttons,
  errors,
  clearErrors,
  profile,
  setProfile,
  isEditMode,
  isNewUser,
  handleSubmit,
  dialogOpen,
  onDialogClose,
  loading,
}) {
  const { user } = useAuth();
  const roleSelectItems = (
    user.roleId === ADMIN
      ? [FRANCHISEE, OWNER]
      : [OWNER]
  ).map((role) => <MenuItem value={role}>{roleName(role)}</MenuItem>);

  const [establishmentOptions, setEstablishmentOptions] = useState([]);

  useEffect(() => {
    establishmentService.getAll().then((establishments) => {
      const options = establishments?.map((e) => new Establishment(e)) || [];
      setEstablishmentOptions(options);
    });
    return () => { };
  }, []);

  const handleFileChange = (e) => {
    const file = Array.from(e.target.files)[0];
    if (file != null) {
      file.url = URL.createObjectURL(file);
      setProfile({ ...profile, file });
    }
  };

  return (
    <Box component="form" noValidate onSubmit={handleSubmit} sx={{}}>
      <Grid
        container
        spacing={2}
        component={Paper}
        elevation={6}
        sx={{
          m: 1,
          pl: 1,
          pr: 3,
          py: 1,
          alignItems: 'center',
        }}
      >
        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <Avatar
            alt={isNewUser ? 'Imagem de Perfil' : (`${profile.name} image`)}
            src={profile.file?.url || profile.avatar || 'none'}
            sx={{ width: 80, height: 80, mx: 'auto' }}
          >
            <PersonIcon />
          </Avatar>
          {isEditMode ? (
            <Button component="label" sx={{ maxWidth: 200 }}>
              Alterar imagem
              <input
                type="file"
                name="imageInput"
                accept="image/*"
                onChange={handleFileChange}
                hidden
              />
            </Button>
          ) : null}
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            fullWidth
            autoFocus
            error={!!errors.name}
            name="name"
            label="Name"
            id="name"
            autoComplete="name"
            helperText={errors.name}
            value={profile.name || ''}
            onChange={(event) => {
              setProfile({ ...profile, name: event.target.value });
              clearErrors();
            }}
            InputProps={{
              readOnly: !isEditMode,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            fullWidth
            error={!!errors.email}
            id="email"
            label="Email Address"
            name="email"
            helperText={errors.email}
            autoComplete="email"
            value={profile.email || ''}
            onChange={(event) => {
              setProfile({ ...profile, email: event.target.value });
              clearErrors();
            }}
            InputProps={{
              readOnly: !isEditMode,
            }}
          />
        </Grid>
        {
          isNewUser
            ? (
              <>
                <Grid item xs={6}>
                  <PasswordField
                    error={errors.password}
                    value={profile.password || ''}
                    onChange={(event) => {
                      setProfile({ ...profile, password: event.target.value });
                      clearErrors();
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="role-select"
                    name="roleId"
                    margin="normal"
                    label="Permissão"
                    sx={{ width: '100%' }}
                    value={profile.roleId || ''}
                    onChange={(e) => {
                      setProfile((oldProfile) => ({
                        ...oldProfile,
                        roleId: e.target.value,
                      }));
                    }}
                    select
                  >
                    {roleSelectItems}
                  </TextField>
                </Grid>
              </>
            )
            : null
        }
        {profile.roleId === 3 && user.roleId !== 3 ? (
          <Grid item xs={12}>
            <Autocomplete
              multiple
              disableClearable
              filterSelectedOptions
              popupIcon=""
              limitTags={2}
              options={establishmentOptions}
              getOptionLabel={(option) => `${option.id}-${option.name}`}
              isOptionEqualToValue={(option, currentValue) => option.id === currentValue.id}
              value={profile.Establishments || []}
              onChange={(event, newValue) => {
                setProfile((oldProfile) => ({
                  ...oldProfile,
                  Establishments: newValue,
                }));
              }}
              readOnly={!isEditMode}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Estabelecimentos"
                  margin="normal"
                  InputProps={{
                    ...params.InputProps,
                    readOnly: !isEditMode,
                  }}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                  }}
                />
              )}
            />
          </Grid>
        ) : null}
        <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
          {loading ? <CircularProgress /> : buttons}
        </Grid>
      </Grid>
      <DeleteDialog label="Apagar usuário?" open={dialogOpen} onClose={onDialogClose} />
    </Box>
  );
}

UserProfileForm.propTypes = {
  buttons: PropTypes.array.isRequired,
  errors: PropTypes.object.isRequired,
  clearErrors: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  setProfile: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  isNewUser: PropTypes.bool.isRequired,
  dialogOpen: PropTypes.bool.isRequired,
  onDialogClose: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default UserProfileForm;
