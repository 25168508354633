import PropTypes from 'prop-types';
import {
  Button,
} from '@mui/material';

function UserFilters({ filters, setFilters }) {
  const handleClick = (key) => {
    setFilters(key);
  };

  const ROLE_NAMES = [
    'Desconhecido',
    'Admin',
    'Franqueado',
    'Restaurante',
    'Aplicativo',
  ];

  function getRoleName(key) {
    key = parseInt(key, 10);
    return ROLE_NAMES[key] || ROLE_NAMES[0];
  }

  const buttons = Object.entries(filters).map(([key, selected]) => (
    <Button
      key={`role-${key}`}
      variant={selected ? 'contained' : 'outlined'}
      onClick={() => handleClick(key)}
      sx={{ borderRadius: 0 }}
    >
      {getRoleName(key)}
    </Button>
  ));

  return buttons;
}

UserFilters.propTypes = {
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
};

export default UserFilters;
