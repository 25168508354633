/* eslint-disable react/destructuring-assignment */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { ErrorPage } from '../pages/errors';

export default class AppErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error) {
    return { error, hasError: true };
  }

  componentDidCatch(error) {
    const { logout, setSnack } = this.props;

    if (error?.code === 4010) {
      logout();
      setSnack({
        key: 'ExpiredSession',
        message: 'Sessão expirada. Realize login novamente',
      });
    } else {
      setSnack({ key: error.code, message: error.message });
    }
  }

  render() {
    const { error, hasError } = this.state;
    const { children } = this.props;

    return hasError ? <ErrorPage error={error.message} /> : children;
  }
}

AppErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  logout: PropTypes.func.isRequired,
  setSnack: PropTypes.func.isRequired,
};
