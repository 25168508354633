import PropTypes from 'prop-types';
import {
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Paper,
  Typography,
} from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';

function CityTile({ city, onDelete, isLight }) {
  const { id, name } = city;
  return (
    <ListItem sx={isLight ? null : { backgroundColor: (theme) => theme.palette.grey[100] }}>
      <ListItemText>
        {id}
        {' '}
        -
        {' '}
        {name}
      </ListItemText>
      <IconButton
        onClick={() => {
          onDelete(id);
        }}
      >
        <DeleteIcon sx={{ color: 'error.main' }} />
      </IconButton>
    </ListItem>
  );
}

function CityList({ cities, onDelete }) {
  cities = cities.sort((a, b) => a.id - b.id);
  function getStates() {
    const list = [];
    cities.forEach((city) => {
      if (!list.some((state) => state.id === city.State.id)) list.push(city.State);
    });

    return list.sort((a, b) => a.id - b.id);
  }

  const states = getStates();
  return (
    <Paper>
      <Typography
        variant="h6"
        sx={{
          pl: 1.5,
          py: 2,
          bgcolor: 'primary.main',
          color: 'white',
          textAlign: 'center',
        }}
      >
        Cidades Ativas
      </Typography>
      <List
        sx={{ p: 0, '& ul': { padding: 0 } }}
        subheader={<li />}
      >
        {states.map((state) => (
          <li key={`section-${state.id}`}>
            <ul>
              <ListSubheader
                key={`list-subheader-${state.name}`}
                sx={{ bgcolor: 'primary.light', fontSize: 18, color: 'primary.dark' }}
              >
                {state.name}
              </ListSubheader>
              {cities
                .filter((city) => city.State.id === state.id)
                .map((city, index) => (
                  <CityTile
                    key={`city-tile-${city.id}`}
                    isLight={index % 2 === 0}
                    city={city}
                    onDelete={onDelete}
                  />
                ))}
            </ul>
          </li>
        ))}
      </List>
    </Paper>
  );
}

CityTile.propTypes = {
  city: PropTypes.object.isRequired,
  isLight: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
};

CityList.propTypes = {
  cities: PropTypes.array.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default CityList;
