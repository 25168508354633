import { ENDPOINTS } from '../constants';
import { Faq } from '../models';
import { parseResponse, defaultHeaders } from '../utils';

class FaqService {
  async getAll() {
    const response = await fetch(ENDPOINTS.FAQ, {
      method: 'GET',
      headers: defaultHeaders(),
    });
    return parseResponse(response, Faq);
  }

  async get(id) {
    if (!id) return null;

    const response = await fetch(ENDPOINTS.FAQ_ID(id), {
      method: 'GET',
      headers: defaultHeaders(),
    });

    return parseResponse(response, Faq);
  }

  async create(data) {
    const faq = new Faq(data);
    if (!faq.isValid()) throw new Error('Invalid faq');

    const response = await fetch(ENDPOINTS.FAQ, {
      method: 'POST',
      headers: defaultHeaders(),
      body: faq.json(),
    });

    return parseResponse(response);
  }

  async update(data) {
    const faq = new Faq(data);
    if (!faq.isValid(true)) throw new Error('Invalid faq');

    const response = await fetch(ENDPOINTS.FAQ_ID(faq.id), {
      method: 'PUT',
      headers: defaultHeaders(),
      body: faq.json(),
    });

    return parseResponse(response);
  }

  async delete(id) {
    if (!id) return null;

    const response = await fetch(ENDPOINTS.FAQ_ID(id), {
      method: 'DELETE',
      headers: defaultHeaders(),
    });

    return parseResponse(response);
  }

  async upvote(id) {
    if (!id) return null;

    const response = await fetch(ENDPOINTS.FAQ_UPVOTE(id), {
      method: 'PUT',
      headers: defaultHeaders(),
    });

    return parseResponse(response);
  }

  async downvote(id) {
    if (!id) return null;

    const response = await fetch(ENDPOINTS.FAQ_DOWNVOTE(id), {
      method: 'PUT',
      headers: defaultHeaders(),
    });

    return parseResponse(response);
  }
}

export default new FaqService();
