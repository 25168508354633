import { useState, useEffect } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from '@mui/material';

import { useSnack } from '../../hooks';
import { cityService, userService } from '../../services';
import { CityList } from './components';
import { SessionExpiredError } from '../../errors';

function SelectCitiesPage() {
  const { setSnack } = useSnack();
  const [state, setState] = useState({
    loading: true,
    users: [],
    states: [],
    cities: {},
    selectedState: null,
    user: null,
  });

  const [cityInputKey, setCityInputKey] = useState('');
  const [cityOptions, setCityOptions] = useState([]);
  const [changed, setChanged] = useState(false);

  useEffect(() => {
    // Get all Franchisee type users
    userService.getAll({ roleId: 2 }).then((users) => {
      users = users.rows.map((user) => ({
        label: user.name,
        id: user.id,
        Cities: user.Cities || [],
      }));
      cityService.getCities()
        .then((result) => {
          const cities = {};
          const states = [];
          result.forEach((city) => {
            // Using city from CityService because it's more complete than the City provided
            // in getAll from UserService
            users = users.map((user) => {
              const index = user.Cities.findIndex((x) => x.id === city.id);
              if (index > -1) user.Cities[index] = { ...city };

              return { ...user, Cities: user.Cities };
            });

            if (cities[city.State.name] == null) {
              states.push(city.State);
              cities[city.State.name] = [];
            }

            cities[city.State.name].push(city);
          });

          states.sort((a, b) => a.id - b.id);

          setState((oldState) => ({
            ...oldState,
            cities,
            states,
            users,
            loading: false,
          }));
        })
        .catch(() => {
          setState((oldState) => ({
            ...oldState,
            loading: false,
          }));
        });
    }).catch((error) => {
      if (error instanceof SessionExpiredError) setState(() => { throw error; });
      setState((oldState) => ({
        ...oldState,
        loading: false,
      }));
    });
  }, []);

  useEffect(() => {
    if (!state.selectedState || !state.user) return setCityOptions([]);
    const cities = state.cities[state.selectedState];
    const filteredCities = (cities ?? []).filter(
      (city) => !state.user.Cities?.some((e) => e.id === city.id),
    );
    setCityOptions(filteredCities);
    return () => { };
  }, [state.cities, state.selectedState, state.user]);

  const save = async () => {
    try {
      const { id, Cities } = state.user;
      const cities = Cities.map((city) => city.id);
      await userService.setVendorToCities({ id, cities });

      setChanged(false);

      return setSnack({
        type: 'success',
        message: 'Cadastro salvo com sucesso',
      });
    } catch (e) {
      return setSnack({ message: e.message });
    }
  };

  const addSelectedCity = (e, newValue) => {
    setChanged(true);
    setState((oldState) => ({
      ...oldState,
      user: {
        ...oldState.user,
        Cities: [...(oldState.user.Cities || []), newValue],
      },
    }));
    setCityInputKey(newValue.name);
  };

  const handleDelete = (id) => {
    const newCities = state.user.Cities.filter((city) => city.id !== parseInt(id, 10));
    setChanged(true);
    setState((oldState) => ({
      ...oldState,
      user: {
        ...oldState.user,
        Cities: [...newCities],
      },
    }));
    setCityInputKey(id);
  };

  return state.loading
    ? (
      <CircularProgress />
    )
    : (
      <Box sx={{ mt: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Typography variant="h5"><b>Cidades</b></Typography>
          {changed ? (
            <Button variant="contained" onClick={save}>
              Salvar
            </Button>
          ) : null}
        </Box>
        <Autocomplete
          disablePortal
          fullWidth
          id="select-user-box"
          options={state.users}
          onChange={(e, newValue) => {
            setState({
              ...state,
              user: newValue,
            });
          }}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Usuário"
              placeholder="Selecione o usuário"
            />
          )}
          sx={{ mb: 2, bgcolor: 'background.paper' }}
        />
        <Box sx={{ display: 'flex', mb: 2 }}>
          <Autocomplete
            disablePortal
            fullWidth
            disabled={!state.user}
            id="select-state-box"
            options={state.states}
            getOptionLabel={(option) => option.name}
            onChange={(e, newValue) => {
              setState({
                ...state,
                selectedState: newValue.name,
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                disabled={!state.user}
                label="Estado"
                placeholder="Selecione o estado"
              />
            )}
            sx={{ mr: 2, bgcolor: 'background.paper' }}
          />
          <Autocomplete
            disablePortal
            fullWidth
            key={cityInputKey}
            disabled={!state.selectedState}
            id="select-city-box"
            options={cityOptions}
            getOptionLabel={(option) => option.name}
            onChange={addSelectedCity}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                disabled={!state.user}
                label="Cidade"
                placeholder="Selecione a cidade a adicionar"
              />
            )}
            sx={{ bgcolor: 'background.paper' }}
          />
        </Box>
        <CityList cities={state.user?.Cities || []} onDelete={handleDelete} />
      </Box>
    );
}

export default SelectCitiesPage;
