import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';
import {
  Box,
  Container,
} from '@mui/material';

import { AppErrorBoundary, Header, Sidebar } from '../components';
import { useSnack } from '../hooks';

function BasePage({ logout }) {
  const { setSnack } = useSnack();

  return (
    <Box sx={{ display: 'flex' }}>
      <Header logout={logout} />
      <Sidebar logout={logout} />
      <Box
        component="main"
        sx={{
          backgroundColor: ((theme) => theme.background),
          flexGrow: 1,
          display: 'flex',
          justifyContent: 'center',
          height: 'calc(100vh - 80px)',
          overflow: 'auto',
          mt: '80px',
        }}
      >
        <Container sx={{ my: 2 }}>
          <AppErrorBoundary logout={logout} setSnack={setSnack}>
            <Outlet />
          </AppErrorBoundary>
        </Container>
      </Box>
    </Box>
  );
}

BasePage.propTypes = {
  logout: PropTypes.func.isRequired,
};

export default BasePage;
