/* eslint-disable react/jsx-no-duplicate-props */
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Autocomplete,
  CircularProgress,
  TextField,
} from '@mui/material';

import { typeService } from '../../../services';

function TypesPicker({
  inputKey, value: initialValue, error, readOnly, onChangeHandle,
}) {
  const [options, setOptions] = useState({
    loaded: false,
    types: [],
    options: [],
  });

  const [value, setValue] = useState(initialValue || null);

  useEffect(() => {
    typeService.getAll().then((result) => {
      result.sort((a, b) => a.name.localeCompare(b.name));
      setOptions({
        loaded: true,
        types: result,
      });
    }).catch(() => {
      setOptions((oldOptions) => ({
        ...oldOptions,
        loaded: true,
      }));
    });
  }, []);

  return (
    options.loaded
      ? (
        <Autocomplete
          multiple
          disableClearable
          filterSelectedOptions
          popupIcon=""
          limitTags={2}
          options={options.types}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, currentValue) => option.id === currentValue.id}
          value={value || null}
          onChange={(event, newValue) => {
            setValue(newValue);
            onChangeHandle(newValue);
          }}
          readOnly={readOnly}
          renderInput={(params) => (
            <TextField
              {...params}
              id={inputKey}
              name={inputKey}
              hiddenLabel
              error={!!error}
              variant="standard"
              helperText={error}
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
              }}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password', // disable autocomplete and autofill
              }}
            />
          )}
        />
      ) : <CircularProgress />
  );
}

TypesPicker.propTypes = {
  inputKey: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
  value: PropTypes.array.isRequired,
  onChangeHandle: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
};

export default TypesPicker;
