import PropTypes from 'prop-types';
import {
  Button,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';

import {
  AddCircle as AddIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';

import { ContactTypePicker } from './index';

function EstablishmentContactRow({
  inputKey,
  title,
  contact,
  onDeleteHandle,
  onChangeHandle,
  onTypeSelect,
  onInformationChange,
}) {
  function isNewContact() { return inputKey === 'contactNew'; }

  const button = () => (isNewContact() ? (
    <Button
      key="addContactButton"
      size="small"
      onClick={onChangeHandle}
    >
      <AddIcon />
    </Button>
  ) : (
    <Button
      key={`deleteContactButton${inputKey}`}
      size="small"
      onClick={onDeleteHandle}
    >
      <DeleteIcon />
    </Button>
  ));

  const cellSx = {
    border: 4,
    backgroundColor: 'background.paper',
    backgroundClip: 'padding-box',
    borderColor: 'transparent',
  };

  return (
    <TableRow>
      <TableCell sx={cellSx}><Typography variant="bodyTitle">{title}</Typography></TableCell>
      {isNewContact()
        ? (
          <TableCell sx={{ ...cellSx, tableLayout: 'fixed', width: 200 }}>
            <ContactTypePicker
              key={inputKey}
              value={contact.typeId}
              onChangeHandle={onTypeSelect}
              readOnly={!isNewContact()}
            />
          </TableCell>
        )
        : null}
      <TableCell colSpan={isNewContact() ? 1 : 2} sx={{ ...cellSx }}>
        <TextField
          required
          fullWidth
          hiddenLabel
          variant="standard"
          name={inputKey}
          id={inputKey}
          value={contact.information || ''}
          onChange={(event) => onInformationChange(event.target.value)}
          InputProps={{ disableUnderline: true, readOnly: !isNewContact() }}
        />
      </TableCell>
      <TableCell sx={{ ...cellSx }} align="center">{button()}</TableCell>
    </TableRow>
  );
}

EstablishmentContactRow.propTypes = {
  inputKey: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  contact: PropTypes.object.isRequired,
  onDeleteHandle: PropTypes.func.isRequired,
  onChangeHandle: PropTypes.func.isRequired,
  onTypeSelect: PropTypes.func.isRequired,
  onInformationChange: PropTypes.func.isRequired,
};

export default EstablishmentContactRow;
