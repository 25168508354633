import PropTypes from 'prop-types';
import {
  Box,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';

function FaqTile({ faq }) {
  return (
    <ListItemButton
      key={faq.id.toString()}
      href={`/faqs/${faq.id}`}
      sx={{ maxHeight: 100 }}
    >
      <ListItemText
        primary={(
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography sx={{ flex: 1 }} variant="bodyTitle">
              {`${faq.id} - ${faq.question}`}
            </Typography>
            <Typography sx={{ flex: '0 0 auto' }} variant="bodyTitle">
              {'Avaliação: '}
              {faq.upvotes - faq.downvotes}
            </Typography>
          </Box>
        )}
        secondary={(
          <Typography
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '2',
              WebkitBoxOrient: 'vertical',
            }}
          >
            {faq.answer}
          </Typography>
        )}
      />
    </ListItemButton>
  );
}

FaqTile.propTypes = {
  faq: PropTypes.object.isRequired,
};

export default FaqTile;
