import { Route } from 'react-router-dom';
import {
  AuthPage,
  CodeValidationPage,
  ForgotPasswordPage,
  NewPasswordPage,
  TermsPage,
} from '../pages';

function authRoutes(setToken) {
  const routes = [];
  /* Index */
  routes.push(<Route key="authIndexRoute" index element={<AuthPage setToken={setToken} />} />);

  /* Esqueci Senha */
  routes.push(
    <Route key="forgotPasswordRoutes" path="forgot">
      <Route key="forgotIndexRoute" index element={<ForgotPasswordPage />} />
      <Route key="forgotEmailRoute" path="" element={<ForgotPasswordPage />} />
      <Route key="forgotCodeRoute" path="code" element={<CodeValidationPage />} />
      <Route key="forgotNewPasswordRoute" path="new" element={<NewPasswordPage />} />
    </Route>,
  );

  /* Terms */
  routes.push(<Route key="termsRoute" path="terms" element={<TermsPage />} />);

  return routes;
}

export default authRoutes;
