import { ENDPOINTS } from '../constants';
import { Log } from '../models';
import { parseResponse, defaultHeaders } from '../utils';

class LogService {
  async getAll(query) {
    const url = ENDPOINTS.parse(ENDPOINTS.LOG, query);
    const response = await fetch(url, {
      method: 'GET',
      headers: defaultHeaders(),
    });

    return parseResponse(response, Log);
  }
}

export default new LogService();
