/* eslint-disable react/jsx-no-duplicate-props */
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Autocomplete,
  CircularProgress,
  TextField,
} from '@mui/material';

import { cityService } from '../../../services';

function CityPicker({
  inputKey, value: initialValue, error, readOnly, onChangeHandle, userCities, user,
}) {
  const [options, setOptions] = useState({
    loaded: false,
    cities: [],
    options: [],
  });

  const [value, setValue] = useState(initialValue?.name ? initialValue : null);
  const [inputValue, setInputValue] = useState(initialValue?.name || '');

  useEffect(() => {
    if (userCities && userCities.length > 0) {
      // Se há cidades especificadas pelo usuário, busca somente essas cidades
      cityService.getCitiesByIds(userCities).then((cityList) => {
        setOptions({
          loaded: true,
          cities: cityList,
        });
      }).catch(() => {
        setOptions((oldOptions) => ({
          ...oldOptions,
          loaded: true,
        }));
      });
    } else if (userCities && userCities.length === 0 && user.roleId !== 2) {
      // Para usuários que não são do tipo roleId=2, busca todas as cidades
      cityService.getCities().then((cityList) => {
        setOptions({
          loaded: true,
          cities: cityList,
        });
      }).catch(() => {
        setOptions((oldOptions) => ({
          ...oldOptions,
          loaded: true,
        }));
      });
    } else {
      // Para usuários com roleId=2 sem cidades especificadas, não retorna cidades
      setOptions({
        loaded: true,
        cities: [],
      });
    }
  }, [userCities, user.roleId]);

  return (
    options.loaded
      ? (
        <Autocomplete
          disableClearable
          popupIcon=""
          options={options.cities}
          groupBy={(option) => (option.State ? option.State.name : '')}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, currentValue) => option.name === currentValue.name}
          value={value || null}
          onChange={(event, newValue) => {
            setValue(newValue);
            onChangeHandle(newValue);
          }}
          inputValue={inputValue}
          onInputChange={(event, newValue) => {
            setInputValue(newValue);
          }}
          readOnly={readOnly}
          renderInput={(params) => (
            <TextField
              {...params}
              id={inputKey}
              name={inputKey}
              hiddenLabel
              error={!!error}
              variant="standard"
              helperText={error}
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
              }}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password', // disable autocomplete and autofill
              }}
            />
          )}
        />
      ) : <CircularProgress />
  );
}

CityPicker.propTypes = {
  inputKey: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
  value: PropTypes.object.isRequired,
  onChangeHandle: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
  userCities: PropTypes.array,
  user: PropTypes.array,
};

CityPicker.defaultProps = {
  userCities: [],
  user: [],
};

export default CityPicker;
