import PropTypes from 'prop-types';
import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  TextField,
} from '@mui/material';

import { DeleteDialog } from '../../../components';

function FaqProfileForm({
  buttons,
  errors,
  clearErrors,
  profile,
  setProfile,
  isEditMode,
  handleSubmit,
  dialogOpen,
  onDialogClose,
  loading,
}) {
  return (
    <Box component="form" noValidate onSubmit={handleSubmit} sx={{}}>
      <Grid
        container
        spacing={2}
        component={Paper}
        elevation={6}
        sx={{
          m: 1,
          pl: 1,
          pr: 3,
          pt: 1,
          pb: 3,
        }}
      >
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            multiline
            minRows={2}
            maxRows={3}
            error={!!errors.question}
            name="question"
            label="Pergunta"
            id="question"
            autoComplete="question"
            helperText={errors.question}
            value={profile.question || ''}
            onChange={(event) => {
              setProfile({ ...profile, question: event.target.value });
              clearErrors();
            }}
            InputProps={{
              readOnly: !isEditMode,
            }}
          />
          <TextField
            required
            fullWidth
            multiline
            minRows={2}
            maxRows={8}
            error={!!errors.answer}
            margin="normal"
            name="answer"
            label="Resposta"
            id="answer"
            autoComplete="answer"
            helperText={errors.answer}
            value={profile.answer || ''}
            onChange={(event) => {
              setProfile({ ...profile, answer: event.target.value });
              clearErrors();
            }}
            InputProps={{
              readOnly: !isEditMode,
            }}
          />
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
          {loading ? <CircularProgress /> : buttons}
        </Grid>
      </Grid>
      <DeleteDialog label="Apagar categoria?" open={dialogOpen} onClose={onDialogClose} />
    </Box>
  );
}

FaqProfileForm.propTypes = {
  buttons: PropTypes.array.isRequired,
  errors: PropTypes.object.isRequired,
  clearErrors: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  setProfile: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  dialogOpen: PropTypes.bool.isRequired,
  onDialogClose: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default FaqProfileForm;
