import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';

import { NewsForm } from './components';

import { newsService, uploadService } from '../../services';
import { validator } from '../../utils';
import { useSnack, useAuth } from '../../hooks';
import { News } from '../../models';
// import { UnauthorizedError } from '../../errors';
import { ROLES } from '../../constants';

const { ADMIN } = ROLES;

function NewsInfoPage() {
  const { id: pathId } = useParams();
  const { setSnack } = useSnack();
  const { user: currentUser } = useAuth();
  const navigate = useNavigate();

  const [state, setState] = useState({
    id: pathId,
    loading: true,
    saving: false,
    edit: true,
    dialogOpen: false,
    newsItem: new News(),
  });

  const loadNewsItem = useCallback((id) => {
    if (parseInt(id, 10)) {
      newsService.get(id).then((data) => {
        setState((oldState) => ({
          ...oldState,
          loading: false,
          newsItem: data,
        }));
      })
        .catch(() => {
          setState((oldState) => ({
            ...oldState,
            loading: false,
          }));
        });
    } else {
      setState((oldState) => ({
        ...oldState,
        loading: false,
        edit: true,
      }));
    }
  }, []);

  useEffect(() => {
    if (currentUser.roleId !== ADMIN) {
      navigate('/unauthorized'); // Redireciona caso não seja ADMIN
    }
  }, [currentUser, navigate]);

  useEffect(() => {
    loadNewsItem(state.id);
  }, [loadNewsItem, state.id]);

  const [errors, setErrors] = useState({
    title: '',
    description: '',
  });

  function isNewNewsItem() {
    return state.newsItem.id === '';
  }

  const cancel = () => {
    navigate('/news/');
  };

  async function uploadImages(images) {
    try {
      const promises = [];
      images.forEach((image) => {
        promises.push(uploadService.image(image));
      });

      const imageArray = (await Promise.all(promises)).filter((e) => e != null);
      return imageArray;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setState((oldState) => ({
      ...oldState,
      edit: false,
      saving: true,
    }));

    if (state.newsItem.filesToUpload != null) {
      const imageArray = (await uploadImages(state.newsItem.filesToUpload)).map((i) => ({
        url: i,
        isAvatar: false,
      }));
      if (imageArray.length > 0) {
        const [{ url }] = imageArray;
        state.newsItem.image = url;
      } else if (!state.newsItem.Images || state.newsItem.Images.length === 0) {
        state.newsItem.image = null;
      } else {
        const [firstImageUrl] = state.newsItem.Images;
        state.newsItem.image = firstImageUrl;
      }
    }

    const titleError = validator.required(state.newsItem.title);
    const descriptionError = validator.required(state.newsItem.description);

    if (!titleError && !descriptionError) {
      try {
        let newNewsItem;
        if (isNewNewsItem()) {
          newNewsItem = await newsService.create(state.newsItem);
        } else {
          newNewsItem = await newsService.update(state.newsItem);
        }

        setState((oldState) => ({
          ...oldState,
          saving: false,
          id: newNewsItem.id || 'new',
        }));

        loadNewsItem(newNewsItem.id);
        setSnack({
          type: 'success',
          message: 'Novidade salva com sucesso',
        });
      } catch (e) {
        setState((oldState) => ({
          ...oldState,
          saving: false,
        }));
        setSnack({ message: e.message });
      }
    } else {
      setErrors({
        title: titleError,
        description: descriptionError,
      });
    }
  };

  const clearErrors = () => {
    const { title, description } = errors;
    if (title || description) {
      setErrors({
        title: '',
        description: '',
      });
    }
  };

  const handleDelete = () => {
    newsService.delete(state.newsItem.id).then(() => {
      cancel();
      setSnack({
        type: 'success',
        message: 'Novidade apagada com sucesso',
      });
    }).catch((error) => {
      setSnack({ message: error.message });
    });
  };

  return state.loading ? (
    <CircularProgress />
  ) : (
    <NewsForm
      handleSubmit={handleSubmit}
      errors={errors}
      clearErrors={clearErrors}
      newsItem={state.newsItem}
      updateNewsItem={(key, value) => {
        const { newsItem } = state;
        newsItem[key] = value;
        setState((oldState) => ({ ...oldState, newsItem }));
      }}
      isNewNews={isNewNewsItem()}
      isEditMode={state.edit}
      onDialogConfirm={handleDelete}
      loading={state.saving}
    />
  );
}

export default NewsInfoPage;
