import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  Paper,
  TextField,
  Divider,
  Chip,
  FormControl,
  InputLabel,
} from '@mui/material';

import {
  CameraAlt as CameraIcon,
} from '@mui/icons-material';

import { DeleteDialog } from '../../../components';
import CategoriesPicker from '../../establishment/components/CategoriesPicker';

function PlanProfileForm({
  buttons,
  errors,
  clearErrors,
  profile,
  setProfile,
  isEditMode,
  isNewPlan,
  handleSubmit,
  dialogOpen,
  onDialogClose,
  loading,
  preSale,
}) {
  const handleFileChange = (e) => {
    const file = Array.from(e.target.files)[0];
    if (file != null) {
      file.url = URL.createObjectURL(file);
      setProfile({ ...profile, file });
    }
  };

  return (
    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ maxWidth: '70vw' }}>
      <Grid
        container
        spacing={2}
        component={Paper}
        elevation={6}
        sx={{
          m: 1, pl: 1, pr: 3, pt: 1, pb: 3,
        }}
      >
        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <Avatar
            alt={isNewPlan ? 'New plan image' : (`${profile.name}image`)}
            src={profile.file?.url || profile.image || 'none'}
            sx={{ width: 80, height: 80, mx: 'auto' }}
          >
            <CameraIcon />
          </Avatar>
          {isEditMode ? (
            <Button component="label" sx={{ maxWidth: 200 }}>
              Alterar imagem
              <input
                type="file"
                name="imageInput"
                accept="image/*"
                onChange={handleFileChange}
                hidden
              />
            </Button>
          ) : null}
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            error={!!errors.name}
            name="name"
            label="Name"
            id="name"
            autoComplete="name"
            helperText={errors.name}
            value={profile.name || ''}
            onChange={(event) => {
              setProfile({ ...profile, name: event.target.value });
              clearErrors();
            }}
            InputProps={{
              readOnly: !isEditMode,
            }}
          />
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <TextField
            required
            id="diamonds"
            name="diamonds"
            label="Diamantes"
            sx={{ width: '31%' }}
            type="number"
            value={profile.diamonds || null}
            onChange={(event) => {
              setProfile({ ...profile, diamonds: event.target.value });
              clearErrors();
            }}
            InputProps={{
              readOnly: !isEditMode,
            }}
          />
          <TextField
            required
            fullWidth
            id="vouchers"
            name="vouchers"
            label="Vouchers"
            sx={{ width: '31%' }}
            value={profile.vouchers || ''}
            onChange={(event) => {
              setProfile({ ...profile, vouchers: event.target.value });
              clearErrors();
            }}
            InputProps={{
              readOnly: !isEditMode,
            }}
          />
          <TextField
            required
            type="number"
            name="value"
            label="Valor em centavos"
            sx={{ width: '31%' }}
            value={profile.value || null}
            onChange={(event) => {
              setProfile({ ...profile, value: event.target.value });
              clearErrors();
            }}
            InputProps={{
              readOnly: !isEditMode,
              startAdornment: <InputAdornment position="start">R$</InputAdornment>,
            }}
          />
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <TextField
            required
            name="voucherPeriod"
            label="Duração dos Vouchers"
            sx={{ width: '31%' }}
            value={profile.voucherPeriod || null}
            onChange={(event) => {
              setProfile({ ...profile, voucherPeriod: event.target.value });
              clearErrors();
            }}
            InputProps={{
              readOnly: !isEditMode,
            }}
          />
          <TextField
            required
            id="expiry"
            name="expiry"
            label="Valido até"
            type="date"
            sx={{ width: '31%' }}
            InputLabelProps={{ shrink: true }}
            value={profile.expiry?.split('T')[0] || ''}
            onChange={(event) => {
              setProfile({ ...profile, expiry: event.target.value });
              clearErrors();
            }}
            InputProps={{
              readOnly: !isEditMode,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth variant="outlined" error={!!errors.categories} component="div">
            <InputLabel htmlFor="categories-picker" shrink>Categorias</InputLabel>
            <div
              style={{
                border: '1px solid rgba(0, 0, 0, 0.23)',
                borderRadius: '4px',
                padding: '0 14px 8px',
                marginTop: '16px',
              }}
            >
              <CategoriesPicker
                inputKey="categories"
                value={profile.categories || []}
                error={errors.categories}
                onChangeHandle={(newValue) => {
                  setProfile({ ...profile, categories: newValue });
                  clearErrors();
                }}
                readOnly={!isEditMode}
                categories={profile.categories}
              />
            </div>
          </FormControl>
        </Grid>

        {preSale && (
          <>
            <Divider flexItem sx={{ width: '100%', marginBottom: 2 }}><Chip color="primary" label="PRÉ-VENDA" /></Divider>
            <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <TextField
                required
                name="discountPeriod"
                label="Período de Pré-Venda"
                type="date"
                sx={{ width: '31%' }}
                InputLabelProps={{ shrink: true }}
                value={profile.discountPeriod?.split('T')[0] || ''}
                onChange={(event) => {
                  setProfile({ ...profile, discountPeriod: event.target.value });
                  clearErrors();
                }}
                helperText="Até que dia o plano estará em pré-venda."
                InputProps={{
                  readOnly: !isEditMode,
                }}
              />
              <TextField
                required
                name="voucherValidFrom"
                label="Período de liberação de Vouchers & Diamantes"
                type="date"
                sx={{ width: '31%' }}
                InputLabelProps={{ shrink: true }}
                value={profile.voucherValidFrom?.split('T')[0] || ''}
                onChange={(event) => {
                  setProfile({ ...profile, voucherValidFrom: event.target.value });
                  clearErrors();
                }}
                helperText="A partir de que dia os Vouchers & Diamantes ficarão disponíveis."
                InputProps={{
                  readOnly: !isEditMode,
                }}
              />
              <TextField
                required
                name="discountValue"
                label="Valor de Pré-Venda em centavos"
                type="number"
                sx={{ width: '31%' }}
                value={profile.discountValue}
                onChange={(event) => {
                  setProfile({ ...profile, discountValue: event.target.value });
                  clearErrors();
                }}
                InputProps={{
                  readOnly: !isEditMode,
                  startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                }}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
          {loading ? <CircularProgress /> : buttons}
        </Grid>
      </Grid>
      <DeleteDialog label="Apagar plano?" open={dialogOpen} onClose={onDialogClose} />
    </Box>
  );
}

PlanProfileForm.propTypes = {
  buttons: PropTypes.array.isRequired,
  errors: PropTypes.object.isRequired,
  clearErrors: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  setProfile: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  isNewPlan: PropTypes.bool.isRequired,
  dialogOpen: PropTypes.bool.isRequired,
  onDialogClose: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  preSale: PropTypes.bool.isRequired,
};

export default PlanProfileForm;
