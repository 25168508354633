import {
  Typography,
} from '@mui/material';

function UnknownPage() {
  return (
    <Typography>
      Ops! Esta página não existe ou você não está autorizado a ver!
    </Typography>
  );
}

export default UnknownPage;
