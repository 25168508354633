import { ENDPOINTS } from '../constants';
import { News } from '../models';
import { parseResponse, defaultHeaders } from '../utils';

class NewsService {
  async getAll() {
    const response = await fetch(ENDPOINTS.NEWS, {
      method: 'GET',
      headers: defaultHeaders(),
    });
    return parseResponse(response, News);
  }

  async get(id) {
    if (!id) return null;

    const response = await fetch(ENDPOINTS.NEWS_ID(id), {
      method: 'GET',
      headers: defaultHeaders(),
    });

    return parseResponse(response, News);
  }

  async create(data) {
    const news = data instanceof News ? data : new News(data);
    if (!news.isValid()) throw new Error('Invalid News');
    console.log(news.json());
    const response = await fetch(ENDPOINTS.NEWS, {
      method: 'POST',
      headers: defaultHeaders(),
      body: news.json(),
    });

    return parseResponse(response, News);
  }

  async update(data) {
    console.log(data);
    const news = data instanceof News ? data : new News(data);
    if (!news.isValid()) throw new Error('Invalid News');

    const response = await fetch(ENDPOINTS.NEWS_ID(news.id), {
      method: 'PUT',
      headers: defaultHeaders(),
      body: news.json(),
    });

    return parseResponse(response, News);
  }

  async delete(id) {
    if (!id) return null;

    const response = await fetch(ENDPOINTS.NEWS_ID(id), {
      method: 'DELETE',
      headers: defaultHeaders(),
    });

    return parseResponse(response);
  }
}

export default new NewsService();
