import { useState, useEffect, useCallback } from 'react';
// import { useNavigate } from 'react-router-dom';

import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Typography,
  TableFooter,
  Tooltip,
  Chip,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Grid,
} from '@mui/material';

import AddCardIcon from '@mui/icons-material/AddCard';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RunningWithErrorsIcon from '@mui/icons-material/RunningWithErrors';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import AddReactionIcon from '@mui/icons-material/AddReaction';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

import { stripeService } from '../../services';

function PaymentPage() {
  const [state, setState] = useState({
    loading: true,
    payments: [],
    users: [],
    plans: [],
    statuses: [],
  });

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [userFilter, setUserFilter] = useState(0);
  const [planFilter, setPlanFilter] = useState(0);
  const [statusFilter, setStatusFilter] = useState(0);

  const icones = [
    { label: 'requires_payment_method', icon: <Chip style={{ backgroundColor: '#5469d4', color: 'white' }} label="requires_payment_method" icon={<AddCardIcon style={{ color: 'white' }} />} /> },
    { label: 'requires_confirmation', icon: <Chip style={{ backgroundColor: 'gray', color: 'white' }} label="requires_confirmation" icon={<CheckBoxOutlineBlankIcon style={{ color: 'white' }} />} /> },
    { label: 'requires_action', icon: <Chip style={{ backgroundColor: 'gray', color: 'white' }} label="requires_action" icon={<AddReactionIcon style={{ color: 'white' }} />} /> },
    { label: 'processing', icon: <Chip style={{ backgroundColor: 'gray', color: 'white' }} label="processing" icon={<RunningWithErrorsIcon style={{ color: 'white' }} />} /> },
    { label: 'succeeded', icon: <Chip style={{ backgroundColor: '#33C27F', color: 'white' }} label="succeeded" icon={<CheckCircleIcon style={{ color: 'white' }} />} /> },
    { label: 'canceled', icon: <Chip style={{ backgroundColor: 'red', color: 'white' }} label="canceled" icon={<DoDisturbIcon style={{ color: 'white' }} />} /> },
  ];

  const getIcone = (icone) => icones.filter((i) => i.label === icone)[0].icon || null;

  const loadData = useCallback(() => {
    stripeService
      .getAll()
      .then((payments) => {
        payments.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
        const users = new Map(
          payments.map((payment) => (
            payment.userData !== null ? [payment.userData.id, payment.userData.name] : [payment.userId, 'Usuário Excluído']
          )),
        );
        const plans = new Map(
          payments.map((payment) => [payment.planData.id, payment.planData.name]),
        );
        const statuses = new Map(
          payments.map((payment) => [payment.status, payment.status]),
        );
        console.log(users);
        setState({
          payments,
          plans,
          statuses,
          users,
          loading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        setState((oldState) => ({
          ...oldState,
          loading: false,
        }));
      });
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  function getFiltererEstablishments() {
    const payments = state.payments.filter((payment) => {
      if (userFilter !== 0 && payment.userId !== userFilter) return false;
      if (planFilter !== 0 && payment.planId !== planFilter) return false;
      if (statusFilter !== 0 && payment.status !== statusFilter) return false;
      return true;
    });
    return payments;
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };
  const handleChangeUserFilter = (event) => {
    setUserFilter(event.target.value);
  };
  const handleChangePlanFilter = (event) => {
    setPlanFilter(event.target.value);
  };
  const handleChangeStatusFilter = (event) => {
    setStatusFilter(event.target.value);
  };
  console.log(state);
  return (
    state.loading
      ? <CircularProgress />
      : (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h5">
              <b>Pagamentos</b>
            </Typography>
          </Box>
          <Box
            sx={{
              mt: 2,
              bgcolor: 'transparent',
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel id="filter-user">Filtrar por Usuário</InputLabel>
                  <Select
                    labelId="filter-user"
                    value={userFilter}
                    label="Filtrar por Usuário"
                    onChange={handleChangeUserFilter}
                  >
                    <MenuItem value={0}>Filtrar por Usuário</MenuItem>
                    {[...state.users].map(([id, name]) => <MenuItem value={id}>{name}</MenuItem>)}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel id="filter-plan">Filtrar por Plano</InputLabel>
                  <Select
                    labelId="filter-plan"
                    value={planFilter}
                    label="Filtrar por Plano"
                    onChange={handleChangePlanFilter}
                  >
                    <MenuItem value={0}>Filtrar por Plano</MenuItem>
                    {[...state.plans].map(([id, name]) => <MenuItem value={id}>{name}</MenuItem>)}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel id="filter-status">Filtrar por Status</InputLabel>
                  <Select
                    labelId="filter-status"
                    value={statusFilter}
                    label="Filtrar por Status"
                    onChange={handleChangeStatusFilter}
                  >
                    <MenuItem value={0}>Filtrar por Status</MenuItem>
                    {[...state.statuses].map(
                      ([id, name]) => (<MenuItem value={id}>{name}</MenuItem>),
                    )}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              mt: 2,
              bgcolor: 'transparent',
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <Tooltip title="Id do PaymentIntent do vindo da API do Stripe.">
                    <TableCell>Id no Stripe</TableCell>
                  </Tooltip>
                  <Tooltip title="Usuário que iniciou o processo de compra do plano.">
                    <TableCell>Usuário</TableCell>
                  </Tooltip>
                  <Tooltip title="Plano que foi selecionado para a compra.">
                    <TableCell>Plano</TableCell>
                  </Tooltip>
                  <Tooltip title="Valor do plano que foi selecionado para a compra.">
                    <TableCell>Valor</TableCell>
                  </Tooltip>
                  <Tooltip title="Status no Stripe da Compra">
                    <TableCell>Status</TableCell>
                  </Tooltip>
                  <Tooltip title="Última atualização vinda da integração">
                    <TableCell>Última Interação</TableCell>
                  </Tooltip>
                </TableRow>
              </TableHead>
              <TableBody>
                {getFiltererEstablishments()
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((payment) => (
                    <TableRow key={payment.id.toString()}>
                      <TableCell>{payment.paymentId}</TableCell>
                      <TableCell>
                        <Tooltip title={`Id: ${payment.userId}`}>
                          <Typography variant="p">
                            {payment.user}
                          </Typography>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        sx={{
                          minWidth: 150,
                        }}
                      >
                        <Tooltip title={`Id: ${payment.planId}`}>
                          <Typography variant="p">
                            {payment.plan}
                          </Typography>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        sx={{
                          minWidth: 150,
                        }}
                      >
                        R$ {payment.planAmount}
                      </TableCell>
                      <TableCell>
                        {getIcone(payment.status)}
                      </TableCell>
                      <TableCell>
                        {` ${new Date(payment.updatedAt).toLocaleTimeString()} ${new Date(payment.updatedAt).toLocaleDateString()}`}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    count={getFiltererEstablishments().length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    labelDisplayedRows={({ from, to, count }) => `${from}–${to} de ${count !== -1 ? count : `mais de ${to}`}`}
                    labelRowsPerPage="Resultados por página"
                    onPageChange={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    rowsPerPageOptions={[10, 20, 50]}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </Box>
        </Box>
      )
  );
}

export default PaymentPage;
