import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import {
  Avatar,
  IconButton,
  TableCell,
  ListItemAvatar,
  ListItemText,
  TableRow,
} from '@mui/material';
import { Star as StarIcon } from '@mui/icons-material';
import { useState } from 'react';
import { Establishment } from '../../../models';
import { formatter } from '../../../utils';

import { PrimaryButton } from '../../../components/styles/buttons';

import { establishmentService } from '../../../services';

function EstablishmentRow({ establishment }) {
  const [highlight, setHighlight] = useState(establishment.highlight);
  const navigate = useNavigate();

  const onHighlightChange = () => {
    establishment.highlight = !highlight;
    establishmentService.updateHighlight(establishment.id, !highlight);
    setHighlight(!highlight);
  };

  return (
    <TableRow key={establishment.id.toString()}>
      <TableCell>
        <ListItemAvatar>
          <Avatar src={establishment.Images?.find((i) => i.isAvatar)?.url || 'none'} />
        </ListItemAvatar>
      </TableCell>
      <TableCell>
        <ListItemText
          primary={<b>{`${establishment.id} - ${establishment.name}`}</b>}
          secondary={(
            <>
              {formatter.formatWeekToString(establishment.availableDays)}
              <br />
              {formatter.formatPeriodToString(establishment.availablePeriods)}
            </>
          )}
        />
      </TableCell>
      <TableCell>{establishment.address}</TableCell>
      <TableCell>
        <IconButton
          edge="end"
          color={highlight ? 'primary' : 'inherit'}
          onClick={onHighlightChange}
        >
          <StarIcon />
        </IconButton>
      </TableCell>
      <TableCell>
        <PrimaryButton
          key="editButton"
          variant="contained"
          sx={{ my: 2, mr: 2, color: 'white' }}
          onClick={() => {
            navigate(`/establishments/${establishment.id}`);
          }}
        >
          Editar
        </PrimaryButton>
      </TableCell>
    </TableRow>
  );
}

EstablishmentRow.propTypes = {
  establishment: PropTypes.instanceOf(Establishment).isRequired,
};

export default EstablishmentRow;
