import { ENDPOINTS } from '../constants';
import { Stripe } from '../models';
import { parseResponse, defaultHeaders } from '../utils';

class StripeService {
  async getAll(query) {
    const url = ENDPOINTS.parse(ENDPOINTS.STRIPE, query);
    const response = await fetch(url, {
      method: 'GET',
      headers: defaultHeaders(),
    });

    return parseResponse(response, Stripe);
  }
}

export default new StripeService();
