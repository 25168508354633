import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  CircularProgress,
  Typography,
} from '@mui/material';

import { FaqTile } from './components';
import { faqService } from '../../services';
import SectionList from '../../components/styles/list/sectionList';

function FaqsPage() {
  const [error, setError] = useState();
  const [state, setState] = useState({
    loading: true,
    faqs: [],
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (error) return () => { };

    faqService.getAll().then((list) => {
      list = list || [];
      const faqs = list.map((faq) => (
        <FaqTile
          key={faq.id.toString()}
          faq={faq}
        />
      ));
      setState((oldState) => ({
        ...oldState,
        loading: false,
        faqs,
      }));
    }).catch((err) => {
      setError(err);
    });

    return () => { };
  }, [error]);

  // Throwing errors which the AppBoundary can catch
  // Errors thrown inside useEffect are not catched by the boundary
  useEffect(() => {
    if (error) {
      const err = error;
      setError(null);
      throw err;
    }
  }, [error]);

  const handleClick = () => {
    navigate('/faqs/new');
  };

  return state.loading
    ? (
      <CircularProgress />
    )
    : (
      <>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5"><b>FAQ</b></Typography>
          <Button variant="contained" onClick={handleClick}>
            + Adicionar
          </Button>
        </Box>
        <Box sx={{
          mt: 2, maxHeight: '70vh', bgcolor: 'transparent',
        }}
        >
          <SectionList variant="sectionList">
            {state.faqs}
          </SectionList>
        </Box>
      </>
    );
}

export default FaqsPage;
