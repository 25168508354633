import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import {
  AppBar as MuiAppBar,
  Autocomplete,
  Avatar,
  IconButton,
  InputAdornment,
  TextField,
  Toolbar,
  Badge,
  Box,
} from '@mui/material';

import {
  Notifications as NotificationsIcon,
  Search as SearchIcon,
} from '@mui/icons-material';

import { establishmentService } from '../services';

import { useAuth } from '../hooks';

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme }) => ({
  width: 'calc(100% - 220px)',
  boxShadow: 'none',
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  // ...(open && {
  //   transition: theme.transitions.create(['width', 'margin'], {
  //     easing: theme.transitions.easing.sharp,
  //     duration: theme.transitions.duration.enteringScreen,
  //   }),
  // }),
}));

function Header() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { pathname } = window.location;
  console.log(pathname);
  const [state, setState] = useState([]);
  const loadData = useCallback(() => {
    establishmentService.getAll()
      .then((establishments) => {
        console.log(establishments);
        setState(establishments.map((e) => ({
          id: e.id,
          label: `${e.id} - ${e.name}`,
        })));
      })
      .catch(() => {
        setState((oldState) => ({
          ...oldState,
        }));
      });
  }, []);
  useEffect(() => {
    if (pathname === '/establishments') {
      loadData();
    } else {
      setState([]);
    }
  }, [pathname, loadData]);
  const profile = () => {
    window.location.assign(`/users/${user.id}`);
  };

  function pesquisaEstabelecimento() {
    return (
      <Autocomplete
        freeSolo
        disableClearable // inputProps add a clear button already
        fullWidth
        id="search-box"
        options={state}
        onChange={(event, newValue, reason) => {
          if (
            event.type === 'keydown'
            && event.key === 'Backspace'
            && reason === 'removeOption'
          ) {
            return;
          }
          if (pathname === '/establishments') {
            navigate(`/establishments/${newValue.id}`);
          }
        }}
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            <Box
              sx={{
                flexGrow: 1,
              }}
            >
              {option.label}
              <br />
            </Box>
          </li>
        )}
        getOptionLabel={(option) => option.label}
        renderInput={(params) => (
          <TextField
            {...params}
            disabled={pathname !== '/establishments'}
            placeholder="Pesquisar"
            InputProps={{
              ...params.InputProps,
              readOnly: pathname !== '/establishments',
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              type: 'search',
            }}
          />
        )}
      />
    );
  }

  return (
    <AppBar position="absolute" color="inherit">
      <Toolbar sx={{ pr: '12px' }}>
        {pathname === '/establishments' ? pesquisaEstabelecimento() : <Box sx={{ width: '100%' }} />}
        <IconButton color="inherit" sx={{ mx: 1 }}>
          <Badge badgeContent={0} color="primary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <IconButton color="inherit" sx={{ mx: 1 }} onClick={profile}>
          <Avatar sx={{ m: 1, bgcolor: 'primary.main' }} />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
