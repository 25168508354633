import PropTypes from 'prop-types';
import {
  IconButton,
  ListItem,
  ListItemText,
} from '@mui/material';

import {
  Check as CheckIcon,
  CheckCircle as CheckCircleIcon,
  Close as CloseIcon,
} from '@mui/icons-material';

function VoucherTile({ voucherApplication, accept, reject }) {
  function actions() {
    switch (voucherApplication.status) {
      case 1:
        return <CheckCircleIcon sx={{ width: '80', color: 'success.main' }} />;
      case -1:
        return <CloseIcon sx={{ width: '80', color: 'error.main' }} />;
      default:
        return (
          <>
            <IconButton
              sx={{ flex: '0 1', color: 'error' }}
              onClick={reject}
            >
              <CloseIcon />
            </IconButton>
            <IconButton
              sx={{ flex: '0 1' }}
              onClick={accept}
            >
              <CheckIcon />
            </IconButton>
          </>
        );
    }
  }

  const { Voucher, Establishment } = voucherApplication;
  const date = new Date(voucherApplication.createdAt);
  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };
  const formattedDate = new Intl.DateTimeFormat('pt-BR', options).format(date);

  return (
    <ListItem
      key={voucherApplication.id.toString()}
    >
      <ListItemText
        primary={`${Establishment.name}`}
        secondary={(
          <>
            <span>
              {`Usuário: ${voucherApplication.user}`}
            </span>
            <br />
            <span>
              {`Código: ${Voucher.code}`}
            </span>
            <br />
            <span>
              {`Data: ${formattedDate}`}
            </span>
          </>
        )}
      />
      {actions()}
    </ListItem>
  );
}

VoucherTile.propTypes = {
  voucherApplication: PropTypes.object.isRequired,
  accept: PropTypes.func.isRequired,
  reject: PropTypes.func.isRequired,
};

export default VoucherTile;
