import {
  BrowserRouter, Routes, Route,
} from 'react-router-dom';
import {
  BasePage,
  UnknownPage,
} from './pages';

import {
  adminRoutes, franchiseeRoutes, ownerRoutes, authRoutes,
} from './routes';
import { useAuth } from './hooks';
import { ROLES } from './constants';

const { ADMIN, FRANCHISEE, OWNER } = ROLES;

function App() {
  const { token, user, setToken } = useAuth();

  const logout = () => {
    setToken(null);
  };

  if (!token) {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/">
            {authRoutes(setToken)}
          </Route>
        </Routes>
      </BrowserRouter>
    );
  }

  const routes = {
    [ADMIN]: adminRoutes,
    [FRANCHISEE]: franchiseeRoutes,
    [OWNER]: ownerRoutes,
  };

  return (
    <BrowserRouter>
      <Routes>
        {/* BasePage  */}
        <Route path="/" element={<BasePage logout={logout} />}>
          {routes[user.roleId]}

          {/* Default Route */}
          <Route path="*" element={<UnknownPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
