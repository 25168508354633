import { useCallback, useEffect, useState } from 'react';
import {
  Box,
  CircularProgress,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';

import { LogsTile } from './components';
import { logService } from '../../services';
import SectionList from '../../components/styles/list/sectionList';

function LogsPage() {
  const [error, setError] = useState();
  const [state, setState] = useState({
    loading: true,
    logs: [],
  });

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(250);

  const loadLogs = useCallback(() => {
    logService.getAll({ page, limit }).then((logs) => {
      setState((oldState) => ({
        ...oldState,
        loading: false,
        logs,
      }));
    }).catch((err) => {
      setError(err);
    });
  }, [page, limit]);

  useEffect(() => {
    if (error) return () => { };

    loadLogs();

    return () => { };
  }, [error, loadLogs]);

  // Throwing errors which the AppBoundary can catch
  // Errors thrown inside async methods in useEffect are not catched by the boundary
  useEffect(() => {
    if (error) {
      const err = error;
      setError(null);
      throw err;
    }
  }, [error]);

  return state.loading
    ? (
      <CircularProgress />
    )
    : (
      <>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5"><b>LOGs</b></Typography>
          <TextField
            label="Items por página"
            type="number"
            sx={{ width: 200 }}
            value={limit}
            onChange={(e) => setLimit(parseInt(e.target.value, 10))}
            select
          >
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={250}>250</MenuItem>
            <MenuItem value={1000}>1000</MenuItem>
            <MenuItem value={5000}>5000</MenuItem>
          </TextField>
          <TextField
            label="Página"
            type="number"
            sx={{ width: 200 }}
            value={page}
            onChange={(e) => setPage(parseInt(e.target.value, 10))}
          />
        </Box>
        <Box sx={{
          mt: 2, maxHeight: '60vh', bgcolor: 'transparent',
        }}
        >
          <SectionList variant="sectionList">
            {state.logs.map((log) => (
              <LogsTile
                key={log.id.toString()}
                log={log}
              />
            ))}
          </SectionList>
        </Box>
      </>
    );
}

export default LogsPage;
