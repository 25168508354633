import {
  Avatar,
  Box,
  Button,
  TextField,
  Typography,
} from '@mui/material';

import {
  LockOutlined as LockOutlinedIcon,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

import { validator } from '../../utils';
import { authService } from '../../services';
import { useSnack } from '../../hooks';

function ForgotPasswordPage() {
  const { setSnack } = useSnack();
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const email = data.get('email');

    const error = validator.email(email, true);

    if (!error) {
      try {
        await authService.requestResetPassword(email);
        sessionStorage.setItem('email', email);
        return navigate('/forgot/code');
      } catch (e) {
        console.log(e);
      }
    }

    return setSnack(error);
  };

  const back = () => navigate(-1);

  return (
    <Box
      sx={{
        my: 8,
        mx: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Esqueci a senha
      </Typography>
      <Typography>Insira seu e-mail de acesso. Um código será enviado para este e-mail</Typography>
      <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
        <TextField
          autoFocus
          fullWidth
          required
          id="email"
          label="Email Address"
          name="email"
          margin="normal"
          autoComplete="email"
        />
        <Button
          fullWidth
          type="submit"
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Enviar
        </Button>
        <Button
          fullWidth
          onClick={back}
        >
          Voltar
        </Button>
      </Box>
    </Box>
  );
}

export default ForgotPasswordPage;
