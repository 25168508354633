import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  CircularProgress,
} from '@mui/material';

import {
  Edit as EditIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';

import { FaqProfileForm } from './components';

import { faqService } from '../../services';
import { validator } from '../../utils';
import { useSnack } from '../../hooks';
import { CancelButton, PrimaryButton, SecondaryButton } from '../../components/styles/buttons/index';
import { Faq } from '../../models';

function FaqInfoPage() {
  const { id } = useParams();
  const { setSnack } = useSnack();
  const navigate = useNavigate();

  const [state, setState] = useState({
    loading: true,
    edit: false,
    saving: false,
    dialogOpen: false,
  });

  const [profile, setProfile] = useState(new Faq({}));

  useEffect(() => {
    async function fetchProfile() {
      if (parseInt(id, 10)) {
        const loadedData = (await faqService.get(id)) || {};
        setProfile(loadedData);
        setState((oldState) => ({
          ...oldState,
          loading: false,
          edit: !loadedData?.id,
          dialogOpen: false,
        }));
      } else {
        setState((oldState) => ({
          ...oldState,
          loading: false,
          edit: true,
          dialogOpen: false,
        }));
      }
    }

    fetchProfile();
  }, [id]);

  const [errors, setErrors] = useState({
    question: '',
    answer: '',
  });

  function isNewFaq() {
    return profile?.id == null;
  }

  const cancel = () => {
    navigate('/faqs');
  };

  const toggleEdit = () => {
    if (parseInt(profile?.id, 10)) {
      setState({
        ...state,
        edit: !state.edit,
      });
    } else {
      cancel();
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setState({
      ...state,
      saving: true,
    });

    const questionError = validator.required(profile.question);
    const answerError = validator.required(profile.answer);

    if (!questionError || !answerError) {
      try {
        if (isNewFaq()) {
          await faqService.create(profile);
        } else {
          await faqService.update(profile);
        }

        setState({
          ...state,
          loading: false,
          saving: false,
          edit: isNewFaq(),
        });

        if (isNewFaq()) {
          setProfile(new Faq({}));
        }

        return setSnack({
          type: 'success',
          message: 'Cadastro salvo com sucesso',
        });
      } catch (e) {
        setState({
          ...state,
          saving: false,
        });
        return setSnack({ message: e.message });
      }
    } else {
      return setErrors({
        question: questionError,
        answer: answerError,
      });
    }
  };

  const clearErrors = () => {
    const { question, answer } = errors;

    // Avoid setState cycle
    if (question || answer) {
      setErrors({
        question: '',
        answer: '',
      });
    }
  };

  function handleDelete() {
    faqService.delete(profile.id).then((success) => {
      if (success) {
        cancel();
        setSnack({
          type: 'success',
          message: 'FAQ apagado com sucesso',
        });
      } else {
        setSnack();
      }
    }).catch((error) => {
      setSnack({ message: error.message });
    });
  }

  const onDialogClose = (confirm) => {
    setState({ ...state, dialogOpen: false });

    if (confirm) {
      handleDelete();
    }
  };

  const confirmDelete = () => {
    setState({ ...state, dialogOpen: true });
  };

  const buttonRow = () => {
    const buttons = [];

    if (state.edit) {
      if (!isNewFaq()) {
        buttons.push(
          <CancelButton
            key="deleteButton"
            onClick={confirmDelete}
            sx={{ mr: 2, color: 'error.main' }}
          >
            <DeleteIcon sx={{ mr: 2 }} />
            {' '}
            Deletar
          </CancelButton>,
        );
      }
      buttons.push(
        <SecondaryButton
          key="cancelEditButton"
          onClick={toggleEdit}
          sx={{ mr: 2 }}
          variant="secondaryButton"
        >
          Cancelar
        </SecondaryButton>,
      );
      buttons.push(
        <PrimaryButton
          type="submit"
          key="submitbutton"
          variant="primaryButton"
        >
          Salvar
        </PrimaryButton>,
      );
    } else {
      buttons.push(
        <SecondaryButton
          key="backButton"
          onClick={cancel}
          sx={{ mx: 2 }}
          variant="secondaryButton"
        >
          Voltar
        </SecondaryButton>,
      );
      buttons.push(
        <PrimaryButton
          key="editbutton"
          variant="primaryButton"
          onClick={toggleEdit}
        >
          Editar
          <EditIcon sx={{ ml: 2 }} />
        </PrimaryButton>,
      );
    }

    return buttons;
  };

  return state.loading
    ? (
      <CircularProgress />
    )
    : (
      <Box sx={{ display: 'flex' }}>
        <FaqProfileForm
          handleSubmit={handleSubmit}
          errors={errors}
          clearErrors={clearErrors}
          profile={profile}
          setProfile={setProfile}
          isEditMode={state.edit}
          buttons={buttonRow()}
          dialogOpen={state.dialogOpen}
          onDialogClose={onDialogClose}
          loading={state.saving}
        />
      </Box>
    );
}

export default FaqInfoPage;
