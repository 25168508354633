import { useState } from 'react';
import {
  Box, Button, List, ListItem, Typography,
} from '@mui/material';

import { ArrowRight as ArrowRightIcon } from '@mui/icons-material';

import { useSnack } from '../../hooks';
import { uploadService } from '../../services';

function ImportPage() {
  const { setSnack } = useSnack();
  const [state, setState] = useState({
    loading: true,
    file: null,
  });

  const handleFileChange = (e) => {
    const file = Array.from(e.target.files)[0];
    if (file != null) {
      setState({ ...state, file });
    }
    e.target.value = '';
  };

  const upload = async () => {
    setState({
      ...state,
      loading: true,
    });

    const result = await uploadService.csv(state.file);

    setState({
      loading: false,
      file: null,
    });

    if (result) setSnack({ type: 'success', message: 'Dados importados com sucesso' });
    else setSnack({ message: 'Não foi possível completar a importação' });
  };

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h5"><b>Importar Compras Externas</b></Typography>
      </Box>
      <Box
        sx={{
          mt: 2, bgcolor: 'transparent',
        }}
      >
        <Typography>
          Insira um arquivo CSV para importar compras realizadas fora da plataforma Risto.
          O arquivo deve estar estruturado com os seguintes dados nesta ordem:
        </Typography>
        <List>
          <ListItem>
            <ArrowRightIcon />
            E-mail de compra
          </ListItem>
          <ListItem>
            <ArrowRightIcon />
            Valor da compra
          </ListItem>
          <ListItem>
            <ArrowRightIcon />
            Id do plano comprado
          </ListItem>
          <ListItem>
            <ArrowRightIcon />
            Data e Hora da compra
          </ListItem>
        </List>
        {state.file && (
          <Typography variant="bodyTitle">
            {`ARQUIVO SELECIONADO: ${state.file.name}`}
          </Typography>
        )}
        <br />
        <br />
        <Button component="label" sx={{ ml: -1 }}>
          Selecionar Arquivo
          <input
            type="file"
            name="csvInput"
            accept=".csv"
            onChange={handleFileChange}
            hidden
          />
        </Button>
        <br />
        <br />
        {state.file && (
          <Button
            variant="contained"
            sx={{ ml: 0 }}
            onClick={upload}
          >
            Enviar
          </Button>
        )}
      </Box>
    </>
  );
}

export default ImportPage;
