import { ENDPOINTS } from '../constants';
import { Importador } from '../models';
import { parseResponse, defaultHeaders } from '../utils';

class ImportadorService {
  async post(data) {
    const imp = new Importador(data);
    if (!imp.isValid()) throw new Error('Invalid Import');

    const response = await fetch(ENDPOINTS.USER_PLAN_IMPORT_NEW, {
      method: 'POST',
      headers: defaultHeaders(),
      body: imp.json(),
    });

    return parseResponse(response);
  }
}

export default new ImportadorService();
