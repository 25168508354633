class Stripe {
  constructor({
    id, paymentId, planId, userId, status, updatedAt, User, Plan,
  }) {
    this.id = id;
    this.paymentId = paymentId;
    this.planId = planId;
    this.userId = userId;
    this.status = status;
    this.user = User?.name;
    this.plan = Plan?.name;
    this.planAmount = Plan?.value ? Plan.value / 100 : 0;
    this.updatedAt = updatedAt;
    this.userData = User;
    this.planData = Plan;
  }

  /// Check if this is a valid contact Type.
  isValid(checkId) {
    if (checkId && !this.id) return false;
    if (!this.paymentId) return false;
    if (!this.planId) return false;
    if (!this.userId) return false;
    if (!this.status) return false;
    if (!this.updatedAt) return false;
    if (!this.User) return false;
    if (!this.Plan) return false;

    return true;
  }

  json() {
    return JSON.stringify(this);
  }
}

export default Stripe;
