import { formatter } from '../utils';

class Establishment {
  constructor(data) {
    this.id = data?.id;
    this.name = data?.name;
    this.address = data?.address;
    this.rules = data?.rules;
    this.offers = data?.offers;
    this.benefits = data?.benefits;
    this.sunday = data?.sunday || 0;
    this.monday = data?.monday || 0;
    this.tuesday = data?.tuesday || 0;
    this.wednesday = data?.wednesday || 0;
    this.thursday = data?.thursday || 0;
    this.friday = data?.friday || 0;
    this.saturday = data?.saturday || 0;
    this.morning = this.getPeriodFromDays(data || {}, 1) || 0;
    this.afternoon = this.getPeriodFromDays(data || {}, 2) || 0;
    this.night = this.getPeriodFromDays(data || {}, 4) || 0;
    this.highlight = data?.highlight || false;
    this.City = data?.City;
    this.Images = data?.Images || [];
    this.filesToUpload = data?.filesToUpload || [];
    this.Contacts = data?.Contacts || [];
    this.Categories = data?.Categories || [];
    this.Culinaries = data?.Culinaries || [];
    this.Types = data?.Types || [];
    this.avatar = this.Images.find((i) => i.isAvatar);
  }

  get availableDays() {
    return this.morning | this.afternoon | this.night;
  }

  get availablePeriods() {
    return this.sunday
      | this.monday
      | this.tuesday
      | this.wednesday
      | this.thursday
      | this.friday
      | this.saturday;
  }

  updateAvatar(avatarId) {
    if (avatarId == null) {
      this.avatar = null;
      return;
    }

    const oldAvatar = this.Images.find((i) => i.isAvatar);
    const avatar = this.Images.find((i) => i.id === avatarId);

    if (oldAvatar?.id === avatar?.id) return;

    const images = this.Images.filter((i) => !(i.id === avatarId || i.id === oldAvatar?.id));

    avatar.isAvatar = true;
    if (oldAvatar?.isAvatar) {
      oldAvatar.isAvatar = false;
      images.push(oldAvatar);
    }
    this.Images = [avatar, ...images];
  }

  /**
   * Check if this is a valid Establishment.
   *
   * @return true - Object has the minimum data to be an Establishment
   *         false - One or more required information has not been provided
   *  */
  isValid() {
    if (!this.name || !this.address || !this.City) return false;
    if ((this.availableDays < 0 || this.availableDays > 127)
      && (this.availablePeriods < 0 || this.availablePeriods > 7)) return false;

    return true;
  }

  json() {
    return JSON.stringify(this);
  }

  setDaysFromPeriods() {
    this.sunday = ((this.morning & 1) === 1) * 1
      + ((this.afternoon & 1) === 1) * 2
      + ((this.night & 1) === 1) * 4;

    this.monday = ((this.morning & 2) === 2) * 1
      + ((this.afternoon & 2) === 2) * 2
      + ((this.night & 2) === 2) * 4;

    this.tuesday = ((this.morning & 4) === 4) * 1
      + ((this.afternoon & 4) === 4) * 2
      + ((this.night & 4) === 4) * 4;

    this.wednesday = ((this.morning & 8) === 8) * 1
      + ((this.afternoon & 8) === 8) * 2
      + ((this.night & 8) === 8) * 4;

    this.thursday = ((this.morning & 16) === 16) * 1
      + ((this.afternoon & 16) === 16) * 2
      + ((this.night & 16) === 16) * 4;

    this.friday = ((this.morning & 32) === 32) * 1
      + ((this.afternoon & 32) === 32) * 2
      + ((this.night & 32) === 32) * 4;

    this.saturday = ((this.morning & 64) === 64) * 1
      + ((this.afternoon & 64) === 64) * 2
      + ((this.night & 64) === 64) * 4;
  }

  getPeriodFromDays(data, i) {
    const {
      sunday, monday, tuesday, wednesday, thursday, friday, saturday,
    } = data;

    const week = {
      sunday: (sunday & i) === i,
      monday: (monday & i) === i,
      tuesday: (tuesday & i) === i,
      wednesday: (wednesday & i) === i,
      thursday: (thursday & i) === i,
      friday: (friday & i) === i,
      saturday: (saturday & i) === i,
    };

    const number = formatter.formatWeekObjectToNumber(week);

    return number;
  }
}

export default Establishment;
