import { ENDPOINTS } from '../constants';
import { Type } from '../models';
import { parseResponse, defaultHeaders } from '../utils';

class TypeService {
  async getAll(query) {
    const url = ENDPOINTS.parse(ENDPOINTS.TYPES, query);
    const response = await fetch(url, {
      method: 'GET',
      headers: defaultHeaders(),
    });
    return parseResponse(response, Type);
  }
}

export default new TypeService();
