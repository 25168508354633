class Plan {
  constructor({
    id,
    name,
    expiry,
    diamonds,
    vouchers,
    voucherPeriod,
    value,
    image,
    Categories,
    categories,
    discountValue,
    discountPeriod,
    voucherValidFrom,
  }) {
    this.id = id;
    this.name = name;
    this.value = value;
    this.diamonds = diamonds;
    this.vouchers = vouchers;
    this.voucherPeriod = voucherPeriod;
    this.expiry = expiry;
    this.image = image;
    this.categories = categories || Categories;
    this.discountValue = discountValue;
    this.discountPeriod = discountPeriod;
    this.voucherValidFrom = voucherValidFrom;
  }

  /// Check if this is valid. It is valid if all required data has been informed.
  isValid(checkId) {
    if (checkId && !this.id) return false;
    if (
      !this.name
      || !this.vouchers
      || !this.voucherPeriod
      || !this.expiry
      || !this.value
    ) return false;

    return true;
  }

  json() {
    const discountPeriod = this.discountPeriod && `${this.discountPeriod} 03:00:00`;
    return JSON.stringify({ ...this, discountPeriod });
  }
}

export default Plan;
