class AddDiamonds {
  constructor({ userPlanId, quantity }) {
    this.userPlanId = userPlanId;
    this.quantity = quantity;
  }

  /// Check if this is valid. It is valid if all required data has been informed.
  isValid(checkId) {
    if (checkId && !this.userPlanId) return false;
    if (!this.quantity) return false;

    return true;
  }

  json() {
    return JSON.stringify(this);
  }
}

export default AddDiamonds;
