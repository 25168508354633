import { useState, useEffect } from 'react';
import moment from 'moment';
import {
  Box,
  TextField,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  CircularProgress,
  Button,
} from '@mui/material';

import { establishmentService, statsService, cityService } from '../../services';

import ChartDisplay from './components/ChartDisplay';

function DashboardPage() {
  const [filterData, setFilterData] = useState({
    startDate: moment().subtract('days', 15).format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
    status: 1,
    establishments: [],
    cityId: '',
  });

  const canClearFilters = () => filterData.establishments.length > 0 || filterData.cityId !== '';
  const [establishments, setEstablishments] = useState([]);
  const [cities, setCities] = useState({
    all: [],
    filtered: [],
    states: [],
  });
  const [selectedState, setSelectedState] = useState('');
  const [errors, setErrors] = useState([]);
  const [stats, setStats] = useState({
    loading: true,
    stats: [],
  });

  const clearFilter = () => {
    setFilterData((prevState) => ({
      ...prevState,
      status: 1,
      establishments: [],
      cityId: '',
    }));
    setSelectedState('');
  };
  useEffect(() => {
    function getStates(c) {
      const list = [];
      c.forEach((city) => {
        if (!list.some((state) => state.id === city.State.id)) list.push(city.State);
      });

      return list.sort((a, b) => a.id - b.id);
    }
    establishmentService.getAll()
      .then((est) => setEstablishments(est))
      .then(() => {
        cityService.getCities()
          .then((c) => setCities({ all: c, filtered: [], states: getStates(c) }))
          .catch((err) => setErrors(err));
      })
      .catch((err) => setErrors(err));
  }, [errors]);

  useEffect(() => {
    setStats((prevState) => ({ ...prevState, loading: true }));
    statsService.getAll({ ...filterData, establishments: filterData.establishments.join(',') })
      .then((s) => setStats({ stats: s, loading: false }))
      .catch((err) => setErrors(err));
  }, [filterData]);

  useEffect(() => {
    setCities((prevState) => ({
      ...prevState,
      filtered: prevState.all.filter((s) => s.State.id === selectedState),
    }));
  }, [selectedState]);

  console.log(stats);
  console.log(errors);

  const handleStartDateChange = async (startDate) => {
    setFilterData((prevState) => ({
      ...prevState,
      startDate: moment(startDate).format('YYYY-MM-DD'),
    }));
  };
  const handleEndDateChange = async (endDate) => {
    console.log(endDate);
    setFilterData((prevState) => ({
      ...prevState,
      endDate: moment(endDate).format('YYYY-MM-DD'),
    }));
  };
  const handleEstablishmentChange = async (esta) => {
    setFilterData((prevState) => ({
      ...prevState,
      establishments: typeof esta === 'string' ? esta.split(',') : esta,
    }));
  };
  const handleCityIdChange = async (cityId) => {
    setFilterData((prevState) => ({
      ...prevState,
      cityId,
    }));
  };
  const handleStateChange = async (stateId) => {
    setSelectedState(stateId);
  };
  console.log(filterData);
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <Box component="form" sx={{ display: 'block', width: '100%' }}>
        <Grid container spacing={4}>
          <Grid
            item
            xl={3}
          >
            <TextField
              name="startDate"
              type="date"
              label="Período de Início"
              fullWidth
              value={filterData.startDate}
              onChange={(event) => handleStartDateChange(event.target.value)}
              hiddenLabel
              variant="standard"
              InputProps={{ disableUnderline: true }}
            />
          </Grid>
          <Grid
            item
            xl={3}
          >
            <TextField
              name="endDate"
              type="date"
              label="Período de Fim"
              fullWidth
              value={filterData.endDate}
              onChange={(event) => handleEndDateChange(event.target.value)}
              hiddenLabel
              variant="standard"
              InputProps={{ disableUnderline: true }}
            />
          </Grid>
          <Grid
            item
            xl={3}
          >
            <FormControl fullWidth sx={{ marginBottom: 2 }}>
              <InputLabel id="establishment">Estabelecimentos</InputLabel>
              <Select
                labelId="establishment"
                id="establishmentsField"
                name="establishments"
                value={filterData.establishments}
                multiple
                label="Estabelecimento"
                InputProps={{ disableUnderline: true }}
                onChange={(event) => handleEstablishmentChange(event.target.value)}
              >
                {establishments.map((est) => (<MenuItem value={est.id}>{est.name}</MenuItem>))}
              </Select>
              <FormHelperText sx={{ margin: '3px 0px 0px 0px' }}>Filtrar por Estabelecimento(s)</FormHelperText>
            </FormControl>
            {canClearFilters() && <Button onClick={() => clearFilter()}>Limpar Filtros</Button>}
          </Grid>
          <Grid
            item
            xl={3}
          >
            <FormControl
              fullWidth
              sx={{ marginBottom: 2 }}
            >
              <InputLabel id="stateId">Estados</InputLabel>
              <Select
                labelId="stateId"
                id="stateIdField"
                name="stateIdField"
                label="Estados"
                value={selectedState}
                InputProps={{ disableUnderline: true }}
                onChange={(event) => handleStateChange(event.target.value)}
              >
                <MenuItem value="">
                  <em>Nenhum</em>
                </MenuItem>
                {cities.states.map((est) => (<MenuItem value={est.id}>{est.name}</MenuItem>))}
              </Select>
              <FormHelperText sx={{ margin: '3px 0px 0px 0px' }}>Filtrar por Estado</FormHelperText>
            </FormControl>
            <FormControl fullWidth disabled={selectedState === ''}>
              <InputLabel id="cityId">Cidades</InputLabel>
              <Select
                labelId="cityId"
                id="cityIdField"
                name="cityId"
                value={filterData.cityId}
                label="Cidades"
                InputProps={{ disableUnderline: true }}
                onChange={(event) => handleCityIdChange(event.target.value)}
              >
                {cities.filtered.map((est) => (<MenuItem value={est.id}>{est.name}</MenuItem>))}
              </Select>
              <FormHelperText sx={{ margin: '3px 0px 0px 0px' }}>Filtrar por Cidade</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
        {stats.loading ? <CircularProgress /> : (
          <ChartDisplay
            stats={stats.stats}
            establishments={filterData.establishments}
          />
        )}
      </Box>
    </Box>
  );
}

export default DashboardPage;
