import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  CircularProgress,
} from '@mui/material';

import {
  Edit as EditIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';

import { CategoryProfileForm } from './components';

import { categoryService, uploadService } from '../../services';
import { validator } from '../../utils';
import { useSnack } from '../../hooks';
import { CancelButton, PrimaryButton, SecondaryButton } from '../../components/styles/buttons/index';
import { Category } from '../../models';

function CategoryInfoPage() {
  const { id } = useParams();
  const { setSnack } = useSnack();
  const navigate = useNavigate();

  const [state, setState] = useState({
    loading: true,
    edit: false,
    saving: false,
    dialogOpen: false,
  });

  const [profile, setProfile] = useState(new Category({}));

  useEffect(() => {
    async function fetchProfile() {
      if (parseInt(id, 10)) {
        const loadedData = (await categoryService.get(id)) || {};
        setProfile(loadedData);
        setState({
          loading: false,
          edit: !loadedData?.id,
          dialogOpen: false,
        });
      } else {
        setState({
          loading: false,
          edit: true,
          dialogOpen: false,
        });
      }
    }

    fetchProfile();
  }, [id]);

  const [errors, setErrors] = useState({
    name: '',
    image: '',
  });

  function isNewCategory() {
    return profile?.id == null;
  }

  const cancel = () => {
    navigate('/categories');
  };

  const toggleEdit = () => {
    if (parseInt(profile?.id, 10)) {
      setState({
        ...state,
        edit: !state.edit,
      });
    } else {
      cancel();
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setState({
      ...state,
      saving: true,
    });

    const image = (profile.file?.size || 0) === 0
      ? profile.image
      : await uploadService.image(profile.file);

    profile.image = image;

    const nameError = validator.name(profile.name, true);

    if (!nameError) {
      try {
        if (isNewCategory()) {
          await categoryService.create(profile);
        } else {
          await categoryService.update(profile);
        }

        setState({
          ...state,
          loading: false,
          saving: false,
          edit: isNewCategory(),
        });

        if (isNewCategory()) {
          setProfile(new Category({}));
        }

        return setSnack({
          type: 'success',
          message: 'Cadastro salvo com sucesso',
        });
      } catch (e) {
        setState({
          ...state,
          saving: false,
        });
        return setSnack({ message: e.message });
      }
    } else {
      return setErrors({
        name: nameError,
      });
    }
  };

  const clearErrors = () => {
    const { name, image } = errors;

    // Avoid setState cycle
    if (name || image) {
      setErrors({
        name: '',
        image: '',
      });
    }
  };

  function handleDelete() {
    categoryService.delete(profile.id).then((success) => {
      if (success) {
        cancel();
        setSnack({
          type: 'success',
          message: 'Categoria apagada com sucesso',
        });
      } else {
        setSnack();
      }
    }).catch((error) => {
      setSnack({ message: error.message });
    });
  }

  const onDialogClose = (confirm) => {
    setState({ ...state, dialogOpen: false });

    if (confirm) {
      handleDelete();
    }
  };

  const confirmDelete = () => {
    setState({ ...state, dialogOpen: true });
  };

  const buttonRow = () => {
    const buttons = [];

    if (state.edit) {
      if (!isNewCategory()) {
        buttons.push(
          <CancelButton
            key="deleteButton"
            onClick={confirmDelete}
            sx={{ mr: 2, color: 'error.main' }}
          >
            <DeleteIcon sx={{ mr: 2 }} />
            {' '}
            Deletar
          </CancelButton>,
        );
      }
      buttons.push(
        <SecondaryButton
          key="cancelEditButton"
          onClick={toggleEdit}
          sx={{ mr: 2 }}
          variant="secondaryButton"
        >
          Cancelar
        </SecondaryButton>,
      );
      buttons.push(
        <PrimaryButton
          type="submit"
          key="submitbutton"
          variant="primaryButton"
        >
          Salvar
        </PrimaryButton>,
      );
    } else {
      buttons.push(
        <SecondaryButton
          key="backButton"
          onClick={cancel}
          sx={{ mx: 2 }}
          variant="secondaryButton"
        >
          Voltar
        </SecondaryButton>,
      );
      buttons.push(
        <PrimaryButton
          key="editbutton"
          variant="primaryButton"
          onClick={toggleEdit}
        >
          Editar
          <EditIcon sx={{ ml: 2 }} />
        </PrimaryButton>,
      );
    }

    return buttons;
  };

  return state.loading
    ? (
      <CircularProgress />
    )
    : (
      <Box sx={{ display: 'flex' }}>
        <CategoryProfileForm
          handleSubmit={handleSubmit}
          errors={errors}
          clearErrors={clearErrors}
          profile={profile}
          setProfile={setProfile}
          isEditMode={state.edit}
          isNewCategory={isNewCategory()}
          buttons={buttonRow()}
          dialogOpen={state.dialogOpen}
          onDialogClose={onDialogClose}
          loading={state.saving}
        />
      </Box>
    );
}

export default CategoryInfoPage;
